<template>
    <div class="mx-6">
        <console-header title="Trademark Details">
            <stf-button
                @click="returnToRecords"
                outlined
                title="Return to records"
                color="primary-console"
            >
                <i class="fa" :class="'fa-arrow-left'" aria-hidden="true"></i>
            </stf-button>
        </console-header>

        <div>
            <mark-details-section title="Application overview">
                <stf-row>
                    <mark-details-section-item label="Trademark">
                        <img
                            v-if="isTrademarkImage(getMarkRecordDetails)"
                            class="records__image"
                            :src="getImageUrl(getMarkRecordDetails?.markImage?.url || '')"
                        >
                        <span v-else>
                            {{ trademarkName }}
                        </span>
                    </mark-details-section-item>

                    <mark-details-section-item
                        label="Application number"
                        :value="applicationNumber"
                    />

                    <mark-details-section-item
                        label="Status"
                        :value="status"
                    />

                    <mark-details-section-item
                        label="Date"
                        :value="date"
                    />

                    <mark-details-section-item
                        label="Country"
                        :value="country"
                    />
                </stf-row>
            </mark-details-section>

            <stf-section-divider />

            <mark-details-section title="Products/Services">
                <stf-row>
                    <mark-details-section-item
                        label="Classes"
                        :value="productClasses"
                    />

                    <mark-details-section-item
                        alignVerticaly
                        label="Products/Services"
                        :value="productsServices"
                    />
                </stf-row>
            </mark-details-section>

            <stf-section-divider />

            <mark-details-section title="Contact">
                <stf-row>
                    <mark-details-section-item
                        label="Name"
                        :value="contactName"
                    />

                    <mark-details-section-item
                        label="Email"
                        :value="contactEmail"
                    />

                    <mark-details-section-item
                        label="Phone"
                        :value="contactPhone"
                    />
                </stf-row>
            </mark-details-section>

            <stf-section-divider />

            <mark-details-section title="Trademark owner">
                <stf-row>
                    <mark-details-section-item
                        label="Name"
                        :value="ownerName"
                    />

                    <mark-details-section-item
                        label="Business number"
                        :value="ownerBusinessNumber"
                    />

                    <mark-details-section-item
                        label="Company number"
                        :value="ownerCompanyNumber"
                    />

                    <mark-details-section-item
                        label="Address"
                        :value="ownerAdress"
                    />

                    <mark-details-section-item
                        label="Email"
                        :value="ownerEmail"
                    />

                    <mark-details-section-item
                        label="Phone"
                        :value="ownerPhone"
                    />
                </stf-row>
            </mark-details-section>

            <stf-section-divider />
        </div>

        <stf-row isCentered class="mt-4">
            <stf-col size="is-narrow">
                <stf-button
                    @click="returnToRecords"
                    class="mr-2"
                    label="back to Records"
                    outlined
                    color="primary-console"
                />
                <stf-button
                    label="Contact a consultant"
                    color="primary-console"
                    @click="redirectToContactPage"
                />
            </stf-col>
        </stf-row>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import RouteName from '@/router/route-name';
import StfButton from '@/ui/button/stf-button.vue';
import ConsoleHeader from '@/components/user-console/console-header.vue';
import {
    getImageUrl,
    isTrademarkImage,
    stringifyGroupValues,
    stringifyNames,
    stringifyobjectStringFromArray
} from '@/core/util/trademarks-utils';
import MarkDetailsSection from '@/components/user-console/mark-details-section.vue';
import MarkDetailsSectionItem from '@/components/user-console/mark-details-section-item.vue';

export default defineComponent({
    name: 'MarkDetails',
    components: {
        StfButton,
        ConsoleHeader,
        MarkDetailsSection,
        MarkDetailsSectionItem
    },
    computed: {
        ...mapGetters('marksRecords', ['getMarkRecordDetails']),
        trademarkName(): string {
            return this.getMarkRecordDetails?.markDescription || 'none';
        },
        applicationNumber(): string | number {
            return this.getMarkRecordDetails?.applicationNumber || 'none';
        },
        status(): string {
            return this.getMarkRecordDetails?.normalizedStatus?.name || 'none';
        },
        date(): string {
            if (this.getMarkRecordDetails && this.getMarkRecordDetails.applicationDate !== null) {
                const date = new Date(this.getMarkRecordDetails.applicationDate);

                return date.toLocaleDateString('nz-NZ', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                })
            }

            return 'TBD';
        },
        country(): string {
            return this.getMarkRecordDetails?.country?.name || 'none';
        },
        productClasses(): string {
            return this.stringifyGroupValues(this.getMarkRecordDetails?.markGoods, 'classNumber') || 'none';
        },
        productsServices(): string {
            return this.stringifyGroupValues(this.getMarkRecordDetails?.markGoods, 'name') || 'none';
        },
        contactName(): string {
            return this.stringifyNames([this.getMarkRecordDetails?.markContact]) || 'none';
        },
        contactEmail(): string {
            return this.getMarkRecordDetails?.markContact.email || 'none';
        },
        contactPhone(): string {
            return this.getMarkRecordDetails?.markContact.phone || 'none';
        },
        ownerName(): string {
            return this.stringifyNames(this.getMarkRecordDetails?.markOwners) || 'none';
        },
        ownerBusinessNumber(): string | number {
            return this.stringifyobjectStringFromArray(this.getMarkRecordDetails?.markOwners, 'businessNumber') || 'none';
        },
        ownerCompanyNumber(): string | number {
            return this.stringifyobjectStringFromArray(this.getMarkRecordDetails?.markOwners, 'companyNumber') || 'none';
        },
        ownerAdress(): string {
            const address = this.getMarkRecordDetails?.markOwnerDetail.address ?? '';
            const suburb = this.getMarkRecordDetails?.markOwnerDetail.suburb ?? '';
            const city = this.getMarkRecordDetails?.markOwnerDetail.city ?? '';
            const region = this.getMarkRecordDetails?.markOwnerDetail.region ?? '';
            const postCode = this.getMarkRecordDetails?.markOwnerDetail.postCode ?? '';
            const countryCode = this.getMarkRecordDetails?.markOwnerDetail.countryCode ?? '';
            return `${address} ${suburb} ${city} ${region} ${postCode} ${countryCode}` || 'none';
        },
        ownerEmail(): string {
            return this.getMarkRecordDetails?.markOwnerDetail.email || 'none';
        },
        ownerPhone(): string {
            return this.getMarkRecordDetails?.markOwnerDetail.phone || 'none';
        },
        contactpageLink(): string {
            const tmpDomain = 'https://www.trademarkplanet.com';
            return this.getMarkRecordDetails?.websiteUrl === tmpDomain
                ? `${this.getMarkRecordDetails?.websiteUrl}/uk/contact`
                : `${this.getMarkRecordDetails?.websiteUrl}/contact`;

        },
    },
    methods: {
        getImageUrl,
        isTrademarkImage,
        stringifyGroupValues,
        stringifyNames,
        stringifyobjectStringFromArray,
        returnToRecords() {
            this.$router.push({name: RouteName.TRADEMARKS});
        },
        redirectToContactPage() {
            window.open(this.contactpageLink);
        },
    },
});
</script>
