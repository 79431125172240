<template>
    <div id="cy-suggestion-box" class="card">
        <div class="card-header bg-light">
            <search-box :loading="searching" @search="onSearch"/>
            <span v-if="searchDone" class="pl-1 mt-2">
                {{ trans('message.goods.search.resultInfo', {search, nbClassInResult }) }}
            </span>
        </div>
        <div class="card-body" style="height: 500px;">
            <div style="overflow-y: auto; height: 100%;">
                <good-class-display v-for="goodClass in classes" :key="goodClass.classNumber" :good-class="goodClass"/>
                <a v-if="goodsResult.classes.length > nbClassDisplayed" @click="showAll = !showAll">
                    {{ showActionWord }}
                    <i
                        class="fa"
                        :class="showAll ? 'fa-angle-up' : 'fa-angle-down'"
                        aria-hidden="true"
                    ></i>
                </a>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import api from '@/core/api'
import DrafterSearch from '@/core/model/drafter-search';
import GoodClassDisplay from '@/components/goods-view/good-class-result.vue'
import SearchBox from '@/components/goods-view/search-box.vue'
import GoodClass from '@/core/model/good-class';
import { mapGetters } from 'vuex';
import useInternalization from '@/composables/internalizable';
import useCountry from '@/composables/countryDependable';

const NB_CLASS_DISPLAY = 3;

export default defineComponent({
    name: 'GoodSearch',
    components: {
        GoodClassDisplay,
        SearchBox
    },
    setup() {
        const country = useCountry();
        return {
            country,
            ...useInternalization(country)
        };
    },
    data() {
        return {
            nbClassDisplayed: NB_CLASS_DISPLAY,
            search: '',
            searching: false,
            goodsResult: new DrafterSearch(),
            showAll: false,
        }
    },
    computed: {
        ...mapGetters('application', ['appReference']),
        nbClassInResult(): number {
            return this.goodsResult.classes.length;
        },
        searchDone(): boolean {
            return this.search.length !== 0 && this.nbClassInResult !== 0;
        },
        classes(): GoodClass[] {
            if (!this.showAll) {
                return this.goodsResult.classes.slice(0, NB_CLASS_DISPLAY) as GoodClass[];
            }

            return this.goodsResult.classes as GoodClass[];
        },
        showActionWord(): string {
            if (this.showAll) {
                return this.trans('message.goods.search.lessClass');
            }

            return this.trans('message.goods.search.moreClass');
        }
    },
    methods: {
        async onSearch(search: string) {
            if (this.searching || !search) {
                return;
            }

            this.search = search;
            this.searching = true;
            this.goodsResult = await api.drafter.search(search, this.appReference, this.country.displayCode.toLowerCase());
            this.searching = false;
        }
    }
});
</script>
