<template>
    <div class="card mb-4">
        <div class="card-body bg-light p-3">
            <div v-if="showAlert" class="alert alert-warning" role="alert">
                {{ this.alertMessage }}
            </div>

            <div class="row" v-if="displayImageInput ">
                <div class="col-sm-12 col-lg-10 col-xl-8">
                    <div class="input-group float-left">
                        <span
                            v-if="loading"
                            style="width: 1.5rem; height: 1.5rem;"
                            class="ml-2 spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                        />
                        <label
                            v-if="!loading"
                            class="bg-white image-btn p-2"
                        >
                            <i class="fa fa-folder-open" aria-hidden="true"></i>
                            <input
                                @change="onFileChange"
                                name="image"
                                type="file"
                                style="display:none;"
                                :accept="imageInput().accept"
                            >
                            {{ imageInput().wording }}
                        </label>
                    </div>
                </div>
            </div>

            <div class="row" v-if="displayWordsInput">
                <div class="col-xs-12 col-sm-12 col-md-6">
                    <stf-input
                        id="cy-mark-input"
                        name="markWord"
                        :label="trans('message.mark.form.label.trademark')"
                        :model-value="getTrademarkWord"
                        @input="onTrademarkWordChange"
                        :height="45"
                    />
                </div>
            </div>

            <div class="row mt-2" v-if="displayWordsInput">
                <div class="col-12">
                    <span>
                        <i class="fa fa-info-circle"></i>
                        {{ trans('message.mark.form.info.trademark') }}
                        <span class="btn-link" @click="onCapitalizeWordsChang">{{ trans('message.mark.form.info.here') }}</span>.
                    </span>
                </div>
            </div>

        </div>
    </div>
</template>

<script lang="ts">
import useCountry from '@/composables/countryDependable';
import {defineComponent} from 'vue';
import api from '@/core/api';
import {mapActions, mapGetters} from 'vuex';
import useInternalization from '@/composables/internalizable';
import HttpResponse from '@/core/http-module/http-response';
import HttpStatusError from '@/core/http-module/error/http-status-error';
import stfInput from '@/ui/input/stf-input.vue';

export default defineComponent({
    components: { stfInput },
    name: 'MarkInput',
    setup() {
        const country = useCountry();
        return { country, ...useInternalization(country) };
    },
    props: {
        displayImageInput: {
            type: Boolean,
            default: false
        },
        displayWordsInput: {
            type: Boolean,
            default: true
        }
    },
    watch: {
        showAlert(value: boolean) {
            if (value) {
                this.fadeAlert();
            }
        }
    },
    data() {
        return {
            loading: false,
            showAlert: false,
            alertMessage: '',
            capitalizeWords: true
        }
    },
    computed: {
        ...mapGetters('mark', {'getTrademarkWord': 'trademarkWord'}),
        ...mapGetters('application', ['appReference'])
    },
    methods: {
        ...mapActions('mark', ['setTrademarkImage', 'setTrademarkWord', 'setTrademarkImageRef']),
        async onFileChange(e: Event) {
            const alertMessageImageSizeExceed = this.trans('message.mark.error.image', {size:this.country.maxImageSize / 1000000});
            this.setTrademarkImage('');
            this.setTrademarkImageRef('');
            const files = (e.target as HTMLInputElement).files;
            if (!files || !files.length) {
                return;
            }

            const fileSize = files[0].size;
            if(fileSize > this.country.maxImageSize) {
                this.alertMessage = alertMessageImageSizeExceed;
                this.showAlert = true;
                return;
            }

            this.loading = true;
            const formData = new FormData();
            formData.append('file', files[0]);
            formData.append('applicationRef', this.appReference);
            const response = await api.markImage.postImage(this.country.displayCode.toLowerCase(), formData);

            if(response instanceof HttpStatusError && response.status === 413) {
                this.alertMessage = alertMessageImageSizeExceed;
                this.showAlert = true;
                return;
            }

            if (!(response instanceof HttpResponse)) {
                this.loading = false;
                this.alertMessage = this.trans('message.mark.alert.imageUpload');
                this.showAlert = true;
                return;
            }
            
            const {url, imageRef} = response.content.data;
            if (url) {
                this.setTrademarkImage(url);
            }

            if (imageRef) {
                this.setTrademarkImageRef(imageRef);
            }

            this.loading = false;
        },
        imageInput() {
            return {
                'wording': this.trans('message.mark.form.label.image'),
                'accept' : this.trans('message.mark.form.label.imageExtension')
            };
        },
        fadeAlert() {
            window.setTimeout(() => {
                this.showAlert = false;
            }, 7000)
        },
        onTrademarkWordChange(event: Event) {
            const newValue = this.capitalizeWords ? (event.target as HTMLInputElement).value.toUpperCase() :
                (event.target as HTMLInputElement).value;
            this.setTrademarkWord(newValue);
        },
        onCapitalizeWordsChang() {
            if(this.capitalizeWords === true) {
                this.capitalizeWords = false;
                this.setTrademarkWord(this.getTrademarkWord.toLowerCase());
            }
        }
    }
});
</script>
