import { australiaWorkflow } from '../workflow';
import { Country, CountryCode } from './type';

export const australia: Country = {
    name: 'Australia',
    code: CountryCode.AU,
    displayCode: CountryCode.AU,
    combinedDisplayCode: CountryCode.AU,
    workflow: australiaWorkflow,
    makeMethod: 'makeAustralia',
    theme: false,
    currency: '$',
    currencyName: 'AUD',
    currencyWithCountry: 'AU$',
    maxImageSize: 20000000, // 20MB
    maxGoodNumberPerClass: 100, // IP Australia only allows 100 goods per class for applications.
    paymentMethodsImageName: 'image-4-card',
    stripeKey: import.meta.env.VITE_STRIP_PUBLIC_KEY_AU,
    autoSelectedInForm: true,
};
