export const enum FieldType { 
    INPUT = 'StfFormInput',
    RADIO = 'StfFormRadio',
    DROPDOWN = 'StfFormDropdown',
    AUTOCOMPLETE = 'StfFormAutoComplete',
    HIDDEN = 'StfFormHiddenInput',
    DECLARATION = 'StfFormDeclaration'
}

export enum FieldConditionType { 
    DISPLAY = 'DISPLAY',
    LABEL = 'LABEL'
}

export interface FormFields {
    [name: string]: FormField;
}

export interface InitFormValues {
    [name: string]: string | number | boolean | undefined;
}

export type FieldValue = {
    message: string | undefined | null | number;
};

export type FieldCondition = DisplayCondition | LabelCondition;

export interface DisplayCondition {
    type: FieldConditionType.DISPLAY;
    on: string;
    check: (value: FieldValue) => boolean;
}

export interface LabelCondition {
    type: FieldConditionType.LABEL;
    on: string;
    check: (value: FieldValue) => string;
}

export interface FormField {
    options?: any;
    name: string;
    fieldType: FieldType;
    condition?: FieldCondition[];
}

export interface FormSchema {
    fields: FormField[];
    validation: any;
}
