import { computed, Ref } from 'vue';
import { LocationQuery } from 'vue-router';

export default function useRoutable(routeName: Ref<string>) {
    const route = computed<LocationQuery>(() => {
        return {
            name: routeName.value,
        };
    });

    return {
        route
    };
}
