import { useStore } from 'vuex';
import { onMounted } from 'vue';

export default function useCartReference() {
    const store = useStore();

    onMounted(() => {
        store.dispatch('application/generateCartRef');
    });
}
