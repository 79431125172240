import ApplicationCheckout, {ApplicationCheckoutLike} from './application-checkout';

export interface CheckoutLike {
    application: ApplicationCheckoutLike | null;
    extraClassFees: number | null;
    ref: string | null;
    total: number | null;
}

export default class Checkout {
    private _application: ApplicationCheckout | null;
    private _extraClassFees: number | null;
    private _ref: string | null;
    private _total: number | null;

    constructor() {
        this._application = null;
        this._extraClassFees = null;
        this._ref = null;
        this._total = null;
    }

    init(application: ApplicationCheckout | null, extraClassFees: number | null, ref: string | null, total: string) {
        this._application = application;
        this._extraClassFees = extraClassFees;
        this._ref = ref;
        this._total = parseFloat(total);
    }

    get ref() {
        return this._ref;
    }

    set ref(ref) {
        this._ref = ref;
    }

    get extraClassFees() {
        return this._extraClassFees;
    }

    set extraClassFees(extraClassFees) {
        this._extraClassFees = extraClassFees;
    }

    get application() {
        return this._application;
    }

    set application(val) {
        this._application = val;
    }

    get total() {
        return this._total;
    }

    set total(val) {
        this._total = val;
    }

    getCheckoutLike(): CheckoutLike {
        const checkoutLike = {
            application: null,
            extraClassFees: null,
            ref: this._ref,
            total: this._total,
        } as CheckoutLike;

        if (this._application !== null) {
            checkoutLike.application = this._application.getApplicationCheckoutLike()
        }

        return checkoutLike;
    }

    static hydrateCheckout(checkoutData: CheckoutLike): Checkout {
        const checkout = new Checkout();
        if (checkoutData.application !== null) {
            checkout.application = ApplicationCheckout.hydrateApplicationCheckout(checkoutData.application);
        }
        checkout.ref = checkoutData.ref;
        checkout.extraClassFees = checkoutData.extraClassFees;
        checkout.total = checkoutData.total;
        return checkout;
    }

    static getEmptyCheckout(): Checkout {
        const checkout = new Checkout();
        checkout.application = new ApplicationCheckout([], 0, 0, '', 0, 0, 0);
        
        return checkout;
    }
}
