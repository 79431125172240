<template>
    <global-error/>
    <info-help
        :tipsArr="[
            trans('message.mark.info.general'),
            trans('message.mark.info.logo')
        ]"
        tipsStyle="mark"
    />
    <div class="row justify-content-center">
        <div class="col-sm-12 col-lg-10 col-xl-8 ml-5">
            <radios
                :options="options"
                v-model="selected"
            />
        </div>
    </div>

    <mark-alert-form
        v-if="showAlertForm"
        :trademark-type="selected"
    />

    <!-- form -->
    <div class="row justify-content-center">
        <div class="col-sm-12 col-lg-10 col-xl-8">
            <mark-input
                :display-image-input="!isWords"
                :display-words-input="isWords"
                @validate="clickNextStep"
            />

            <div class="col-sm-12 ml-3 mb-3">&nbsp;<!-- Just to keep the space --></div>

            <mark-trademark-form
                :display-image-form="!isWords"
                :display-word-form="isWords"
            />
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-auto">
            <stf-button
                @click="clickNextStep"
                color="primary"
                class="float-right"
            >
                {{ trans('message.nextStep') }}
            </stf-button>
        </div>
    </div>
    <stf-footer>
        <div class="is-full-height is-flex is-justify-content-flex-end is-align-items-center is-flex-grow-1">
            <stf-button
                id="cy-next"
                @click="clickNextStep"
                color="primary"
                class="float-right"
            >
                {{ trans('message.nextStep') }}
            </stf-button>
        </div>
    </stf-footer>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue';
import useCountry from '@/composables/countryDependable';
import useAppReference from '@/composables/appReferenceable';
import useInternalization from '@/composables/internalizable';
import useWorkflowRouteGuard from '@/composables/workflow-route-guard';
import useLoggable from '@/composables/loggable';
import InfoHelp from '@/components/common/info-help.vue';
import Radios from '@/components/common/radios.vue';
import MarkInput from '@/components/mark-view/mark-input.vue';
import MarkTrademarkForm from '@/components/mark-view/mark-trademark-form.vue';
import MarkAlertForm from '@/components/mark-view/mark-alert-form.vue';
import {MarkType} from '@/core/interface/api-interface';
import RouteName from '@/router/route-name';
import {useStore} from 'vuex';
import useCartReference from '@/composables/cartReferenceable';
import StfButton from '@/ui/button/stf-button.vue';
import StfFooter from '@/components/stf-footer.vue';
import GlobalError from '@/components/global-error.vue';

export default defineComponent({
    name: 'MarkView',
    components: {
        InfoHelp,
        Radios,
        MarkInput,
        MarkTrademarkForm,
        MarkAlertForm,
        StfButton,
        StfFooter,
        GlobalError
    },
    setup() {
        const country = useCountry();
        useAppReference(country.displayCode);
        useCartReference();
        const store = useStore();
        const selected = ref(store.getters['mark/getMarkType']);

        async function isValid() {

            // trademarkWord is empty
            if (selected.value === MarkType.WORDS) {
                return store.getters['mark/trademarkWord'] !== '';
            }

            // trademarkImage is empty
            if (selected.value === MarkType.IMAGE) {
                return store.getters['mark/trademarkImage'] !== '';
            }

            return true;
        }

        useWorkflowRouteGuard(isValid);

        return { country, isValid, selected, ...useLoggable(), ...useInternalization(country)};
    },
    data() {
        return {
            showAlertForm: false,
        }
    },
    computed: {
        isWords(): boolean {
            return this.selected === MarkType.WORDS;
        },
        options(): Array<{ text: string; value: MarkType}> {
            return [
                {text: this.trans('message.mark.choice.word'), value: MarkType.WORDS},
                {text: this.trans('message.mark.choice.logo'), value: MarkType.IMAGE},
            ]
        }
    },
    methods: {
        async clickNextStep() {
            if (!await this.isValid()) {
                this.showAlertForm = true;
                return;
            }

            this.$router.push({name: RouteName.GOODS})
        }
    }
});
</script>

