import { useRoute } from 'vue-router';
import { URLifyCountry } from '@/core/util/country-utils';
import countries from '@/config/country';
import { Country } from '@/config/country/type';

export default function useCountry(): Country {
    const route = useRoute();

    if (!route.params.country) {
        throw 'The parameter \'country\' is not present in this url';
    }

    const countryFound = countries.find(country => {
        return URLifyCountry(country.displayCode) === route.params.country;
    });

    if (!countryFound) {
        throw '\'country\' not found';
    }

    return countryFound;
}
