<template>
    <div>
        404 Not Found
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'NotFound'
});
</script>
