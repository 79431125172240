<template>
    <component
        v-if="canDisplayField"
        :is="field.fieldType"
        :name="field.name"
        v-bind="field.options"
        :label="displayLabel()"
        :validation-on-fly="validationOnFly"
    />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import StfFormInput from './stf-form-input.vue';
import StfFormRadio from './stf-form-radio.vue';
import StfFormDropdown from './stf-form-dropdown.vue';
import StfFormDeclaration from './stf-form-declaration.vue'
import StfFormAutoComplete from './stf-form-autocomplete.vue';
import StfFormHiddenInput from './stf-form-hidden-input.vue';
import {FieldValue, FormField, DisplayCondition, LabelCondition, FieldConditionType} from './type';
import { useFormValues } from 'vee-validate';

export default defineComponent({
    name: 'StfFormField',
    components: {
        StfFormInput,
        StfFormDropdown,
        StfFormAutoComplete,
        StfFormHiddenInput,
        StfFormRadio,
        StfFormDeclaration
    },
    props: {
        field: {
            type: Object as PropType<FormField>,
            required: true
        },
        validationOnFly: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        return {
            valuesForm: useFormValues()
        };
    },
    computed: {
        canDisplayField(): boolean {
            const conditions = this.field.condition?.filter(condition => condition.type === FieldConditionType.DISPLAY) as DisplayCondition[] || undefined;
            if (!conditions) {
                return true;
            }

            let valid = true;
            conditions.forEach(condition => {
                valid = valid && condition.check(this.valuesForm[condition.on] as FieldValue);
            });


            return valid;
        }
    },
    methods: {
        displayLabel() {  
            if (this.field.options && this.field.options.label) { 
                return this.field.options.label; 
            }

            const condition = this.field.condition?.filter(condition => condition.type === FieldConditionType.LABEL)[0] as LabelCondition;

            return condition?.check(this.valuesForm[condition.on] as FieldValue);
        }
    }
});
</script>
