import { RootState } from '.';
import { Module } from 'vuex';

const contact: Module<any, RootState> = {
    getters: {
        dataForSimilarApplication: (state, getters, rootState) => {
            return {
                good: {
                    goods: [...rootState.good.goods],
                    goodsPrice: { ...rootState.good.goodsPrice }
                },
                owners: {...rootState.owners},
                ownerDetails: { ...rootState.ownerDetails },
                contact: { ...rootState.contact },
                app: { ...rootState.app }
            };
        }
    },
    actions: {
        resetApplication({ dispatch }) {
            dispatch('application/reset');
            dispatch('good/reset');
            dispatch('mark/reset');
            dispatch('ownerDetails/reset');
            dispatch('owners/reset');
            dispatch('workflow/reset');
            dispatch('contact/reset');
            dispatch('app/reset');
        },
        loadDataForSimilarApplication({ dispatch }, stateData) {
            if (stateData === null) {
                return;
            }

            dispatch('good/loadState', stateData.good);
            dispatch('ownerDetails/loadState', stateData.ownerDetails);
            dispatch('owners/loadState', stateData.owners);
            dispatch('contact/loadState', stateData.contact);
            dispatch('app/loadState', stateData.app);
        }
    }
};

export default contact;
