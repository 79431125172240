<template>
    <stf-button
        @click="logout"
        class="mr-1"
        :color="color"
        outlined
    >
        Logout
    </stf-button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import authentication from '@/core/authentication/authentication';
import StfButton from '@/ui/button/stf-button.vue';
import RouteName from '@/router/route-name';
import logger from '@/core/logger/logger';

export default defineComponent({
    name: 'Logout',
    components: {
        StfButton
    },
    methods : {
        async logout() {
            try {
                await authentication.logout()
                this.$router.push({name: RouteName.LOGIN});
            } catch (error) {
                logger.error('Logout failed', error)
            }
        },
    },
    props: {
        color: {
            type: String,
            default: 'primary'
        }
    }
});
</script>
