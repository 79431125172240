<template>
    <div style="position: relative;">
        <img class="trademark-banner" src="/img/BannerBackground.jpg">
        <div class="et_pb_bottom_inside_divider"></div>
        <span class="banner-words">Application in progress</span>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'trademark-banner'
})
</script>
