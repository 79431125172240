<template>
    <div class="mb-3">
        <div
            v-for="item in options"
            :key="item.value"
            class="form-check"
        >
            <input
                type="radio"
                :style="{accentColor: '#0070BA'}"
                :value="item.value"
                v-model="selected"
                @change="onChange"
                class="form-check-input"
                :id="item.value"
                name="gridRadios"
            >
            <label class="form-check-label" :for="item.value">
                {{ item.text }}
            </label>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {MarkOwnerType} from '@/core/interface/api-interface';

export default defineComponent({
    name: 'radios',
    props: {
        options: {
            type: Array,
            default: () => []
        },
        modelValue: {
            type: Number,
            default: MarkOwnerType.PERSON
        }
    },
    data() {
        return {
            selected: this.modelValue
        }
    },
    methods: {
        onChange(event: Event) {
            this.$emit('update:modelValue', parseInt((event.target as HTMLInputElement).value))
        }
    }
});
</script>
