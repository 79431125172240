<template>
    <div
        v-if="getMarkStatus.show"
        class="row justify-content-center"
    >
        <div class="col-sm-12 col-lg-10 col-xl-8">
            <div class="alert alert-danger alert-form-error">
                <strong>{{ trans('message.mark.alert.title') }}</strong>
                <ul class="m-0">
                    <li v-if="getMarkStatus.showTrademarkWordAlert">{{ trans('message.mark.alert.word') }}</li>
                    <li v-if="getMarkStatus.showTrademarkImageAlert">{{ trans('message.mark.alert.image') }}</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {mapGetters} from 'vuex';
import {MarkType} from '@/core/interface/api-interface';
import useInternalization from '@/composables/internalizable';
import useCountry from '@/composables/countryDependable';

export default defineComponent({
    name: 'MarkAlertForm',
    props: {
        trademarkType: {
            type: Number,
            default: MarkType.WORDS
        }
    },
    setup() {
        return { ...useInternalization(useCountry())};
    },
    data() {
        return {}
    },
    computed: {
        ...mapGetters('mark', ['trademarkWord', 'trademarkImage']),
        getMarkStatus(): any {
            const status = {
                show: false,
                showTrademarkWordAlert: false,
                showTrademarkImageAlert: false,
            };

            const showTrademarkWordAlert = this.trademarkType === MarkType.WORDS && !this.trademarkWord;
            const showTrademarkImageAlert = this.trademarkType === MarkType.IMAGE && !this.trademarkImage;
            status.show = showTrademarkWordAlert || showTrademarkImageAlert;
            status.showTrademarkWordAlert = this.trademarkType === MarkType.WORDS && !this.trademarkWord;
            status.showTrademarkImageAlert = this.trademarkType === MarkType.IMAGE && !this.trademarkImage;

            return status;
        }
    }
});
</script>
