<template>
    <div class="sub-menu-item">
        <external-link :href="menu.url">{{ menu.label }}</external-link>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import ExternalLink from "@/components/common/external-link.vue";

export default defineComponent({
    name: 'TmpUkSubMenuItem',
  components: {ExternalLink},
    props: {
        menu: {
            type: Object,
            required: true
        }
    }
});
</script>

<style scoped>
.sub-menu-item {
	padding: 0 20px;
}

.sub-menu-item > a {
	text-transform: uppercase;
	font-size: 15px;
	color: rgba(102, 102, 102, 0.9) !important;
	letter-spacing: -0.3px;
	font-weight: 500;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	padding: 6px 20px;
	display: block;
	width: 100%;
}

.sub-menu-item > a:hover {
	background-color: rgba(0, 0, 0, 0.03);
	opacity: 0.7;
}
</style>
