export default {
    message: {
        mark: {
            info: {
                logo: 'Logos <b>must</b> be uploaded as GIF or JPG files.'
            },
            form: {
                label: {
                    image: 'Select GIF or JPG file',
                    imageExtension: '.jpg,.gif'
                }
            }
        },
        success: {
            submitInfo: 'Your New Zealand trademark application will be submitted to the Trademark Office in one business day. <br/> Please check your emails for our order confirmation and payment receipt/tax invoice.',
            thanks: 'Thanks for using Trademarks Online.',
        }
    }
};
