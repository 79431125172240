import { Mark, MarkImageInterface, MarkType, MarkWordInterface } from '@/core/interface/api-interface';
import { Module } from 'vuex';
import { RootState } from '.';
import {
    MUT_RESET,
    MUT_SET_TRADEMARK_WORD,
    MUT_SET_TRADEMARK_IMAGE,
    MUT_SET_TRADEMARK_IMAGE_REF
} from './mark-mutation';
import useDomain from '@/composables/domainDependable';

export interface MarkState {
    trademarkWord: string;
    trademarkImage: string;
    trademarkImageRef: string;
}

const mark: Module<MarkState, RootState> = {
    namespaced: true,
    state: {
        trademarkWord: '',
        trademarkImage: '',
        trademarkImageRef: ''
    },
    getters: {
        trademarkWord: ({ trademarkWord }) => trademarkWord,
        trademarkImage: ({ trademarkImage }) => trademarkImage,
        getMarkType: (state): MarkType => {
            if (state.trademarkImage !== '') {
                return MarkType.IMAGE;
            }

            return MarkType.WORDS;
        },
        getMarkWordDataToSubmit: (state): MarkWordInterface | null => {
            if (state.trademarkWord !== '') {
                return {
                    word: state.trademarkWord
                };
            }

            return null;
        },
        getMarkImageDataToSubmit: (state): MarkImageInterface | null => {
            if (state.trademarkImageRef !== '') {
                return {
                    imageRef: state.trademarkImageRef,
                };
            }

            return null;
        },
        getMarkDataToSubmit: (state, getters, rootState, rootGetters): Mark => {
            const domain = useDomain();
            return {
                markSource: domain.shortCode,
                markWord: getters.getMarkWordDataToSubmit,
                markImage: getters.getMarkImageDataToSubmit,
                markContact: rootGetters['contact/getMarkContactDataToSubmit'],
                markOwners: rootGetters['owners/getMarkOwnersDataToSubmit'],
                markOwnerDetail: rootGetters['ownerDetails/getMarkOwnerDetailsDataToSubmit'],
                markGoods: rootGetters['good/getGoodDataToSubmit']
            };
        }
    },
    actions: {
        setTrademarkWord({ commit }, value: string) {
            commit(MUT_SET_TRADEMARK_WORD, value);
        },
        setTrademarkImage({ commit }, value: string) {
            commit(MUT_SET_TRADEMARK_IMAGE, value);
        },
        setTrademarkImageRef({ commit }, value: string) {
            commit(MUT_SET_TRADEMARK_IMAGE_REF, value);
        },
        reset({ commit }) {
            commit(MUT_RESET);
        },
        loadState({ commit }, value: any) {
            if(value.trademarkWord) {
                commit(MUT_SET_TRADEMARK_WORD, value.trademarkWord);
            } else {
                commit(MUT_SET_TRADEMARK_IMAGE, value.trademarkImage);
                commit(MUT_SET_TRADEMARK_IMAGE_REF, value.trademarkImageRef);
            }
        }
    },
    mutations: {
        [MUT_RESET](state) {
            state.trademarkWord = '';
            state.trademarkImage = '';
            state.trademarkImageRef = '';
        },
        [MUT_SET_TRADEMARK_WORD](state, value: string) {
            state.trademarkWord = value;
            state.trademarkImage = '';
            state.trademarkImageRef = '';
        },
        [MUT_SET_TRADEMARK_IMAGE](state, value: string) {
            state.trademarkImage = value;
            state.trademarkWord = '';
        },
        [MUT_SET_TRADEMARK_IMAGE_REF](state, value: string) {
            state.trademarkImageRef = value;
        }
    }
};

export default mark;
