<template>
    <div>
        <label v-if="label.length > 0" class="subheader stf-text-grey">
            {{ label }}
        </label>
        <template v-for="item in radio" :key="item.value">
            <div class="is-block pl-6 body-2">
                <field
                    :style="{accentColor: '#0070BA'}"
                    :id="item.value"
                    :name="name"
                    type="radio"
                    :value="item.value"
                    :validateOnBlur="validationState"
                    :validateOnChange="validationState"
                    :validateOnInput="validationState"
                ></field><label :for="item.value">{{ item.label }}</label>
            </div>
        </template>
    </div>
    
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { Field, useFieldError } from 'vee-validate';


export default defineComponent({
    name: 'StfFormRadio',
    components: {
        Field
    },
    props: {
        name: {
            type: String,
            required: true
        },
        rules: {
            default: undefined
        },
        validationOnFly: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        required: {
            type: Boolean,
            default: false,
        },
        radio: {
            type: Array,
            default: () => []
        }
    },
    setup(props) {

        const errorMessage = useFieldError(props.name);

        const validationState = computed(() => {
            // If the field is valid or have not been validated yet
            // no validation
            if (!errorMessage.value) {
                return false;
            }

            // Aggressive
            return true;
        });
    
        return {
            validationState
        };
    }
});
</script>
