<template>
    <section>
        <fieldset
            v-if="!!trademarkWord && displayWordForm"
            class="card card-body bg-light mb-3"
        >
            <legend class="font-eighteen"> {{ trans('message.mark.yourTrademark') }}</legend>

            <div class="row pl-4 panel-heading">
                <div class="col-12 good-name-word">
                    {{ trademarkWord }}
                </div>
                <div class="col-12">
                    <div
                        @click="deleteTradeMarkWord"
                        class="fa fa-times mark-btn-default float-right delete-trademark-btn"
                    />
                </div>
            </div>
        </fieldset>

        <fieldset
            v-if="!!trademarkImage && displayImageForm"
            class="card card-body bg-light mb-3"
        >
            <legend class="font-eighteen">{{ trans('message.mark.yourTrademark') }}</legend>

            <div class="row panel-heading">
                <div class="col-12 col-lg-4">
                    <img class="img-fluid" :src="getImageUrl()"/>
                </div>
                <div class="col-12">
                    <div @click="deleteTradeMarkImage"
                         class="fa fa-times mark-btn-default float-right delete-trademark-btn"/>
                </div>
            </div>
        </fieldset>
    </section>

</template>

<script lang="ts">
import useCountry from '@/composables/countryDependable';
import useInternalization from '@/composables/internalizable';
import {defineComponent} from 'vue';
import {mapGetters, mapActions} from 'vuex';

export default defineComponent({
    name: 'MarkTrademarkForm',
    props: {
        displayImageForm: {
            type: Boolean,
            default: false
        },
        displayWordForm: {
            type: Boolean,
            default: true
        }
    },
    setup() {
        return { ...useInternalization(useCountry()) };
    },
    computed: {
        ...mapGetters('mark', ['trademarkWord', 'trademarkImage']),
    },
    methods: {
        ...mapActions('mark', ['setTrademarkWord', 'setTrademarkImage', 'setTrademarkImageRef']),
        deleteTradeMarkWord() {
            this.setTrademarkWord('');
        },
        deleteTradeMarkImage() {
            this.setTrademarkImage('');
            this.setTrademarkImageRef('');
        },
        getImageUrl() {
            return window.location.origin + this.trademarkImage
        }
    }
});
</script>
