<template>
    <stf-row>
        <stf-col size="is-full">
            <stf-heading-two>
                {{title}}
            </stf-heading-two>
        </stf-col>
        <stf-col size="is-full">
            <slot></slot>
        </stf-col>
    </stf-row>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'MarkDetailsSection',
    props: {
        title: {
            type: String,
            required: true
        },
    },
});
</script>
