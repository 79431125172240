import { MarkOwnerInterface, MarkOwnerType } from '../interface/api-interface';
import { getFirstAndLastNameFromString, isBusinessNumber } from '../util/owner-utils';

export interface OwnerLike {
    markOwnerType: MarkOwnerType;
    firstName: string;
    lastName: string;
    businessName: string;
    citizenCountryCode: string;
    businessNumber: string;
    companyNumber: string;
}

export default class Owner {
    private _markOwnerType: MarkOwnerType;
    private _firstName = '';
    private _lastName = '';
    private _businessName = '';
    private _citizenCountryCode = '';
    private _businessNumber = '';
    private _companyNumber = '';

    constructor(
        markOwnerType: MarkOwnerType,
        firstName?: string,
        lastName?: string,
        businessName?: string,
        citizenCountryCode?: string,
        businessNumber?: string,
        companyNumber?: string,
    ) {
        this._markOwnerType = markOwnerType;
        firstName && (this._firstName = firstName);
        lastName && (this._lastName = lastName);
        businessName && (this._businessName = businessName);
        citizenCountryCode && (this._citizenCountryCode = citizenCountryCode);
        businessNumber && (this._businessNumber = businessNumber);
        companyNumber && (this._companyNumber = companyNumber);
    }

    get markOwnerType() {
        return this._markOwnerType;
    }

    set markOwnerType(val) {
        this._markOwnerType = val;
    }

    get firstName() {
        return this._firstName;
    }

    set firstName(val) {
        this._firstName = val;
    }

    get lastName() {
        return this._lastName;
    }

    set lastName(val) {
        this._lastName = val;
    }

    get businessName() {
        return this._businessName;
    }

    set businessName(val) {
        this._businessName = val;
    }

    get citizenCountryCode() {
        return this._citizenCountryCode;
    }

    set citizenCountryCode(val) {
        this._citizenCountryCode = val;
    }

    get companyNumber() {
        return this._companyNumber;
    }

    set companyNumber(companyNumber) {
        if (isBusinessNumber(companyNumber, this._citizenCountryCode)) {
            this._businessNumber = companyNumber;
            return
        }

        this._companyNumber = companyNumber;
    }

    get businessNumber() {
        return this._businessNumber;
    }

    set businessNumber(val) {
        this._businessNumber = val;
    }

    get isBusiness() {
        return MarkOwnerType.BUSINESS === this._markOwnerType;
    }

    setOwnerName(name: string) {
        if (this.isBusiness) {
            this._businessName = name;
            return;
        }

        const {firstName, lastName} = getFirstAndLastNameFromString(name);

        this._firstName = firstName;
        this._lastName = lastName;
    }

    get apiData(): MarkOwnerInterface {
        return {
            markOwnerType: this._markOwnerType,
            firstName: this._firstName !== '' ? this._firstName : null,
            lastName: this._lastName !== '' ? this._lastName : null,
            businessName: this._businessName !== '' ? this._businessName : null,
            citizenCountryCode: this._citizenCountryCode !== '' ? this._citizenCountryCode : null,
            businessNumber: this._businessNumber !== '' ? this._businessNumber : null,
            companyNumber: this._companyNumber !== '' ? this._companyNumber : null
        };
    }

    get name() {
        return (this._firstName === '' && this._lastName === '') ? '' : this._firstName + ' ' + this._lastName;
    }

    public isSameAs(owner: Owner | OwnerLike): boolean {
        return owner.firstName === this.firstName &&
            owner.lastName === this.lastName &&
            owner.businessName === this.businessName &&
            owner.citizenCountryCode === this.citizenCountryCode &&
            owner.businessNumber === this.businessNumber &&
            owner.companyNumber === this.companyNumber;
    }

    getOwnerLike(): OwnerLike {
        return {
            markOwnerType: this._markOwnerType,
            firstName: this._firstName,
            lastName: this._lastName,
            businessName: this._businessName,
            citizenCountryCode: this._citizenCountryCode,
            businessNumber: this._businessNumber,
            companyNumber: this._companyNumber,
        };
    }

    static hydrateGood(owner: OwnerLike): Owner {
        return new Owner(
            owner.markOwnerType,
            owner.firstName,
            owner.lastName,
            owner.businessName,
            owner.citizenCountryCode,
            owner.businessNumber,
            owner.companyNumber,
        );
    }
}
