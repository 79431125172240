<template>
    <tmp-uk-header-replica v-if="domainShortCode === 'TMP-UK'"/>
    <trademark-banner v-if="domainShortCode === 'TMP-UK'"/>
    <tmo-uk-header-replica v-if="domainShortCode === 'TMO-UK'"/>
    <diy-nz-header-replica v-if="domainShortCode === 'DIY-NZ'"/>
    <tmo-au-header-replica v-if="domainShortCode === 'TMO-AU'"/>
    <tmo-sg-header-replica v-if="domainShortCode === 'TMO-SG'"/>
    <tmo-my-header-replica v-if="domainShortCode === 'TMO-MY'"/>
    <tmo-bx-header-replica v-if="domainShortCode === 'TMO-BX'"/>
    <tmo-nz-header-replica v-if="domainShortCode === 'TMO-NZ'"/>
    <tmo-hk-header-replica v-if="domainShortCode === 'TMO-HK'"/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TmpUkHeaderReplica from '@/header-footer-replicas/headers/tmp-uk-header/tmp-uk-header-replica.vue';
import TrademarkBanner from '@/components/trademark-banner/trademark-banner.vue';
import TmoUkHeaderReplica from '@/header-footer-replicas/headers/tmo-uk-header/tmo-uk-header-replica.vue';
import TmoSgHeaderReplica from '@/header-footer-replicas/headers/tmo-sg-header/tmo-sg-header-replica.vue';
import DiyNzHeaderReplica from '@/header-footer-replicas/headers/diy-nz-header/diy-nz-header-replica.vue';
import TmoAuHeaderReplica from '@/header-footer-replicas/headers/tmo-au-header/tmo-au-header-replica.vue';
import TmoMyHeaderReplica from '@/header-footer-replicas/headers/tmo-my-header/tmo-my-header-replica.vue';
import TmoBxHeaderReplica from '@/header-footer-replicas/headers/tmo-bx-header/tmo-bx-header-replica.vue';
import TmoNzHeaderReplica from '@/header-footer-replicas/headers/tmo-nz-header/tmo-nz-header-replica.vue';
import TmoHkHeaderReplica from '@/header-footer-replicas/headers/tmo-hk-header/tmo-hk-header-replica.vue';
import useDomain from '@/composables/domainDependable';

export default defineComponent({
    name: 'LayoutHeader',
    components: {
        TmpUkHeaderReplica,
        TrademarkBanner,
        TmoUkHeaderReplica,
        DiyNzHeaderReplica,
        TmoAuHeaderReplica,
        TmoSgHeaderReplica,
        TmoMyHeaderReplica,
        TmoBxHeaderReplica,
        TmoNzHeaderReplica,
        TmoHkHeaderReplica
    },
    setup() {
        const domain = useDomain();
        window.document.title = domain.title;

        return {domain};
    },
    computed: {
        domainShortCode(): string {
            return this.domain.shortCode
        }
    }
});
</script>
