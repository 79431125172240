import api from '@/core/api';

interface credentials {
    email: string;
    password: string;
}

class Authentication {
    async login(data: credentials) {
        const response = await api.authApi.login({
            email: data.email, 
            password: data.password
        });
        return response;
    }

    async logout() {
        await api.authApi.logout();
    }

    async isAuth(): Promise<boolean> {
        try {
          await api.trademarkRecords.get();
          return true;
        } catch (error) {
          return false; 
        }
      }

    async resetPassword(email: string) {
        const response = await api.authApi.resetPassword({
            email: email
        }); 
        return response;
    }

    async resetPasswordReset(password: string) {
        const response = await api.authApi.resetPasswordReset({
            plainPassword: password
        }); 
        return response;
    }
}

export default new Authentication();