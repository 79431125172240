import portalApiClient from './client/portal-api';
import DrafterSearch from '@/core/model/drafter-search';
import GoodClass from '@/core/model/good-class';
import Good from '@/core/model/good';
import HttpResponse from '../http-module/http-response';

const drafter = {
    async search(search: string, appReference: string, countryCode: string): Promise<DrafterSearch> {
        const response = await portalApiClient.get(countryCode + '/drafter-search?q=' + search + '&ref=' + appReference);
        if (response instanceof HttpResponse) {
            const drafterSearch = new DrafterSearch();
            const data = response.content.data as [];
            data.forEach((goodClassItem: any) => {
                const goodClass = new GoodClass(goodClassItem.id, goodClassItem.number, goodClassItem.name);

                goodClassItem.suggestions.forEach((good: any) => {
                    goodClass.addSuggestion = new Good(good.id, good.class_number, good.name);
                });

                drafterSearch.addClass(goodClass);
            });

            return drafterSearch;
        }

        return new DrafterSearch();
    }
};

export default drafter;
