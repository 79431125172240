import { Domain } from '@/config/domain/type';

export const trademarksOnlineSingapore: Domain = {
    domain: import.meta.env.VITE_TMO_SG_DOMAIN,
    shortCode: 'TMO-SG',
    title: 'Apply for your Singapore trademark',
    siteName: 'Trademarks Online',
    description: 'Our online trademark registration platform is available 24/7 and takes just minutes. Apply for your Singapore trademark by entering your trademark or logo.',
    headerColor: '#980E0E',
    footerColor: '#980E0E',
    mobileMenuColor: '#980E0E',
    contactFlagImgUrl: '',
    menuItems: [
        {
            label: 'Home',
            url: 'https://www.trademarksonline.com.sg/',
            child: []
        }, {
            label: 'Need to know',
            url: 'https://www.trademarksonline.com.sg/need-to-know',
            child: []
        }, {
            label: 'Pricing',
            url: 'https://www.trademarksonline.com.sg/pricing',
            child: []
        }, {
            label: 'FAQ',
            url: 'https://www.trademarksonline.com.sg/faq',
            child: []
        }, {
            label: 'Contact',
            url: 'https://www.trademarksonline.com.sg/contact',
            child: []
        }
    ],
    headerImageUrl: 'https://images.squarespace-cdn.com/content/v1/62687d32611a1c7ec290db63/31ef77a7-0d83-4fdc-aa22-257c7e08afbd/SGTMO%2Bwords%2Band%2Bflag%2Bon%2Bred%2B%281%29.jpg',
    headerImageTitle: 'Trademarks Online Singapore',
    bannerImageUrl: 'https://images.squarespace-cdn.com/content/v1/62687d32611a1c7ec290db63/785fb472-6d20-4dff-8fe6-95ef1225e315/Singapore%2Bbanner%2Bupdated%2B05052022%2B%281%29.jpg',
    termOfUseUrl: 'https://www.trademarksonline.com.sg/terms-of-use',
    declarationTitle: 'I confirm that',
    declarationList: [
        'The applicant intends to use, and is entitled to own, the trademark',
        'The TM Office will not let me change the trademark or goods and services after it has been submitted'
    ],
    gtmId: 'GTM-5MC6GKWP',
};
