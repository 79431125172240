import { Module } from 'vuex';
import { RootState } from '.';
import api from '@/core/api';
import type { Mark } from '@/core/api/user-console/type';
import {
    MUT_SET_MARKS_RECORDS,
    MUT_SET_MARK_RECORD_DETAILS,
    MUT_SET_ERROR
} from './marks-records-mutation';

export interface MarksRecordsState {
    marksRecords: Mark[];
    markRecordDetails: Mark | null;
    error: string | null;
}

const marksRecords: Module<MarksRecordsState, RootState> = {
    namespaced: true,
    state: {
        marksRecords: [],
        markRecordDetails: null,
        error: null,
    },
    getters: {
        getMarksRecords: ({ marksRecords }) => marksRecords,
        getMarkRecordDetails: ({ markRecordDetails }) => markRecordDetails,
        getMarksRecordsError: ({ error }) => error,
    },
    actions: {
        async setMarksRecordsFromApi({ commit }) {
            try {
              const marksRecords = await api.trademarkRecords.get();
              commit('MUT_SET_MARKS_RECORDS', marksRecords);
              commit('MUT_SET_ERROR', null);
            } catch (e) {
              commit('MUT_SET_ERROR', 'Failed to fetch trademark records');
            }
        },
        setMarkRecordDetails({ commit }, markRecordDetails) {
            commit('MUT_SET_MARK_RECORD_DETAILS', markRecordDetails);
        }
    },
    mutations: {
        [MUT_SET_MARKS_RECORDS](state, marksRecords) {
            state.marksRecords = marksRecords;
        },
        [MUT_SET_MARK_RECORD_DETAILS](state, markRecordDetails) {
            state.markRecordDetails = markRecordDetails;
        },
        [MUT_SET_ERROR](state, error) {
            state.error = error;
        },
    }
};

export default marksRecords;
