import HttpError from './http-error';

export default class HttpStatusError extends HttpError {
    private _status: number;
    private _content: any;

    constructor(url: string, httpMethod: string, status: number, content: any) {
        super(url, httpMethod);
        this._status = status;
        this._content = content;
    }

    get status() {
        return this._status;
    }

    get content() {
        return this._content;
    }

    get message() {
        return `Request ${this.httpMethod} ${this.url} end up with status ${this._status}`;
    }
}
