<template>
    <teleport to="#GlobalError" v-if="teleportMounted">
        <div v-if="message.length > 0" class="col-12 col-sm-12 col-lg-10 col-xl-8">
            <div class="alert alert-danger" role="alert">
                {{ message }}
                <ul v-if="list.length > 0" class="m-0">
                    <li v-for="(item, index) in list" :key="index">{{ item }}</li>
                </ul>
            </div>
        </div>
    </teleport>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';

export default defineComponent({
    name: 'GlobalError',
    props: {
        message: {
            type: String,
            default: ''
        },
        list: {
            type: Array,
            default: () => []
        }
    },
    setup() {
        const teleportMounted = inject('teleportMounted')

        return {
            teleportMounted
        }
    }
});
</script>
