<template>
    <stf-input
        class="stf-form-field"
        v-on="validationListeners"
        v-model="value"
        :name="name"
        :error-message="errorMessage"
        v-bind="$attrs"
    />
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useField } from 'vee-validate';
import StfInput from '@/ui/input/stf-input.vue';

export default defineComponent({
    name: 'StfFormInput',
    components: {
        StfInput
    },
    props: {
        name: {
            type: String,
            required: true
        },
        rules: {
            default: undefined
        },
        validationOnFly: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const { errorMessage, value, handleChange } = useField(props.name, props.rules, { validateOnValueUpdate: props.validationOnFly });

        const validationListeners = computed(() => {
            // If the field is valid or have not been validated yet
            // no validation
            if (!errorMessage.value) {
                return {
                    blur: (e: InputEvent) => handleChange(e, false),
                    change: (e: InputEvent) => handleChange(e, false),
                    input: (e: InputEvent) => handleChange(e, false),
                };
            }

            // Aggressive
            return {
                blur: handleChange,
                change: handleChange,
                input: handleChange,
            };
        });
    
        return {
            errorMessage,
            value,
            validationListeners,
        };
    }
});
</script>
