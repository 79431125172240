import { unitedKingdomWorkflow } from '../workflow';
import { Country, CountryCode } from './type';

export const unitedKingdom: Country = {
    name: 'UK',
    code: CountryCode.GB,
    displayCode: CountryCode.UK,
    combinedDisplayCode: CountryCode.UK,
    workflow: unitedKingdomWorkflow,
    makeMethod: 'makeUnitedKingdom',
    theme: true,
    currency: '£',
    currencyName: 'GBP',
    currencyWithCountry: '£',
    maxImageSize: 4000000,  // 4MB
    maxGoodNumberPerClass: 1000,
    paymentMethodsImageName: 'image-7-card',
    stripeKey: import.meta.env.VITE_STRIP_PUBLIC_KEY_UK,
    autoSelectedInForm: true,
};
