<template>
    <div :class="classes" :style="style">
        <slot></slot>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import { CssProperties } from '../type';

export default defineComponent({
    name: 'stf-sheet',
    props: {
        color: {
            type: String,
            default: 'primary-dark'
        },
        elevation: {
            type: String,
            default: 'flat'
        },
        fullWidth: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        style(): CssProperties {
            const style = {} as CssProperties;

            if (this.color.includes('#')) {
                style.backgroundColor = this.color;
            }

            return style;
        },
        classes(): string[] {
            
            const classes = [
                'stf-sheet',
                `stf-sheet--${this.elevation}`
            ];

            if (this.fullWidth) {
                classes.push('stf-sheet--full-width');
            }

            if (!this.color.includes('#')) {
                classes.push(`stf-sheet--${this.color}`);
            }

            return classes;
        }
    }
});
</script>
