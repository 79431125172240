<template>
    <div class="mb-6 is-flex is-flex-direction-column">
        <layout-header/>
        <section>
            <div class="container">
                <stepper-builder v-if="stepper" :form-method="country.makeMethod"/>
                <div id="GlobalError" class="row justify-content-center">
                <!-- space for error msg -->
                </div>
                <slot/>
            </div>
        </section>
        <layout-footer />
    </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import LayoutFooter from '@/layouts/layout-footer.vue';
import layoutHeader from '@/layouts/layout-header.vue';
import StepperBuilder from '@/components/stepper/builder.vue';
import useDomain from '@/composables/domainDependable';
import useGlobalErrorTeleport from '@/composables/globalErrorTeleportable';
import { useRoute } from 'vue-router';
import { URLifyCountry } from '@/core/util/country-utils';
import countries from '@/config/country';

export default defineComponent({
    name: 'DefaultLayout',
    props: {
        stepper: {
            type: Boolean,
            default: false
        }
    },
    components: {
        LayoutFooter,
        layoutHeader,
        StepperBuilder
    },
    setup() {
        useGlobalErrorTeleport();
        const route = useRoute();
        const domain = useDomain();
        window.document.title = domain.title;

        const country = computed(() => {
            const countryFound = countries.find(country => {
                return URLifyCountry(country.displayCode) === route.params.country;
            });
            if (!countryFound) {
                return {
                    makeMethod: 'makeAustralia'
                };
            }

            return countryFound
        })

        return {domain, country};
    },
    computed: {
        domainShortCode(): string {
            return this.domain.shortCode
        }
    }
});
</script>
