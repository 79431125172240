import storage from '@/store/storage/storage';
import { getSpecificKeyForCountry } from './storage-helpers';

const storageVersion = import.meta.env.VITE_STORAGE_VERSION;

export default {
    getItem: (defaultKey: string): string|null => {
        const key = getSpecificKeyForCountry(defaultKey, window.location.pathname, storageVersion);

        return storage.getItem(key);
    },
    setItem: (defaultKey: string, value: string): void => {
        const key = getSpecificKeyForCountry(defaultKey, window.location.pathname, storageVersion);

        storage.setItem(key, value);
    },
    removeItem: (defaultKey: string): void => {
        const key = getSpecificKeyForCountry(defaultKey, window.location.pathname, storageVersion);

        storage.removeItem(key);
    },
};
