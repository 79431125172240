import userApiClient from '@/core/api/client/user-api';
import HttpResponse from '@/core/http-module/http-response';
import type { Mark } from '@/core/api/user-console/type';

const trademarkRecords = {
    async get(): Promise<Mark[]> {
        const response =  await userApiClient.get('marks');

        if (!(response instanceof HttpResponse)) {
            throw new Error('can\'t retrieve trademarks')
        }

        return response.content.data.marks;
    },
};

export default trademarkRecords;
