<template>
    <h2 class="notification-title text-info pb-1" style="font-size: 12px;position: sticky;top: 0;background-color: white;">{{goodClass.name}}</h2>

    <div
        v-if="showAlert && goodsNumberExceed"
        class="alert alert-warning fade in d-flex"
    >
        <div style="text-align: center">
            <strong>
                {{ trans('message.goods.limit.reach', {numberOfItems: this.country.maxGoodNumberPerClass, classNumber: goodClassWithNumberExceed}) }}
            </strong>
            {{ trans('message.goods.limit.canNotAdd', {classNumber: goodClassWithNumberExceed}) }}
        </div>
        <div
            class="m-2 font-sixteen"
            @click="showAlert = !showAlert">×</div>
    </div>

    <div :style="goodBoxHeight">
        <good-display
            v-for="good in suggestions"
            :key="good.externalId"
            :good="good"
            ref="goodTag"
            @goodClick="onGoodClick"
        />
    </div>
    <!-- show more goods in specific class -->
    <a class="font-eleven primary" @click="showAll = !showAll">
        {{ showActionWord }}
        <i
            class="fa"
            :class="showAll ? 'fa-angle-up' : 'fa-angle-down'"
            aria-hidden="true"
        ></i>
    </a>
    <hr size="1"/>
</template>

<script lang="ts">
import GoodClass from '@/core/model/good-class';
import { ComputedOptions, defineComponent } from 'vue';
import GoodDisplay from '@/components/goods-view/good-display.vue';
import { mapActions, mapGetters } from 'vuex';
import Good from '@/core/model/good';
import useCountry from '@/composables/countryDependable';
import useInternalization from '@/composables/internalizable';

export default defineComponent({
    name: 'GoodClassDisplay',
    components: {
        GoodDisplay
    },
    props: {
        goodClass: {
            type: GoodClass,
            required: true
        }
    },
    setup() {
        const country = useCountry();

        return { country, ...useInternalization(country)};
    },
    data() {
        return {
            showAll: false,
            goodHeight: 20,
            showAlert: false,
            goodsNumberExceed: false,
            goodClassWithNumberExceed: 0
        }
    },
    mounted() {
        const goodDisplay = this.$refs['goodTag'] as ComputedOptions[];
        if (goodDisplay) {
            // goodTag might undefined when there is no search result
            const goodTag = goodDisplay[0].$el as unknown as HTMLDivElement;
            this.goodHeight = goodTag.clientHeight;
        }
    },
    computed: {
        ...mapGetters('good', ['goods', 'goodsInClass']),
        showActionWord(): string {
            if (this.showAll) {
                return this.trans('message.goods.showLess');
            }

            return this.trans('message.goods.showMore');
        },
        suggestions(): Good[] {
            return this.goodClass.suggestions.filter(good => {
                const index = this.goods.findIndex((item: Good) => item.externalId === good.externalId);
                return index === -1
            });
        },
        goodBoxHeight(): {maxHeight?: string; overflow?: string} {
            if (!this.showAll) {
                return {
                    maxHeight: (this.goodHeight * 3) + 'px',
                    overflow: 'hidden'
                };
            }

            return {};
        },
    },
    methods: {
        ...mapActions('good', ['addGood']),
        onGoodClick(good: Good) {
            this.goodsNumberExceed = this.goodsInClass(good.classNumber).length >= this.country.maxGoodNumberPerClass;
            if (this.goodsNumberExceed) {
                this.showAlert = true;
                this.goodClassWithNumberExceed = good.classNumber;
                return;
            }

            this.addGood({good: good, countryCode: this.country.displayCode.toLowerCase()});
        }
    }
});
</script>
