export interface ApplicationCheckoutClasses {
    classNumber: number;
    fee: number;
}

export interface ApplicationCheckoutLike {
    classes: ApplicationCheckoutClasses[];
    flatFee: number;
    extraClassFees: number;
    ref: string;
    subTotal: number;
    tax: number;
    total: number;
}

export default class ApplicationCheckout {
    private _classes: ApplicationCheckoutClasses[];
    private _flatFee: number;
    private _extraClassFees: number;
    private _ref: string;
    private _subTotal: number;
    private _tax: number;
    private _total: number;

    constructor(classes: ApplicationCheckoutClasses[], flatFee: number, extraClassFees: number, ref: string, subTotal: number, tax: number, total: number) {
        this._classes = classes;
        this._flatFee = flatFee;
        this._extraClassFees = extraClassFees;
        this._ref = ref;
        this._subTotal = subTotal;
        this._tax = tax;
        this._total = total;
    }

    get classes() {
        return this._classes;
    }

    get flatFee() {
        return this._flatFee;
    }

    get extraClassFees() {
        return this._extraClassFees;
    }

    get subTotal() {
        return this._subTotal;
    }

    get tax() {
        return this._tax;
    }

    get total() {
        return this._total;
    }

    getApplicationCheckoutLike(): ApplicationCheckoutLike {
        return {
            classes: this._classes,
            flatFee: this._flatFee,
            extraClassFees: this._extraClassFees,
            ref: this._ref,
            subTotal: this._subTotal,
            tax: this._tax,
            total: this._total,
        };
    }

    static hydrateApplicationCheckout(applicationCheckoutData: ApplicationCheckoutLike): ApplicationCheckout {
        const applicationCheckout = new ApplicationCheckout(
            applicationCheckoutData.classes,
            applicationCheckoutData.flatFee,
            applicationCheckoutData.extraClassFees,
            applicationCheckoutData.ref,
            applicationCheckoutData.subTotal,
            applicationCheckoutData.tax,
            applicationCheckoutData.total
        );
        return applicationCheckout;
    }
}
