import Good from './good';

export default class GoodClass {
    private _name: string;
    private _id: number;
    private _classNumber: number;
    private _suggestions: Array<Good>;
    private _matches: Array<Good>;

    constructor(id: number, classNumber: number, name: string) {
        this._id = id;
        this._classNumber = classNumber;
        this._name = name;
        this._suggestions = [];
        this._matches = [];
    }

    get name() {
        return this._name;
    }

    set name(name: string) {
        this._name = name;
    }

    get id() {
        return this._id;
    }

    set id(id: number) {
        this._id = id;
    }

    get classNumber() {
        return this._classNumber;
    }

    set classNumber(classNumber: number) {
        this._classNumber = classNumber;
    }

    set addSuggestion(suggestion: Good) {
        this._suggestions.push(suggestion);
    }

    get suggestions() {
        return this._suggestions;
    }

    set addMatches(suggestion: Good) {
        this._matches.push(suggestion);
    }

    get matches() {
        return this._matches;
    }
}
