//todo replace image text by function
// const todo: MessageFunction = (ctx: any) => {
//     ctx.list.forEach((format, index) => {

//     }) ;

//     return '';
// };

export default {
    message: {
        error: {
            default: 'Sorry, an error has occurred: Please refresh this page, or contact us by email.',
            short: 'Sorry, an error has occurred:'
        },
        mark: {
            yourTrademark: 'Your Trademark',
            info: {
                general: 'Add your trademark below (it can be a word, words or a logo image).',
                logo: 'Logos <b>must</b> be uploaded as TIFF, PNG, JPEG, JPG, or GIF files with a maximum size of 4MB.'
            },
            choice: {
                word: 'Trademark is a word or words',
                logo: 'Trademark is an image / logo'
            },
            form: {
                label: {
                    trademark: 'Trademark',
                },
                info: {
                    trademark: 'Trademark words are capitalized for maximum protection.  Override protection',
                    here: 'here'
                }
            },
            error: {
                image: 'Error: Image exceeded maximum size of {size} MB',
            },
            alert: {
                title: 'Please Fix The Following',
                word: 'Please provide the trademark words.',
                image: 'Please provide the trademark image.',
                imageUpload: 'Image failed to upload!',
            }
        },
        goods: {
            alert: 'Please add your goods and services for this application',
            info: {
                whatFor: 'What will you use your trademark for? Eg: "shoes" or "accounting".',
                search: 'Search then select the items you want to protect your trademark for.'
            },
            limit: {
                reach: 'You have selected {numberOfItems} items in class {classNumber}.',
                canNotAdd: 'You can not add more items from class {classNumber}.'
            },
            search: {
                search: 'Search',
                searchAgain: 'Search again',
                resultInfo: 'Results for "{search}" found in {nbClassInResult} classes. Scroll to view all',
                lessClass: 'Less Classes',
                moreClass: 'More Classes',
                nbClass: '{number} Classes',
                nbItems: '{number} items',
                firstItemSelected: {
                    great: 'Great! You have selected your first item in class {number}.',
                    selectAgain: 'You can add other items from the same class without incurring further costs.',
                }
            },
            showMore: 'Show More',
            showLess: 'Show less',
        },
        contact: {
            form: {
                ownerType: {
                    label: 'The Owner of the trademark is a:'
                },
                ownerName: {
                    label: 'Trademark Owner'
                },
                ownerCountry: {
                    label: 'Country'
                },
                companyNumber: {
                    label: 'ABN/ACN (if applicable)',
                    invalid: 'ABN/ACN is invalid'
                },
                ownerEmail: {
                    label: 'Email'
                },
                ownerPhone: {
                    label: 'Mobile number'
                },
                addressAutoComplete: {
                    label: 'Address',
                    placeholder: ''
                },
                address: {
                    label: 'Address'
                },
                suburb: {
                    label: 'Suburb'
                },
                region: {
                    label: 'State / Region'
                },
                city: {
                    label: 'City'
                },
                postcode: {
                    label: 'Postcode',
                    invalid: 'Postcode is invalid'
                },
                declaration: {
                    title: 'I confirm that',
                }
            },
            alert: {
                title: 'Please Fix The Following',
            }
        },
        payment: {
            paymentTitle: 'Payment method',
            paymentCaption: 'Secure and encrypted payment',
            ownerTitle: 'Trademark Owner',
            goodsTitle: 'Goods and Services',
            pay: 'PAY NOW',
            paying: 'Paying',
            payCard: 'Checkout now',
            payPaypal: 'PAY WITH PAYPAL {currency}{amount}',
            loading: 'We are loading the payment data',
            total: 'Total:',
            gst: 'GST (15%)',
            tax: 'Tax:',
            subTotal: 'Sub total:',
            orderTitle: 'Order summary',
            wordMark: '{wordMark} Trademark in {countryCode}',
            imageMark: 'Trademark in {countryCode}',
            classDescription: 'Class {classNumber} / {goods}'
        },
        success: {
            received: 'Payment Received!',
            confirmation: 'Your trademark application has been<br/> received by {name}',
            submitInfo: 'Your {country} trademark application will be submitted to the Trademark Office in one business day.<br/> Please check your emails for our order confirmation and payment receipt/tax invoice.',
            thanks: 'Thanks for using {name}.',
            applyAgain: 'APPLY FOR ANOTHER TRADEMARK',
            applyTitle: 'Apply for another trademark',
            applyQuestion: 'Are the trademark details similar to your last?',
            different: 'No, completely different',
            similar: 'Yes, similar'
        },
        stripe: {
            cardError: 'An error with you card happen',
            paymentError: 'Payment failed to proceed',
            cardHolderName: 'Card holder name',
        },
        stepper: {
            label: {
                mark: 'Add trademark',
                goods: 'Select products',
                contact: 'Owner details',
                payment: 'Confirm and pay',
            }
        },
        price: {
            baseFee: 'Base fee',
            total: 'Total',
        },
        classNumber: 'Class {classNumber}',
        class: 'Class',
        nextStep: 'NEXT',
        previousStep: 'BACK',
        loading: 'loading...',
        yes: 'YES',
        no: 'NO',
        or: 'OR'
    }
};
