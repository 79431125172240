import { MarkOwnerDetailInterface } from '@/core/interface/api-interface';
import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import application, { ApplicationState } from './application';
import good, { GoodState } from './good';
import mark, { MarkState } from './mark';
import owners, { OwnersState } from './owners';
import ownerDetails from './owner-details';
import contact, { ContactState } from './contact';
import authentication from './authentication';
import marksRecords from './marks-records';
import common from './common';
import workflow, { WorkflowState } from './workflow';
import LocalStorageDecorator from '@/store/storage/local-storage-decorator';
import app, { AppStore } from './app';

export interface RootState {
    application: ApplicationState;
    good: GoodState;
    contact: ContactState;
    mark: MarkState;
    owners: OwnersState;
    ownerDetails: MarkOwnerDetailInterface;
    workflow: WorkflowState;
    app: AppStore;
}

export default createStore<RootState>({
    modules: {
        application,
        good,
        mark,
        owners,
        ownerDetails,
        contact,
        common,
        workflow,
        app,
        authentication,
        marksRecords
    },
    plugins: [createPersistedState({
        storage: LocalStorageDecorator
    })],
});
