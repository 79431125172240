<template>
    <div id="cy-result-box" class="card" style="height: 100%;">
        <div class="card-header text-white bg-primary">
            <div class="row">
                <div class="col font-sixteen">
                    <i class="fa fa-shopping-cart" aria-hidden="true"></i> {{ trans('message.goods.search.nbClass', {number: goodClasses.length}) }}
                </div>
                <div class="col font-sixteen">
                    {{ trans('message.goods.search.nbItems', {number: goods.length}) }}
                </div>

                <div
                    v-if="goods.length"
                    class="col"
                    style="width: 160px; min-width: 60px;"
                >
                    <div class="float-right">
                        <tooltip
                            :html="tooltip"
                        >
                            <span class="circle-badge">
                                <i
                                    class="fa fa-info-circle"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    aria-hidden="true">
                                </i>
                            </span>
                            <strong class="selected-total-value ml-1">{{ $filters.formatAmountWithCurrency(goodsPrice.total, this.country.currency) }}</strong>
                        </tooltip>
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="showAlert && onlyOneGood"
            class="alert alert-warning fade in d-flex"
        >
            <div style="text-align: center; margin: auto">
                <strong>{{ trans('message.goods.search.firstItemSelected.great', {number: goodClasses[0]}) }}</strong>
                {{ trans('message.goods.search.firstItemSelected.selectAgain') }}
            </div>
            <div
                class="m-2 font-sixteen"
                @click="showAlert = !showAlert">×
            </div>
        </div>

        <div
            v-if="classAlertSwitch && showClassAlert"
            class="alert alert-warning fade in d-flex"
        >
            <div style="text-align: center; margin: auto">
                <strong>{{ trans('message.goods.search.secondClassSelected.great') }}</strong>
                {{ trans('message.goods.search.secondClassSelected.selectAgain',
                         {currency: this.country.currency}) }}
            </div>
            <div
                class="m-2 font-sixteen"
                @click="classAlertSwitch = !classAlertSwitch">×
            </div>
        </div>

        <div class="card-body" style="overflow-y: auto;">
            <good-class-display
                v-for="classNumber in goodClasses"
                :key="classNumber"
                :class-number="classNumber"
            />
        </div>

    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import GoodClassDisplay from '@/components/goods-view/good-class-specification.vue';
import {ApplicationCheckoutClasses} from '@/core/model/application-checkout';
import Tooltip from '@/components/common/tooltip.vue';
import useCountry from '@/composables/countryDependable';
import useInternalization from '@/composables/internalizable';
import {CountryCode} from '@/config/country/type';

export default defineComponent({
    name: 'GoodSearch',
    components: {
        GoodClassDisplay,
        Tooltip
    },
    setup() {
        const country = useCountry();
        return {country, ...useInternalization(country)};
    },
    data() {
        return {
            showAlert: true,
            classAlertSwitch: true,
            showClassAlert: false,
            selectedGoodsClassNumber: 0
        }
    },
    watch: {
        goods() {
            // now only show class alert when country is UK
            if (this.country.code !== CountryCode.GB || this.goodClasses.length < 2) {
                this.showClassAlert = false;
                this.selectedGoodsClassNumber = this.goodClasses.length;
            } else if (this.selectedGoodsClassNumber + 1 === this.goodClasses.length) {
                this.showClassAlert = true;
                this.selectedGoodsClassNumber = this.goodClasses.length;
            } else {
                this.selectedGoodsClassNumber = this.goodClasses.length;
                this.showClassAlert = false;
            }
        }
    },
    computed: {
        ...mapGetters('good', ['goodClasses', 'goods', 'goodsPrice']),
        ...mapGetters('application', ['appReference']),
        onlyOneGood(): boolean {
            return this.goods.length === 1;
        },
        tooltip(): string {
            if(!this.goodsPrice || !this.goodsPrice.application) {
                return '';
            }
            const tableHtml = "<table style='text-align:left; margin: 3px'> ";

            // the uk price is different from other countries
            // ref: https://sortifytm.atlassian.net/browse/DEV-2040
            if (this.country.code === CountryCode.GB) {
                const firstClassFeeHtml = `<tr>
                        <td>${this.trans('message.price.baseFee')} </td>
                        <td>${this.country.currency}${this.goodsPrice.application.flatFee}</td>
                    </tr>`;
                const extraClassHtml = !this.goodsPrice.application.extraClassFees ? '' : `<tr v-if=${this.goodsPrice.application.extraClassFees !== 0}>
                        <td>${this.trans('message.price.classFee')}</td>
                        <td>${this.country.currency}${this.goodsPrice.application.extraClassFees} </td>
                    </tr> `;
                const totalFeeHtml = `<tr>
                        <td><b>${this.trans('message.price.total')}</b></td>
                        <td><b>${this.country.currency}${this.goodsPrice.application.total}</b></td>
                    </tr>`;
                return tableHtml + firstClassFeeHtml + extraClassHtml + totalFeeHtml + '</table>';
            }

            const flatFeeHtml = `<tr>
                       <td>${this.trans('message.price.baseFee')} </td>
                       <td>${this.country.currency}${this.goodsPrice.application.flatFee}</td>
                    </tr>`;
            let classHtml = '';
            this.goodsPrice.application.classes.forEach((classItem: ApplicationCheckoutClasses) => {
                classHtml += `<tr>
                        <td>${this.trans('message.class')} ${classItem.classNumber} </td>
                        <td>${this.country.currency}${classItem.fee} </td>
                    </tr> `;
            });
            const totalFeeHtml = `<tr>
                        <td><b>${this.trans('message.price.total')}</b></td>
                        <td><b>${this.country.currency}${this.goodsPrice.application.total}</b></td>
                    </tr>`;
            return tableHtml + flatFeeHtml + classHtml + totalFeeHtml + '</table>';
        }
    }
});
</script>
