<template>
    <div class="stf-divider mb-4 mt-2">
        <span class="stf-divider__text">
            {{ text }}
        </span>
    </div>
</template>

<script lang="ts">
import { defineComponent, toRefs } from 'vue';

export default defineComponent({
    name: 'stf-divider',
    props: {
        text: {
            type: String,
            require: true
        }
    },
    setup(props) {
        const { text } = toRefs(props);
        return text
    }
});
</script>

<style lang="scss" scoped>
    @import './divider'
</style>