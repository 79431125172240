<template>
    <component :is="tag" :class="cssClass">
        <slot />
    </component>
</template>

<script lang="ts">
import { ComputedCssClass } from '@/ui/type';
import { defineComponent } from 'vue';
import StfSheet from '@/ui/sheet/stf-sheet.vue';

export default defineComponent({
    components: {
        StfSheet
    },
    props: {
        right: {
            type: Boolean,
            default: false
        },
        left: {
            type: Boolean,
            default: false
        },
        bottomBorder: {
            type: Boolean,
            default: false
        },
        tag: {
            type: String,
            default: 'div'
        },
        full: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        cssClass(): ComputedCssClass {
            return {
                'stf-container': true,
                'stf-container--right': this.right,
                'stf-container--left': this.left,
                'stf-container--bottom-border': this.bottomBorder,
                'stf-container--full': this.full,
            };
        }
    }
});
</script>
