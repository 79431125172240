import useScreen from '@/plugins/screen-plugin/screen';
import { App } from '@vue/runtime-core';
import { Config, Screen } from './type';

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $screen: Screen;
    }
}

export default {
    install: (app: App, options: Config): void => {
        app.config.globalProperties.$screen = useScreen(options);
    }
};
