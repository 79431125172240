import {RootState} from '.';
import {Module} from 'vuex';
import {
    MUT_LOADING
} from './authentication-mutation';


export interface AuthenticationState {
    loading: boolean;
}

const authentication: Module<AuthenticationState, RootState> = {
    namespaced: true,
    state: {
        loading: false,
    },
    getters: {
        loading(state): boolean {
            return state.loading;
        },
    },
    actions: {
        setLoading({ commit }, loading: boolean) {
            commit(MUT_LOADING, loading);
        },
    },
    mutations: {
        [MUT_LOADING](state, loading: boolean) {
            state.loading = loading
        },
    }
};

export default authentication;
