export default {
    message: {
        mark: {
            info: {
                logo: 'Logos <b>must</b> be uploaded as BMP, PNG, JPEG, or JPG files'
            },
            form: {
                label: {
                    image: 'Select BMP, PNG, JPEG, or JPG file',
                    imageExtension: '.bmp,.png,.jpeg,.jpg'
                }
            }
        },
        contact: {
            form: {
                ownerCountry: {
                    label: 'Country',
                    label_person: 'Country of Citizenship'
                },
                companyNumber: {
                    label: 'Company number',
                    invalid: 'Company number is Invalid'
                },
                suburb: {
                    label: 'Address'
                },
                postcode: {
                    label: 'Postal code',
                    invalid: 'Postal code is invalid'
                },
                region: {
                    label: 'City / Region'
                }
            }
        }
    }
};
