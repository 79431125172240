import useDomain from "@/composables/domainDependable";
import {computed} from "vue";
import { useFavicon } from '@vueuse/core'

export default function useDomainFavicon() {
    const domain = useDomain();

    const favicon = computed(() => {
        const shortCode = domain.shortCode.toLowerCase();
        return `${shortCode}-favicon.png`;
    })

    useFavicon(favicon, {
        baseUrl: '/img/',
        rel: 'icon',
    });
}
