<template>
    <div
        class="stf-footer"
        :class="{
            'stf-footer--high' : setFooterHeight(), 
            'stf-footer--width' : setFooterWidth()
        }"
    >
        <stf-sheet
            :color="domain.footerColor"
            class="stf-footer__wrapper"
        >
            <div class="stf-footer__content">
                <img
                    v-if="domain.contactFlagImgUrl"
                    class="stf-footer__flag"
                    data-type="image"
                    :src="domain.contactFlagImgUrl"
                    alt=""
                >
                <slot />
            </div>
        </stf-sheet>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import StfSheet from '@/ui/sheet/stf-sheet.vue';
import useDomain from '@/composables/domainDependable';
export default defineComponent({
    components: {
        StfSheet
    },
    setup() {
        const domain = useDomain();
        const largerFooterForDomainShortCodes = ['TMO-UK', 'TMO-SG'];
        const smallerWidthFooterForDomainShortCodes = [
            'TMO-BX', 
            'TMO-NZ', 
            'TMO-AU', 
            'DIY-NZ', 
            'TMO-HK'
        ]
        return {
            domain, 
            largerFooterForDomainShortCodes, 
            smallerWidthFooterForDomainShortCodes
        };
    },
    methods : {
        setFooterHeight(): boolean {
            return this.largerFooterForDomainShortCodes.includes(this.domain.shortCode);
        },
        setFooterWidth(): boolean {
            return this.smallerWidthFooterForDomainShortCodes.includes(this.domain.shortCode);
        }
    }
});
</script>
