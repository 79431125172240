<template>
    <tmp-group-footer-replica :footer-menu="footerMenu" no-phone></tmp-group-footer-replica>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import TmpGroupFooterReplica from '@/header-footer-replicas/common/footers/tmp-group-footer-replica.vue';

export default defineComponent({
    name: 'DiyNzFooterReplica',
    components: { TmpGroupFooterReplica },
    data() {
        return {
            footerMenu: {
                termsLink: 'https://www.trademarkplanet.com/terms-of-use',
                privacyLink: 'https://www.trademarkplanet.com/privacy-policy',
                contactLink: 'https://www.trademarkplanet.com/uk/contact',
                contactNumber: ''
            }
        }
    }
});

</script>
