import { Module } from 'vuex';
import { RootState } from '.';
import {
    MUT_LOAD_STATE,
    MUT_SET_ADDRESS_INPUT_TYPE,
    MUT_SET_AUTOCOMPLETE,
    MUT_SET_AUTOCOMPLETE_DISPLAYED,
    MUT_RESET
} from '@/store/app-mutations';

export interface AppStore {
    addressInputType: string;
    autoCompleteDisplayed: string;
    autoComplete: string;
}

const app: Module<AppStore, RootState> = {
    namespaced: true,
    state: {
        addressInputType: '',
        autoCompleteDisplayed: '',
        autoComplete: ''
    },
    getters: {
        addressInputType: ({ addressInputType }) => addressInputType,
        autoCompleteDisplayed: ({ autoCompleteDisplayed }) => autoCompleteDisplayed,
        autoComplete: ({ autoComplete }) => autoComplete
    },
    actions: {
        loadState({ commit }, state) {
            commit(MUT_LOAD_STATE, state);
        },
        setAddressInputType({ commit }, value: string) {
            commit(MUT_SET_ADDRESS_INPUT_TYPE, value);
        },
        setAutoCompleteDisplayed({ commit }, value: string) {
            commit(MUT_SET_AUTOCOMPLETE_DISPLAYED, value);
        },
        setAutoComplete({ commit }, value: string) {
            commit(MUT_SET_AUTOCOMPLETE, value);
        },
        reset({ commit }) {
            commit(MUT_RESET);
        }
    },
    mutations: {
        [MUT_LOAD_STATE](state, stateToLoad: AppStore) {
            Object.entries(stateToLoad).forEach(property => {
                const propertyName = property[0];
                state[propertyName as keyof AppStore] = property[1];
            });
        },
        [MUT_SET_ADDRESS_INPUT_TYPE](state, value: string) {
            state.addressInputType = value;
        },
        [MUT_SET_AUTOCOMPLETE_DISPLAYED](state, value: string) {
            state.autoCompleteDisplayed = value;
        },
        [MUT_SET_AUTOCOMPLETE](state, value: string) {
            state.autoComplete = value;
        },
        [MUT_RESET](state) {
            state.addressInputType = '';
            state.autoCompleteDisplayed = '';
            state.autoComplete = '';
        },
    }
};

export default app;
