import { useForm, useValidateForm, useFormValues } from 'vee-validate';
import { FieldValue, FormFields, InitFormValues, FieldConditionType, DisplayCondition } from './type';

export default function useStfForm(validation: any, initialValue: InitFormValues, fields: FormFields) {
    useForm({
        initialValues: initialValue,
        validationSchema: validation,
    });
    
    const valuesForm = useFormValues();

    const validateForm = useValidateForm();

    const isValid = async function() {
        const result = await validateForm();

        return result.valid;
    };

    const getData = function() {
        const values = valuesForm.value;

        for (const [key,] of Object.entries(values)) {
            //if the field is not displayed we don't want to save the previous value as it can be un-relevant.
            const conditions = fields[key]?.condition?.filter(condition => condition.type === FieldConditionType.DISPLAY) as DisplayCondition[] | undefined;
            let isDisplayed = true ;
            if (conditions) {
                conditions.forEach(condition => {
                    isDisplayed = isDisplayed && condition.check(values[condition.on] as FieldValue);
                });
            }

            if((isDisplayed as boolean) === false) {
                values[key] = '';
            }
        }

        return values;
    }

    return {
        valuesForm,
        isValid,
        getData,
    };
}
