<template>
    <div
        class="mb-4 is-flex is-flex-direction-column flex-grow-1"
    >
        <header style="position: relative;">
            <img class="trademark-banner" src="/img/BannerBackground.jpg">
            <div class="et_pb_bottom_inside_divider" />
            <span class="banner-words">MY TRADEMARKS ONLINE</span>
        </header>
        <section>
            <div class="container-fluid">
                <div id="GlobalError" class="row justify-content-center">
                <!-- space for error msg -->
                </div>
                <slot/>
            </div>
        </section>
        <footer class="footer mt-auto mb-4">
            <div class="container has-text-centered mt-6">
                <p>Part of the Trademark Planet group</p>
            </div>
            <div class="is-flex is-flex-wrap-wrap is-justify-content-center is-align-items-center mx-auto mt-3">
                <span v-for="(menuItem, index) in footerMenu.tmoCountries" :key="index">
                    <span v-if="index !== 0">|</span>
                    <external-link :href="menuItem.url" class="mx-2">{{menuItem.name}}</external-link>
                </span>
            </div>
        </footer>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ExternalLink from "@/components/common/external-link.vue";

export default defineComponent({
    name: 'userConsoleLayout',
  components: {ExternalLink},
    data() {
        return {
            footerMenu: {
                tmoCountries: [
                    {
                        name: 'New Zealand',
                        url: 'https://www.diytm.co.nz/'
                    },
                    {
                        name: 'Australia',
                        url: 'https://www.trademarksonline.com.au/'
                    },
                    {
                        name: 'Singapore',
                        url: 'https://www.trademarksonline.com.sg/'
                    },
                    {
                        name: 'Malaysia',
                        url: 'https://www.trademarksmalaysia.my/'
                    },
                    {
                        name: 'Hong Kong',
                        url: 'https://www.trademarksonline.hk/'
                    },
                    {
                        name: 'United Kingdom',
                        url: 'https://www.trademarksonline.co.uk/'
                    },
                    {
                        name: 'Benelux',
                        url: 'https://www.trademarksonline.be/'
                    },
                ],
            }
        }
    }
});
</script>
