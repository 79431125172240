<template>
    <input type="hidden" :name="name"/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useField } from 'vee-validate';

export default defineComponent({
    name: 'StfFormHiddenInput',
    props: {
        name: {
            type: String,
            required: true
        },
        validationOnFly: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const { errorMessage, value } = useField(props.name, undefined, { validateOnValueUpdate: props.validationOnFly });

        return {
            errorMessage,
            value,
        };
    }
});
</script>
