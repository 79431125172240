import drafter from './drafter';
import markImage from './mark-image';
import application from './application';
import payment from './payment';
import authApi from './auth-api';
import trademarkRecords from './user-console/trademark-records';

const api = {
    drafter,
    markImage,
    application,
    payment,
    trademarkRecords,
    authApi
};

export default api;
