<template>
    <stf-container>
        <owner-form :country="country" ref="ownerForm"/>
        <div class="columns m-0">
            <div class="column text-center">
                <stf-button
                    @click="next"
                    color="primary"
                >
                    {{ trans('message.nextStep') }}
                </stf-button>
            </div>
        </div>
    </stf-container>
    <stf-footer>
        <div class="is-full-height is-flex is-justify-content-flex-end is-align-items-center is-flex-grow-1">
            <stf-button
                @click="previous"
                class="mr-1"
                color="primary"
                outlined
            >
                {{ trans('message.previousStep') }}
            </stf-button>
            <stf-button
                id="cy-next"
                @click="next"
                color="primary"
            >
                {{ trans('message.nextStep') }}
            </stf-button>
        </div>
    </stf-footer>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue';
import OwnerForm from '@/components/contact-view/owner-form.vue';
import useCountry from '@/composables/countryDependable';
import useInternalization from '@/composables/internalizable';
import StfContainer from '@/components/ui/stf-container.vue';
import StfButton from '@/ui/button/stf-button.vue';
import useCartReference from '@/composables/cartReferenceable';
import RouteName from '@/router/route-name';
import useWorkflowRouteGuard from '@/composables/workflow-route-guard';
import { useStore } from 'vuex';
import StfFooter from '@/components/stf-footer.vue';

export default defineComponent({
    name: 'ContactView',
    components: {
        OwnerForm,
        StfContainer,
        StfButton,
        StfFooter,
    },
    setup() {
        const country = useCountry();
        const store = useStore();

        useCartReference();

        const ownerForm = ref<typeof OwnerForm>();

        async function isValid() {
            const isValid = await ownerForm.value?.isValid();

            if (isValid) {
                const {
                    ownerName,//eslint-disable-line
                    ownerType,//eslint-disable-line
                    secondOwnerName,//eslint-disable-line
                    ownerCountry,//eslint-disable-line
                    companyNumber,//eslint-disable-line
                    ownerEmail,//eslint-disable-line
                    ownerPhone,//eslint-disable-line
                    addressAutoComplete,//eslint-disable-line
                    declaration,//eslint-disable-line
                    ...address
                } = ownerForm.value?.getData();

                store.dispatch('owners/updateOwnerType', ownerType);
                store.dispatch('owners/updateOwnerName', ownerName);
                store.dispatch('owners/updateSecondOwnerName', secondOwnerName);
                store.dispatch('owners/updateOwnerCountryCode', ownerCountry);
                store.dispatch('ownerDetails/setCountryCode', ownerCountry);
                store.dispatch('owners/updateCompanyNumber', companyNumber);
                store.dispatch('ownerDetails/setEmail', ownerEmail);
                store.dispatch('ownerDetails/setPhone', ownerPhone);
                store.dispatch('ownerDetails/setDeclaration', declaration);

                store.dispatch('ownerDetails/setCity', address.city);
                store.dispatch('ownerDetails/setAddress', address.address);
                store.dispatch('ownerDetails/setRegion', address.region);
                store.dispatch('ownerDetails/setSuburb', address.suburb);
                store.dispatch('ownerDetails/setPostcode', address.postcode);

                return true;
            }

            return false;
        }

        useWorkflowRouteGuard(isValid);

        return {
            isValid,
            ownerForm,
            country,
            ...useInternalization(country)
        };
    },
    methods: {
        previous() {
            this.$router.push({name: RouteName.GOODS})
        },
        async next() {
            const isValid = await this.isValid();

            if(!isValid) {
                return;
            }

            const {
                ownerEmail,
                ownerPhone,
            } = this.ownerForm?.getData();

            this.$gtm.trackEvent({
                event: 'contact',
                email: ownerEmail,
                phone: ownerPhone,
            });

            this.$router.push({name: RouteName.PAYMENT});
        }
    }
});
</script>
