<template>
    <div
        v-click-outside="onClickOutside"
        :class="dropdownCssClass"
    > 
        <fieldset :class="wrapperCssClass">
            <legend
                v-if="label.length > 0 && labelOnTop"
                class="stf-dropdown__legend caption"
                :style="legendWidth"
            >
            </legend>
            <div class="stf-dropdown__input-wrapper" @click="toggle">
                <input 
                    type="hidden" 
                    :name="name"
                    :value="value"
                />
                <input
                    :name=" name + 'Displayed'"
                    :readonly="!autoComplete"
                    :value="valueDisplayed"
                    :placeholder="placeholder"
                    @input="onInputChange"
                    @blur="onBlur"
                    @focus="onFocus"
                    :disabled="disabled"
                    class="stf-dropdown__input body-2"
                />
                <span v-if="!autoComplete" class="stf-dropdown__icon">
                    <stf-icon :type="icon.name" small :color="icon.color"/>
                </span>
            </div>
            <div v-if="open" class="stf-dropdown__list">
                <div v-if="withSearch" class="stf-dropdown__search-wrapper">
                    <span>
                        <stf-icon :type="searchIcon.name" medium :color="icon.color"/>
                    </span>
                    <input
                        v-model="search"
                        type="text"
                        class="stf-dropdown__search-input body-2"
                        placeholder="Search country..."
                    />
                </div>
                <div v-if="loading" class="stf-dropdown__no-option body-2">
                    <stf-icon type="Loading" class="stf-spinner"/>
                </div>
                <template v-else>
                    <div
                        v-for="(value, key) in innerOptions"
                        :key="key"
                        :option="key"
                        class="stf-dropdown__item body-2"
                        @click="onSelect(key)"
                    >
                        {{value}}
                    </div>
                    <div v-if="noOptions" class="stf-dropdown__no-option body-2"> No result found </div>
                </template>
            </div>
        </fieldset>
        <label
            ref="label"
            :for="name"
            v-if="label.length > 0"
            class="stf-dropdown__label caption stf-text-grey"
            :class="labelCssClass"
        >
            {{ label }}
        </label>
        <div v-if="error" class="stf-dropdown__help stf-dropdown__help--error">
            {{ errorMessage }}
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, InputHTMLAttributes, PropType } from 'vue';
import { AutoCompleteData, DropdownOptions } from './type';
import StfIcon from '@/ui/icon/stf-icon.vue';
import ClickOutside from '@/ui/directives/click-outside';
import { ComputedCssClass, CssProperties } from '@/ui/type';
import { useField } from 'vee-validate';

type LabelRef = HTMLLabelElement | undefined;

export default defineComponent({
    emits: ['update:modelValue', 'input', 'change', 'blur'],
    name: 'stf-dropdown',
    components: {
        StfIcon
    },
    props: {
        name: {
            type: String,
            required: true
        },
        label: {
            type: String,
            default: ''
        },
        options: {
            type: Object as PropType<DropdownOptions>,
            default: () => { return {} }
        },
        modelValue: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        withSearch: {
            type: Boolean,
            default: false
        },
        errorMessage: {
            type: String,
            default: undefined
        },
        required: {
            type: Boolean,
            default: false
        },
        autoComplete: {
            type: Boolean,
            default: false
        },
        autoCompleteData: {
            type: Function as PropType<AutoCompleteData>,
            default: () => {
                return () => {
                    return {};
                };
            }
        }
    },
    setup(props) {
        const {value: valueDisplayed} = useField(props.name + 'Displayed');

        valueDisplayed.value = props.options[props.modelValue] ?? '';
        
        return {valueDisplayed};
    },
    data() {
        return {
            innerOptions: this.options,
            open: false,
            value: this.modelValue,
            loading: false,
            autoCompleteWaiting: 0,
            focus: false,
            labelWidth: 0,
            search: ''
        };
    },
    mounted() {
        const label = this.$refs['label'] as LabelRef;

        if (label) {
            this.labelWidth = label.clientWidth;
        }
    },
    computed: {
        legendWidth(): CssProperties {
            return {
                width: `${this.labelWidth}px`
            }
        },
        labelOnTop(): boolean {
            return !!this.value || this.focus;
        },
        placeholder(): string {
            if (this.focus) {
                return '';
            }

            return 'Select ' + this.label;
        },
        icon(): {color: string; name: string} {
            return {
                color: this.disabled ? 'grey-400' : 'grey-700',
                name: this.open ? 'MenuUp' : 'MenuDown'
            }
        },
        searchIcon(): {color: string; name: string} {
            return {
                color: 'grey-700',
                name: 'Magnify'
            }
        },
        error(): boolean {
            return !!this.errorMessage;
        },
        wrapperCssClass(): ComputedCssClass {
            return {
                'stf-dropdown__wrapper': true,
                'stf-dropdown__wrapper--focus': this.focus,
            };
        },
        labelCssClass(): ComputedCssClass {
            return {
                'stf-dropdown__label--required' : this.required,
                'stf-dropdown__label--displayed': this.labelOnTop,
                'stf-dropdown__label--focus': this.focus,
            };
        },
        dropdownCssClass(): ComputedCssClass {
            return {
                'stf-dropdown': true,
                'stf-dropdown--open': this.open, 
                'stf-dropdown--disabled': this.disabled
            };
        },
        noOptions(): boolean {
            return Object.keys(this.innerOptions).length === 0;
        }
    },
    watch: {
        modelValue(newModelValue) {
            this.value = newModelValue;
        },
        value(newValue) {
            if (!newValue) {
                return;
            }

            this.valueDisplayed = this.getDisplayedValue(newValue);
        },
        options(newOptions) {
            this.innerOptions = newOptions;
        },
        label() {
            this.$nextTick(() => {
                const label = this.$refs['label'] as LabelRef;

                if (label) {
                    this.labelWidth = label.clientWidth;
                }
            });
        },
        search(newValue) {
            this.onSearchChange(newValue);
        }
    },
    methods: {
        getDisplayedValue(key = ''): string {
            const value = this.innerOptions[key];
            if (!value) {
                return '';
            }

            return value;
        },
        async onInputChange(inputEvent: InputEvent) {
            const value = (inputEvent.target as InputHTMLAttributes).value as string;
            this.valueDisplayed = value;
            if (!this.autoComplete) {
                return;
            }

            clearTimeout(this.autoCompleteWaiting);
            
            this.autoCompleteWaiting = setTimeout(() => {
                this.setAutocompleteValue('');
                if(!value.trim()) {
                    this.resetDropdown();
                    return;
                }

                this.runAutocomplete(value);
            }, 400);
        },
        resetDropdown() {
            this.setAutocompleteValue('');
        },
        async runAutocomplete(value: string) {
            this.open = true;
            this.loading = true;
            this.innerOptions = await this.autoCompleteData(value);
            this.loading = false;
        },
        onSelect(key: string) {
            this.setAutocompleteValue(key)
            this.close();
        },
        setAutocompleteValue(key: string) {
            this.value = key;
            this.$emit('update:modelValue', key);
            this.$emit('input', key);       
        },
        toggle () {
            if (this.disabled) {
                return;
            }

            this.open = !this.open;
        },
        onClickOutside() {
            this.close();
        },
        close() {
            this.open = false;
            this.search = '';
        },
        onBlur() {
            this.focus = false;
        },
        onFocus() {
            this.focus = true;
        },
        onSearchChange(inputValue :string) {
            this.innerOptions = this.options
            const options = Object.entries(this.innerOptions)
                .filter(([, name]) => name.toLowerCase().includes(inputValue.toLowerCase()))
                .reduce((obj, [code, name]) => {
                    obj[code] = name;
                    return obj;
                }, {} as { [code: string]: string });

            this.innerOptions = options;
        }
    },
    directives: {
        ClickOutside
    }
});
</script>

<style lang="sass">
@import './dropdown'
</style>
