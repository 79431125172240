<template>
    <div class="wizard__step" :class="cssClasses">
        <router-link :to="route">
            <span class="wizard__step-number" />
            <span class="wizard__step-description"> {{ trans('message.stepper.label.' + stepName) }} </span>
        </router-link>
    </div>
</template>

<script lang="ts">
import { defineComponent, toRefs } from 'vue';
import useRootable from '@/composables/routable';
import { mapGetters } from 'vuex';
import useInternalization from '@/composables/internalizable';
import useCountry from '@/composables/countryDependable';

export default defineComponent({
    name: 'Step',
    props: {
        stepName: {
            type: String,
            required: true
        },
        routeName: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const { routeName } = toRefs(props);
        const { route } = useRootable(routeName);

        return {
            route,
            ...useInternalization(useCountry())
        };
    },
    computed: {
        ...mapGetters('workflow', ['isValid']),
        stepIsValid(): boolean {
            return this.isValid(this.stepName);
        },
        cssClasses() {
            if (this.routeName === this.$route.name) {
                return 'active';
            }

            if (this.stepIsValid) {
                return 'validated';
            }

            return '';
        }
    }
});
</script>
