export default {
    formatAmountWithCurrency(value: string | number | null, currency: string): string {
        if (value === null) {
            value = 0;
        }

        if (typeof value === 'string') {
            value = parseFloat(value);
        }

        return currency + value.toLocaleString();
    }
};
