/**
 * All the idea between GoodLike, ClassNameLike is to solve the issue in the persisted store.
 * When we persist the store in the localStorage the lib use JSON.stringify or JSON.stringify
 * doesn't serialize the function so we end up after reloading the store from the storage with half of our object.
 *
 * The solution of using something else than JSON.stringify is not really viable,
 * because we could serialize the function with JSON.stringify by passing a function to transform
 * the function into a string, but we also have to do the same during the deserialization or to do that
 * we need to use eval, and eval is an open door to code injection.
 *
 * The implementation of interface Alike is a temporary patch until we find a better solution or
 * if no better solution are found, we gonna have to avoid using class when they need to be in the store.
 * Or we keep going with interface Alike
*/
export interface GoodLike {
    externalId: string;
    classNumber: number;
    name: string;
}

export default class Good {
    private _externalId: string;
    private _classNumber: number;
    private _name: string;

    constructor(externalId: string, classNumber: number, name: string) {
        this._externalId = externalId;
        this._classNumber = classNumber;
        this._name = name;
    }

    get name() {
        return this._name;
    }

    set name(name: string) {
        this._name = name;
    }

    get externalId() {
        return this._externalId;
    }

    set externalId(externalId: string) {
        this._externalId = externalId;
    }

    get classNumber() {
        return this._classNumber;
    }

    set classNumber(classNumber: number) {
        this._classNumber = classNumber;
    }

    get apiData() {
        return {
            externalId: this._externalId,
            classNumber: this._classNumber,
            name: this._name,
        };
    }

    getGoodLike(): GoodLike {
        return {
            externalId: this._externalId,
            classNumber: this._classNumber,
            name: this._name,
        };
    }

    static hydrateGood(good: GoodLike): Good {
        return new Good(good.externalId, good.classNumber, good.name);
    }
}
