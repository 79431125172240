import userApi from '@/core/api/client/user-api';

export interface AuthenticationInterface {
    email: string;
    password: string;
}

interface ResetPassword {
    email: string;
}

interface Reset {
    plainPassword: string;
}

const authentication = {
    async login(data: AuthenticationInterface) {
        return await userApi.post('login', data);
    },
    async logout() {
        return await userApi.get('logout');
    },
    async resetPassword(data:ResetPassword) {
        return await userApi.post('reset-password', data);
    },
    async resetPasswordReset(data:Reset) {
        return await userApi.post('reset', data);
    }
};

export default authentication;