<template>
    <tmo-group-footer-replica :footer-menu="footerMenu"></tmo-group-footer-replica>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import TmoGroupFooterReplica from '@/header-footer-replicas/common/footers/tmo-group-footer-replica.vue';

export default defineComponent({
    name: 'TmoHkFooterReplica',
    components: { TmoGroupFooterReplica },
    data() {
        return {
            footerMenu: {
                imageUrl: 'https://images.squarespace-cdn.com/content/v1/6479606464ff1518c0aa2c1b/8743d955-e923-4c99-ac90-b294a765ffaf/all+together+red+rescaled+1+%281%29.png?format=280w',
                countryName: 'Hong Kong',
                tmoCountries: [],
                contactUrl: 'https://www.trademarksonline.hk/contact',
                termsUrl: 'https://www.trademarksonline.hk/terms-of-use',
                privacyUrl: 'https://www.trademarksonline.hk/privacy-policy',
            }
        }
    }
});

</script>