<!-- 
    Component using Bulma column
    https://versions.bulma.io/0.9.0/documentation/columns/
-->

<template>
    <div :class="classes">
        <slot></slot>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'StfCcol',
    props: {
        size: {
            type: String,
            default: 'is-flex-grow-1'
        },
        tablet: {
            type: String,
            default: ''
        },
        mobile: {
            type: String,
            default: ''
        },
    },
    computed: {
        classes(): string[] {
            return [
                'column',
                `${this.size}`,
                this.tablet ? `${this.tablet}-tablet` : `${this.size}-tablet`,
                this.mobile ? `${this.mobile}-mobile` : `${this.size}-mobile`,
            ].filter(Boolean);
        }
    },
});
</script>
