import { ApplicationInterface } from '@/core/interface/api-interface';
import { generateUniqeReference, generateUniqId } from '@/core/util/reference-utils';
import { Module } from 'vuex';
import { RootState } from '.';
import {
    MUT_SET_PAYMENT_SESSION_ID,
    MUT_SET_APPLICATION_REF,
    MUT_SET_CART_REF,
    MUT_SET_PAYMENT_TYPE,
    MUT_SET_PAYMENT_REF,
    MUT_RESET
} from './application-mutation';

export interface ApplicationState {
    ref: string;
    cartRef: string;
    paymentType: string;
    paymentRef: string;
    paymentSessionId: string;
}

const application: Module<ApplicationState, RootState> = {
    namespaced: true,
    state: {
        ref: '',
        cartRef: '',
        paymentRef: '',
        paymentType: '',
        paymentSessionId: ''
    },
    getters: {
        getApplicationDataToSubmit: (state, getters, rootState, rootGetters): ApplicationInterface => {
            return {
                applicationRef: state.ref,
                cartRef: state.cartRef,
                mark: rootGetters['mark/getMarkDataToSubmit']
            };
        },
        hasRef: (state): boolean => {
            return state.ref !== '';
        },
        hasCartRef: (state): boolean => {
            return state.cartRef !== '';
        },
        appReference: ({ ref }) => ref,
        paymentType: ({ paymentType }) => paymentType,
        paymentRef: ({ paymentRef }) => paymentRef,
        paymentSessionId: ({ paymentSessionId }) => paymentSessionId
    },
    actions: {
        setPaymentType({ commit }, type: string) {
            commit(MUT_SET_PAYMENT_TYPE, type);
        },
        setPaymentRef({ commit }, ref: string) {
            commit(MUT_SET_PAYMENT_REF, ref);
        },
        setPaymentSessionId({ commit }, id: string) {
            commit(MUT_SET_PAYMENT_SESSION_ID, id);
        },
        generateRef({ getters, commit }, countryCode: string) {
            if (getters.hasRef) {
                return;
            }

            const ref = generateUniqeReference(countryCode);

            commit(MUT_SET_APPLICATION_REF, ref);
        },
        generateCartRef({ getters, commit }) {
            if (getters.hasCartRef) {
                return;
            }

            const ref = generateUniqId();

            commit(MUT_SET_CART_REF, ref);
        },
        loadAppRef({ commit }, ref: string) {
            commit(MUT_SET_APPLICATION_REF, ref);
        },
        loadCartRef({ commit }, ref: string) {
            commit(MUT_SET_CART_REF, ref);
        },
        loadState({ commit }, value: ApplicationState) {
            commit(MUT_SET_APPLICATION_REF, value.ref);
            commit(MUT_SET_CART_REF, value.cartRef);
        },
        reset({ commit }) {
            commit(MUT_RESET);
        }
    },
    mutations: {
        [MUT_RESET](state) {
            state.ref = '';
            state.cartRef = '';
            state.paymentRef = '';
            state.paymentType = '';
            state.paymentSessionId = '';
        },
        [MUT_SET_APPLICATION_REF](state, ref: string) {
            state.ref = ref;
        },
        [MUT_SET_CART_REF](state, cartRef: string) {
            state.cartRef = cartRef;
        },
        [MUT_SET_PAYMENT_TYPE](state, type: string) {
            state.paymentType = type;
        },
        [MUT_SET_PAYMENT_REF](state, paymentRef: string) {
            state.paymentRef = paymentRef;
        },
        [MUT_SET_PAYMENT_SESSION_ID](state, id: string) {
            state.paymentSessionId = id;
        }
    }
};

export default application;
