import { getCountryFromUrl } from '@/core/util/country-utils';

export const getSpecificKeyForCountry = function (key: string, urlPathname: string, version?: string): string {
    if (key === '@@') {
        // It's to by pass the lib assertion on the storage.
        // I don't understant why it doesn't work without this condition.
        // Something related to vuex-persistedstate/src/index.ts L78
        return key;
    }

    const country = getCountryFromUrl(urlPathname);

    if (country !== null) {
        key = key + '_' + country.code.toLowerCase();
    }

    if (version) {
        key = key + '_' + version;
    }

    return key;
};
