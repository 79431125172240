<template>
    <tmo-group-footer-replica :footer-menu="footerMenu"></tmo-group-footer-replica>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import TmoGroupFooterReplica from '@/header-footer-replicas/common/footers/tmo-group-footer-replica.vue';

export default defineComponent({
    name: 'TmoUkFooterReplica',
    components: { TmoGroupFooterReplica },
    data() {
        return {
            footerMenu: {
                imageUrl: 'https://images.squarespace-cdn.com/content/v1/6226ec08a8758f252e060714/cdeda5f5-ed21-46ca-a377-085bf11d25b4/UKTMO%2Bwords%2Band%2Bflag%2Bon%2Bwhite%2B%281%29.jpg?format=750w',
                countryName: 'United Kingdom',
                tmoCountries: [
                    {
                        name: 'United Kingdom',
                        url: 'https://www.trademarksonline.co.uk/'
                    },
                    {
                        name: 'Malaysia',
                        url: 'https://www.trademarksmalaysia.my/'
                    },                    
                    {
                        name: 'Singapore',
                        url: 'https://www.trademarksonline.com.sg/'
                    },                     
                    {
                        name: 'Australia',
                        url: 'https://www.trademarksonline.com.au/'
                    },
                    {
                        name: 'New Zealand',
                        url: 'https://www.diytm.co.nz/'
                    },
                ],
                contactUrl: 'https://www.trademarksonline.co.uk/contact',
                termsUrl: 'https://www.trademarksonline.co.uk/terms-of-use',
                privacyUrl: 'https://www.trademarksonline.co.uk/privacy-policy',
                phone: '01226610952'
            }
        }
    }
});

</script>
