<template>
    <stf-container>
        <div class="columns">
            <div class="column has-text-centered">
                <img src="/img/image-success.svg" alt="Success" /><br/><br/>
                <div class="heading-4">{{ trans('message.success.received') }}</div><br/><br/>
                <span v-html="trans('message.success.submitInfo', {country :country.name})"></span><br/><br/>
                {{ trans('message.success.thanks', {name : siteName}) }}
            </div>
        </div>
    </stf-container>
    <stf-footer>
        <div class="is-full-height is-flex is-justify-content-flex-end is-align-items-center is-flex-grow-1">
            <stf-button
                @click="show = true"
                color="primary"
            >
                {{ trans('message.success.applyAgain') }}
            </stf-button>
        </div>
    </stf-footer>

    <modal v-model="show" >
        <template v-slot:header>
            <h5 class="modal-title mt-0">{{ trans('message.success.applyTitle') }}</h5>
        </template>
        <template v-slot:body>
            <p>
                {{ trans('message.success.applyQuestion') }}
            </p>
        </template>
        <template v-slot:footer>
            <stf-button @click="applyDifferent" >{{ trans('message.success.different') }}</stf-button>
            <stf-button @click="applySame" color="secondary" >{{ trans('message.success.similar') }}</stf-button>
        </template>
    </modal>
</template>

<script lang="ts">
import Modal from '@/components/common/modal.vue';
import useCountry from '@/composables/countryDependable';
import useLoggable from '@/composables/loggable';
import useInternalization from '@/composables/internalizable';
import { MARK } from '@/router/route-name';
import { defineComponent } from 'vue';
import StfButton from '@/ui/button/stf-button.vue';
import StfContainer from '@/components/ui/stf-container.vue';
import useDomain from '@/composables/domainDependable';
import StfFooter from '@/components/stf-footer.vue';

export default defineComponent({
    name: 'SuccessView',
    components: {
        Modal,
        StfButton,
        StfContainer,
        StfFooter
    },
    setup() {
        const country = useCountry();
        const siteName = useDomain().siteName;
        return {
            ...useLoggable(),
            country,
            siteName,
            ...useInternalization(country)
        }
    },
    data () {
        return {
            show: false,
            storeData: null
        };
    },
    mounted() {
        this.storeData = {...this.$store.getters.dataForSimilarApplication};
        const totalPay = this.$store.state.good.goodsPrice.total ?? '0';
        const applicationRef =  this.$store.getters['application/appReference'] ?? '';
        const paymentRef = this.$store.getters['application/paymentRef'] ?? '';

        if (applicationRef && paymentRef) {
            this.info('Payment Success', {
                applicationRef: applicationRef,
                paymentRef: paymentRef
            });

            this.$gtm.trackEvent({
                event: 'purchase',
                transaction_id: applicationRef,
                currency: this.country.currencyName.trim(),
                value: parseFloat(totalPay as string)
            });

            // ref: https://help.ads.microsoft.com/#apex/ads/en/56684/2
            window.uetq.push('event', 'bing_conversion_payment', {
                'event_category': 'payment&applicationRef:' + applicationRef,
                'event_label': this.country.currencyName,
                'event_value': parseFloat(totalPay as string)
            });

            window.uetq.push('event', 'bing_conversion_purchase', {
                'event_category': 'payment_success',
                'event_label': applicationRef,
                'event_value': parseFloat(totalPay as string)
            });
        }

        this.$nextTick(() => {
            this.$store.dispatch('resetApplication');
        });
    },
    methods: {
        applyDifferent() {
            this.info('user apply different trademark', {});
            this.show = false;
            this.$router.push({name: MARK});
        },
        applySame() {
            this.info('user apply same trademark', {});
            this.show = false;
            this.$store.dispatch('loadDataForSimilarApplication', this.storeData);

            this.$router.push({name: MARK});
        }
    }
});
</script>

