<template>
    <stf-dropdown
        v-model="value"
        :name="name"
        :error-message="errorMessage"
        v-bind="$attrs"
        class="stf-form-field"
        :with-search="true"
    />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useField } from 'vee-validate';
import StfDropdown from '@/ui/dropdown/stf-dropdown.vue';

export default defineComponent({
    name: 'StfFormDropdown',
    components: {
        StfDropdown
    },
    props: {
        name: {
            type: String,
            required: true
        },
        rules: {
            default: undefined
        },
        validationOnFly: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const { errorMessage, value } = useField(props.name, props.rules, { validateOnValueUpdate: props.validationOnFly });

        return {
            errorMessage,
            value,
        };
    },
    watch: {
        value(newValue) {
            this.$emit('update:modelValue', newValue);
        }
    }
});
</script>
