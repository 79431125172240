export const MUT_RESET = 'MUT_RESET';
export const MUT_ADD_OWNER = 'MUT_ADD_OWNER';
export const MUT_REMOVE_OWNER = 'MUT_REMOVE_OWNER';
export const MUT_UPDATE_COMPANY_NUMBER = 'MUT_UPDATE_COMPANY_NUMBER';
export const MUT_UPDATE_BUSINESS_NUMBER = 'MUT_UPDATE_BUSINESS_NUMBER';
export const MUT_REPLACE_OWNER = 'MUT_REPLACE_OWNER';
export const MUT_LOAD_STATE = 'MUT_LOAD_STATE';
export const MUT_ADD_OWNER_NAME = 'MUT_ADD_OWNER_NAME';
export const MUT_ADD_SECOND_OWNER_NAME = 'MUT_ADD_SECOND_OWNER_NAME';
export const MUT_ADD_COMPANY_NUMBER = 'MUT_ADD_COMPANY_NUMBER';
export const MUT_ADD_OWNER_COUNTRY_CODE = 'MUT_ADD_OWNER_COUNTRY_CODE';
export const MUT_ADD_OWNER_TYPE = 'MUT_ADD_OWNER_TYPE';
