import { Domain } from '@/config/domain/type';

export const trademarksOnlineAustralia: Domain = {
    domain: import.meta.env.VITE_TMO_AU_DOMAIN,
    shortCode: 'TMO-AU',
    title: 'Apply for your Australian trademark',
    siteName: 'Trademarks Australia',
    description: 'Our online trademark registration platform is available 24/7 and takes just minutes. Apply for your Australian trademark by entering your trademark or logo.',
    headerColor: '#fff',
    footerColor: 'grey-dark',
    mobileMenuColor: '#505050',
    contactFlagImgUrl:'',
    menuItems: [
        {
            label: 'Home',
            url: 'https://www.trademarksonline.com.au',
            child: []
        }, {
            label: 'Need to know',
            url: 'https://www.trademarksonline.com.au/how-to-get-a-trademark',
            child: []
        }, {
            label: 'Pricing',
            url: 'https://www.trademarksonline.com.au/pricing',
            child: []
        }, {
            label: 'Faq',
            url: 'https://www.trademarksonline.com.au/trademark-questions',
            child: []
        }, {
            label: 'Testimonials',
            url: 'https://www.trademarksonline.com.au/testimonials',
            child: []
        }, {
            label: 'About us',
            url: 'https://www.trademarksonline.com.au/about-us',
            child: []
        }, {
            label: 'Blog',
            url: 'https://www.trademarksonline.com.au/blog',
            child: []
        }
    ],
    headerImageUrl: '',
    headerImageTitle: 'Trademarks Online Australia',
    bannerImageUrl: 'https://images.squarespace-cdn.com/content/v1/633e0d57389d517aa3dc8c75/425a780e-d574-451c-914d-a69da2bc2c6e/TMO-banner%2816Dec%292-min+%281%29.jpg',
    termOfUseUrl: 'http://www.trademarksonline.com.au/terms-of-use',
    declarationTitle: 'I confirm that',
    declarationList: [
        'The applicant intends to use, and is entitled to own, the trademark',
        'The TM Office will not let me change the trademark or goods and services after it has been submitted'
    ],
    gtmId: 'GTM-WN9J2SB3',
};
