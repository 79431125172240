<template>
    <stf-dropdown
        v-on="validationListeners"
        v-model="value"
        :name="name"
        auto-complete
        :auto-complete-data="autoComplete"
        :error-message="errorMessage"
        :options="options"
        v-bind="$attrs"
        class="stf-form-field"
    />
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue';
import StfDropdown from '@/ui/dropdown/stf-dropdown.vue';
import { AutoCompleteData, DropdownOptions } from '@/ui/dropdown/type';
import { useField } from 'vee-validate';

export default defineComponent({
    name: 'StfFormAutoComplete',
    props: {
        name: {
            type: String,
            required: true
        },
        autoComplete: {
            type: Function as PropType<AutoCompleteData>,
            required: true
        },
        options: {
            type: Object as PropType<DropdownOptions>,
            default: () => { return {} }
        },
        validationOnFly: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const { errorMessage, value, handleChange } = useField(props.name, undefined, { validateOnValueUpdate: props.validationOnFly });
        
        const validationListeners = computed(() => {
            // If the field is valid or have not been validated yet
            // no validation
            if (!errorMessage.value) {
                return {
                    blur: (e: InputEvent) => handleChange(e, false),
                    change: (e: InputEvent) => handleChange(e, false),
                    input: (e: InputEvent) => handleChange(e, false),
                };
            }

            // Aggressive
            return {
                blur: handleChange,
                change: handleChange,
                input: handleChange,
            };
        });

        return {
            errorMessage,
            value,
            validationListeners
        };
    },
    components: {
        StfDropdown
    }
});
</script>
