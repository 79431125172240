<template>
    <div>
        <external-link class="menu-item heading-4" :class="{'menu-link-selected' : menu.label === 'apply'}"  :href="menu.url">{{ menu.label }}</external-link>
    </div>

</template>

<script lang="ts">
import {defineComponent} from 'vue';
import ExternalLink from "@/components/common/external-link.vue";

export default defineComponent({
    name: 'NavbarMenuItem',
  components: {ExternalLink},
    props: {
        menu: {
            type: Object,
            required: true
        }
    }
});
</script>

<style scoped>

.menu-item {
  color: #3d3d3d;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  text-decoration: none;
  letter-spacing: normal;
  line-height: 1.4em;
  font-size: 17.6px;
  text-align: center;
  margin-left: 28px;
}

.menu-link-selected {
  color: #fff;
  text-decoration: underline;
  background-color: #5fad2a;
}

</style>
