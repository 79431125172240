export const MARK = 'MARK';
export const GOODS = 'GOODS';
export const CONTACT = 'CONTACT';
export const PAYMENT = 'PAYMENT';
export const SUCCESS = 'SUCCESS';
export const NOT_FOUND = 'NOT_FOUND';
export const COMPLETE_CART = 'COMPLETE_CARD';

export const TRADEMARKS = 'TRADEMARKS';
export const TRADEMARKS_DETAILS = 'TRADEMARKS_DETAILS';
export const LOGIN = 'LOGIN';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_RESET = 'RESET_PASSWORD_RESET';

export default {
    MARK,
    GOODS,
    CONTACT,
    PAYMENT,
    NOT_FOUND,
    SUCCESS,
    TRADEMARKS,
    TRADEMARKS_DETAILS,
    LOGIN,
    RESET_PASSWORD,
    RESET_PASSWORD_RESET,
    COMPLETE_CART,
};
