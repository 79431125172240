import * as yup from 'yup';
import { CountryCode } from '@/config/country/type';

export const autoCompleteCountry: string[] = [CountryCode.AU, CountryCode.GB, CountryCode.NZ];

const getOwnerFormValidationSchema = function (countryCode: CountryCode, trans: any) {

    const ownerTypeValidation = yup
        .number()
        .required();

    const ownerNameValidation = yup
        .string()
        .trim()
        .required()
        .label(trans('message.contact.form.ownerName.label'));

    const secondOwnerNameValidation = yup
        .string()
        .trim()
        .label(trans('message.contact.form.ownerName.label'));

    const ownerCountryValidation = yup
        .string()
        .trim()
        .required()
        .label(trans('message.contact.form.ownerCountry.label'));

    const ownerEmailValidation = yup
        .string()
        .email()
        .trim()
        .required()
        .label(trans('message.contact.form.ownerEmail.label'));

    const ownerPhoneValidation = yup
        .string()
        .trim()
        .required()
        .label(trans('message.contact.form.ownerPhone.label'));

    const addressValidation = yup
        .string()
        .trim()
        .required()
        .label(trans('message.contact.form.address.label'));

    const suburbValidation = yup
        .string()
        .trim()
        .when('ownerCountry', (ownerCountry: any, schema: any) => {
            if (
                ownerCountry === CountryCode.SG ||
                ownerCountry === CountryCode.MY ||
                ownerCountry === CountryCode.BE ||
                ownerCountry === CountryCode.NL ||
                ownerCountry === CountryCode.LU ||
                ownerCountry === CountryCode.HK
            ) {
                return schema;
            }

            return schema.required();
        })
        .label(trans('message.contact.form.suburb.label'));

    const regionValidation = yup
        .string()
        .trim()
        .when('ownerCountry', (ownerCountry: any, schema: any) => {
            if (ownerCountry === CountryCode.NZ) {
                return schema;
            }

            return schema.required();
        })
        .label(trans('message.contact.form.region.label'));

    const cityValidation = yup
        .string()
        .trim()
        .when('ownerCountry', (ownerCountry: any, schema: any) => {
            if (ownerCountry === CountryCode.NZ) {
                return schema.required();
            }

            return schema;
        })
        .label(trans('message.contact.form.city.label'));

    const postcodeValidation = yup
        .string()
        .trim()
        .when('ownerCountry', (ownerCountry: any, schema: any) => {
            return (ownerCountry !== CountryCode.HK) ? schema.required() : schema;
        })
        .label(trans('message.contact.form.postcode.label'))

    const declarationValidation = yup
        .mixed()
        .test(
            'declaration',
            'Please click the box above to proceed',
            (value) =>
            countryCode !== CountryCode.MY &&
            countryCode !== CountryCode.BE &&
            countryCode !== CountryCode.GB &&
            countryCode !== CountryCode.HK &&
            countryCode !== CountryCode.AU &&
            countryCode !== CountryCode.SG &&
            countryCode !== CountryCode.NZ ||
            value === true,
        );

    return yup.object({
        ownerType: ownerTypeValidation,
        ownerName: ownerNameValidation,
        secondOwnerName: secondOwnerNameValidation,
        ownerCountry: ownerCountryValidation,
        ownerEmail: ownerEmailValidation,
        ownerPhone: ownerPhoneValidation,
        address: addressValidation,
        suburb: suburbValidation,
        region: regionValidation,
        city: cityValidation,
        postcode: postcodeValidation,
        declaration: declarationValidation
    });
}

export default getOwnerFormValidationSchema;
