<template>
    <div class="mx-6">
        <console-header title="Trademark Records">
            <stf-logout color="primary-console"/>
        </console-header>
        
        <div v-if="loading" class="has-text-centered pt-6">
            <stf-heading-two>Loading data...</stf-heading-two>
            <progress class="progress is-primary" max="100"/>
        </div>

        <marks-records-table 
            v-else 
            :marksRecords="sortedTrademarks"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import StfLogout from '@/components/stf-logout.vue';
import MarksRecordsTable from '@/components/user-console/marks-records-table.vue';
import type { Mark } from '@/core/api/user-console/type';
import ConsoleHeader from '@/components/user-console/console-header.vue';

export default defineComponent({
    name: 'TrademarksRecordsView',
    components: {
        StfLogout,
        MarksRecordsTable,
        ConsoleHeader
    },
    data () {
        return {
            trademarks: [] as Mark[],
            trademarkTitles: [
                'Country',
                'Application Number',
                'Trademark',
                'Status',
                'Trademark Owner',
                'Classes', 
                'Products/Services',
                'Actions'
            ]
        };
    },
    computed: {
        ...mapGetters('authentication', ['loading']),
        ...mapGetters('marksRecords', ['getMarksRecords']),
        
        sortedTrademarks(): Mark[] {
            if(!this.getMarksRecords || this.getMarksRecords.length === 0) return [];
            return this.getMarksRecords.slice().sort((a: any, b: any) => {
                if (a.country.name < b.country.name) return -1;
                if (a.country.name > b.country.name) return 1;
                return a.applicationNumber - b.applicationNumber;
            });
        }
    },
    methods: {
        ...mapActions('authentication', ['setLoading']),
        ...mapActions('marksRecords', ['setMarksRecordsFromApi']),
        async loadMarksRecords() {
            this.setLoading(true);
            try {
                this.setMarksRecordsFromApi();
            }
            finally {
                this.setLoading(false);
            }
        },
    },
    mounted() {
        this.loadMarksRecords();     
    }
});
</script>