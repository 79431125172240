<template>
    <hr class="stf-spacer stf-spacer--dot"/>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'stf-spacer'
});
</script>
