<template>
    <stf-sheet  class="columns is-mobile is-variable is-0 px-4 heading-4" color="normal" fullWidth>
        <div class="column">
            {{ trans('message.payment.total') }}
        </div>
        <div class="column has-text-right">
            {{ $filters.formatAmountWithCurrency(checkout.total, country.currencyWithCountry) }}
        </div>
    </stf-sheet>
</template>

<script lang="ts">
import useCountry from '@/composables/countryDependable';
import useInternalization from '@/composables/internalizable';
import Checkout from '@/core/model/checkout';
import { defineComponent } from 'vue';
import StfSheet from '@/ui/sheet/stf-sheet.vue';

export default defineComponent({
    components: {
        StfSheet
    },
    props: {
        checkout: {
            type: Checkout,
            required: true
        }
    },
    setup() {
        const country = useCountry();
        return {
            country,
            ...useInternalization(country)
        };
    }
});
</script>
