import { ref } from 'vue';
import { useStore } from 'vuex';
import StfFormBuilder from '@/components/form/stf-form-builder.vue';
import WorldCountries from '@/config/world-countries-en';
import { Country, CountryCode } from '@/config/country/type';
import { FieldType, FormFields, InitFormValues, FieldConditionType } from '../type';
import useInternalization from '@/composables/internalizable';
import getOwnerFormValidationSchema from './owner-form-validation';
import { MarkOwnerType } from '@/core/interface/api-interface';

export const autoCompleteCountry: string[] = [CountryCode.AU, CountryCode.GB, CountryCode.NZ];

export const MANUAL_ADDRESS = 'MANUAL';
export const AUTOCOMPLETE_ADDRESS = 'AUTOCOMPLETE';

export default function useOwnerForm(country: Country) {
    const { trans } = useInternalization(country);
    const store = useStore();
    const requirePostCodeCountry: string[] = [
        CountryCode.AU,
        CountryCode.GB,
        CountryCode.NZ,
        CountryCode.SG,
        CountryCode.MY,
        CountryCode.BE
    ];

    const fields: FormFields = {
        ownerType: {
            fieldType: FieldType.RADIO,
            name: 'ownerType',
            options: {
                label: trans('message.contact.form.ownerType.label'),
                required: true,
                radio: [{
                    label: 'Person',
                    value: MarkOwnerType.PERSON
                }, {
                    label: 'Company',
                    value: MarkOwnerType.BUSINESS
                }]
            },
        },
        ownerName: {
            fieldType: FieldType.INPUT,
            name: 'ownerName',
            options: {
                label: trans('message.contact.form.ownerName.label'),
                required: true
            },
        },
        secondOwnerName: {
            fieldType: FieldType.INPUT,
            name: 'secondOwnerName',
            options: {
                required: false
            },
        },
        ownerCountry: {
            fieldType: FieldType.DROPDOWN,
            name: 'ownerCountry',
            options: {
                options: WorldCountries, required: true
            },
            condition: [{
                type: FieldConditionType.LABEL as FieldConditionType.LABEL,
                on: 'ownerType',
                check: (value: any) => {
                    if (country.code === CountryCode.SG && value === MarkOwnerType.PERSON) {
                        return trans('message.contact.form.ownerCountry.label_person');
                    }

                    return trans('message.contact.form.ownerCountry.label');
                }
            }],
        },
        companyNumber: {
            fieldType: FieldType.INPUT,
            name: 'companyNumber',
            options: { label: trans('message.contact.form.companyNumber.label') },
            condition: [{
                type: FieldConditionType.DISPLAY as FieldConditionType.DISPLAY,
                on: 'ownerCountry',
                check: (value: any) => {
                    return (value === CountryCode.AU && country.code === CountryCode.AU) ||
                        (value === CountryCode.SG && country.code === CountryCode.SG) ||
                        (value === CountryCode.MY && country.code === CountryCode.MY) ||
                        (value === CountryCode.BE && country.displayCode === CountryCode.BX) ||
                        (value === CountryCode.NL && country.displayCode === CountryCode.BX) ||
                        (value === CountryCode.LU && country.displayCode === CountryCode.BX) ||
                        (value === CountryCode.GB && country.code === CountryCode.GB) ||
                        (value === CountryCode.HK && country.code === CountryCode.HK);
                }
            }, {
                type: FieldConditionType.DISPLAY as FieldConditionType.DISPLAY,
                on: 'ownerType',
                check: (value: any) => {
                    return value === MarkOwnerType.BUSINESS;
                }
            }]
        },
        address: {
            fieldType: FieldType.INPUT,
            name: 'address',
            options: { label: trans('message.contact.form.address.label'), required: true },
        },
        suburb: {
            fieldType: FieldType.INPUT,
            name: 'suburb',
            options: { label: trans('message.contact.form.suburb.label'), required: true },
        },
        city: {
            fieldType: FieldType.INPUT,
            name: 'city',
            options: { label: trans('message.contact.form.city.label'), required: true },
            condition: [{
                type: FieldConditionType.DISPLAY as FieldConditionType.DISPLAY,
                on: 'ownerCountry',
                check: (value: any) => {
                    return value === CountryCode.NZ;
                }
            }]
        },
        region: {
            fieldType: FieldType.INPUT,
            name: 'region',
            options: { label: trans('message.contact.form.region.label'), required: true },
            condition: [{
                type: FieldConditionType.DISPLAY as FieldConditionType.DISPLAY,
                on: 'ownerCountry',
                check: (value: any) => {
                    return value !== CountryCode.NZ;
                }
            }]
        },
        postcode: {
            fieldType: FieldType.INPUT,
            name: 'postcode',
            options: {
                label: trans('message.contact.form.postcode.label'),
                required: requirePostCodeCountry.includes(country.code) ?? true},
        },
        ownerEmail: {
            fieldType: FieldType.INPUT,
            name: 'ownerEmail',
            options: { label: trans('message.contact.form.ownerEmail.label'), required: true },
        },
        ownerPhone: {
            fieldType: FieldType.INPUT,
            name: 'ownerPhone',
            options: { label: trans('message.contact.form.ownerPhone.label'), required: true },
        },
        declaration: {
            fieldType: FieldType.DECLARATION,
            name: 'declaration',
            condition: [{
                type: FieldConditionType.DISPLAY as FieldConditionType.DISPLAY,
                on: 'ownerType',
                check: () => {
                    return (
                        country.displayCode === CountryCode.MY ||
                        country.displayCode === CountryCode.BX ||
                        country.displayCode === CountryCode.UK ||
                        country.displayCode === CountryCode.HK ||
                        country.displayCode === CountryCode.AU ||
                        country.displayCode === CountryCode.SG ||
                        country.displayCode === CountryCode.NZ
                    );
                }
            }]
        }
    };

    const ownerCountry = store.getters['owners/ownerCountryCode'] ? store.getters['owners/ownerCountryCode'] : country.code;
    const ownerType = store.getters['owners/ownerType'] ? store.getters['owners/ownerType'] : MarkOwnerType.PERSON;
    const initialValue: InitFormValues = {
        ownerType: ownerType,
        ownerName: store.getters['owners/ownerName'],
        secondOwnerName: store.getters['owners/secondOwnerName'],
        ownerCountry: country.autoSelectedInForm ? ownerCountry : undefined,
        companyNumber: store.getters['owners/companyNumber'],
        address: store.getters['ownerDetails/address'],
        suburb: store.getters['ownerDetails/suburb'],
        region: store.getters['ownerDetails/region'],
        city: store.getters['ownerDetails/city'],
        postcode: store.getters['ownerDetails/postCode'],
        ownerEmail: store.getters['ownerDetails/email'],
        ownerPhone: store.getters['ownerDetails/phone'],
        declaration: store.getters['ownerDetails/declaration'],
    };

    const validation = getOwnerFormValidationSchema(country.code, trans);

    const ownerForm = ref<typeof StfFormBuilder>();

    return {
        fields,
        initialValue,
        validation,
        ownerForm
    }
}
