<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-sm-12 col-lg-10 col-xl-8 ml-5">
                <reset-sent 
                    v-if="isEmailSent" 
                    @retry-send-email="retryResetPassword"
                />
                <div v-else>
                    <h2>Reset or Create your password</h2>
                    <form 
                        @submit.prevent="resetPassword"
                        class="mt-5"
                    >
                        <div class="mb-5">
                            <stf-input
                                class="mb-4"
                                v-model="email"
                                name="email" 
                                label="email" 
                                type="email" 
                                required
                            />
                            <span>
                                Enter your email address and we will send you a link to create or reset your password.
                            </span>
                        </div>

                        <div v-if="errorMsg" class="error mb-4">
                            {{ errorMsg }}
                        </div>
            
                        <stf-button
                            type="submit"
                            color="primary-console"
                        >
                            Reset/Create password
                        </stf-button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import StfButton from '@/ui/button/stf-button.vue';
import HttpResponse from '@/core/http-module/http-response';
import HttpError from '@/core/http-module/error/http-error';
import stfInput from '@/ui/input/stf-input.vue';
import authentication from '@/core/authentication/authentication';
import ResetSent from '@/components/reset-password/reset-sent.vue';

export default defineComponent({
    name: 'ResetPassword',
    components: {
        StfButton,
        stfInput,
        ResetSent
    },
    data() {
        return {
            email: '',
            isEmailSent: false,
            errorMsg: ''
        }
    },
    methods: {
        async resetPassword() {
            const response = await authentication.resetPassword(this.email);
            if(
                (response instanceof HttpResponse && response.status !== 200) || 
                response instanceof HttpError
            ) {
                this.errorMsg = 'An error occurred, please try again later.';
                return;
            }
            this.email = '';
            this.isEmailSent = true;
        },
        retryResetPassword() {
            return this.isEmailSent = false;
        }
    }
});
</script>