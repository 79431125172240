<template>
    <tmo-uk-footer-replica v-if="domainShortCode === 'TMO-UK'"/>
    <diy-nz-footer-replica v-if="domainShortCode === 'DIY-NZ'"/>
    <tmo-au-footer-replica v-if="domainShortCode === 'TMO-AU'"/>
    <tmo-sg-footer-replica v-if="domainShortCode === 'TMO-SG'"/>
    <tmo-my-footer-replica v-if="domainShortCode === 'TMO-MY'"/>
    <tmo-bx-footer-replica v-if="domainShortCode === 'TMO-BX'"/>
    <tmo-nz-footer-replica v-if="domainShortCode === 'TMO-NZ'"/>
    <tmo-hk-footer-replica v-if="domainShortCode === 'TMO-HK'"/>
    <tmp-uk-footer-replica v-if="domainShortCode === 'TMP-UK'"/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TmoUkFooterReplica from '@/header-footer-replicas/footers/tmo-uk-footer/tmo-uk-footer-replica.vue';
import DiyNzFooterReplica from '@/header-footer-replicas/footers/diy-nz-footer/diy-nz-footer-replica.vue';
import TmoAuFooterReplica from '@/header-footer-replicas/footers/tmo-au-footer/tmo-au-footer-replica.vue';
import TmoSgFooterReplica from '@/header-footer-replicas/footers/tmo-sg-footer/tmo-sg-footer-replica.vue';
import TmoMyFooterReplica from '@/header-footer-replicas/footers/tmo-my-footer/tmo-my-footer-replica.vue';
import TmoBxFooterReplica from '@/header-footer-replicas/footers/tmo-bx-footer/tmo-bx-footer-replica.vue';
import TmoNzFooterReplica from '@/header-footer-replicas/footers/tmo-nz-footer/tmo-nz-footer-replica.vue';
import TmoHkFooterReplica from '@/header-footer-replicas/footers/tmo-hk-footer/tmo-hk-footer-replica.vue';
import TmpUkFooterReplica from '@/header-footer-replicas/footers/tmp-uk-footer/tmp-uk-footer-replica.vue';
import useDomain from '@/composables/domainDependable';

export default defineComponent({
    name: 'LayoutFooter',
    components: {
        TmoUkFooterReplica,
        DiyNzFooterReplica,
        TmoAuFooterReplica,
        TmoSgFooterReplica,
        TmoMyFooterReplica,
        TmoBxFooterReplica,
        TmoNzFooterReplica,
        TmoHkFooterReplica,
        TmpUkFooterReplica
    },
    setup() {
        const domain = useDomain();
        window.document.title = domain.title;

        return {domain};
    },
    computed: {
        domainShortCode(): string {
            return this.domain.shortCode
        }
    }
});
</script>
