import { AxiosInstance } from 'axios';
import HttpError from './error/http-error';
import AxiosClient from './axios-client';
import HttpRequestError from './error/http-request-error';
import HttpResponse from './http-response';
import HttpStatusError from './error/http-status-error';

type Parameters = {
    [name: string]: string;
};

type RequestParameters = {
    params?: Parameters;
};

export default class HttpClientAdapter {
    private client: AxiosInstance;

    constructor(baseUrl: string) {
        this.client = AxiosClient.getClient(baseUrl);
    }

    async get(url: string, parameters: RequestParameters = {}) {
        const rep = await this.client.get(url, parameters).catch(error => {
            throw this.createError(error, url, 'GET');
        });

        return new HttpResponse(rep.status, rep.data);
    }

    async post(url: string, data: any, config: any) {
        const rep = await this.client.post(url, data, config).catch(error => {
            throw this.createError(error, url, 'POST');
        });

        return new HttpResponse(rep.status, rep.data);
    }

    private createError(error: any, url: string, method: string) {
        if (error.response) {
            throw new HttpStatusError(url, method, error.response.status, error.response.data);
        } else if (error.request) {
            throw new HttpRequestError(url, method, error.request);
        }

        throw new HttpError(url, method);
    }
}
