export default class HttpError extends Error {
    private _url: string;
    private _httpMethod: string;

    constructor(url: string, httpMethod: string) {
        super(`Error during a ${httpMethod} to ${url}`);

        this._url = url;
        this._httpMethod = httpMethod;
    }

    get url() {
        return this._url;
    }

    get httpMethod() {
        return this._httpMethod;
    }
}
