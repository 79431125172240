import { hongKongWorkflow } from '../workflow';
import { Country, CountryCode } from './type';

export const hongKong: Country = {
    name: 'Hong Kong',
    code: CountryCode.HK,
    displayCode: CountryCode.HK,
    combinedDisplayCode: CountryCode.HK,
    workflow: hongKongWorkflow,
    makeMethod: 'makeHongKong',
    theme: false,
    currency: '$',
    currencyName: 'HKD',
    currencyWithCountry: 'HK$',
    maxImageSize: 20000000, // 20MB
    maxGoodNumberPerClass: 100,
    paymentMethodsImageName: 'image-4-card',
    stripeKey: import.meta.env.VITE_STRIP_PUBLIC_KEY_HK,
    autoSelectedInForm: true,
};
