import portalApiClient from './client/portal-api';
import Checkout from '../model/checkout';
import ApplicationCheckout, { ApplicationCheckoutClasses } from '../model/application-checkout';
import { RequestResponse } from '../http-module/http-type';
import HttpResponse from '../http-module/http-response';

export type APIapplicationCheckout = {
    classes: ApplicationCheckoutClasses[];
    extraClassFees: number;
    flatFee: number;
    ref: string;
    subtotal: number;
    tax: number;
    total: number;
};

export type APICheckout = {
    applications: APIapplicationCheckout[];
    extraClassFees: number;
    ref: string;
    total: string;
};

type capturePaymentAPIRequestData = {
    orderId: string;
}

type checkoutTransactionIdAPIResponseData = {
    id: string;
    type: string;
};

type checkoutTransactionIdData = {
    successUrl: string;
    cancelUrl: string;
}

const payment = {
    async checkoutAmount(countryCode: string, paymentRef: string): Promise<Checkout> {
        const response = await portalApiClient.get(countryCode + '/checkout-amount/' + paymentRef);

        if (!(response instanceof HttpResponse)) {
            return new Checkout();
        }

        const data = response.content.data as APICheckout;
        if (data.applications.length <= 0) {
            throw new Error('An error occur, no application in checkout');
        }

        const applicationApi = data.applications[0];

        const application = new ApplicationCheckout(
            applicationApi.classes,
            applicationApi.flatFee,
            applicationApi.extraClassFees,
            applicationApi.ref,
            applicationApi.subtotal,
            applicationApi.tax,
            applicationApi.total,
        );

        const checkout = new Checkout();
        checkout.init(application, applicationApi.extraClassFees, data.ref, data.total);

        return checkout;
    },

    async checkoutPay(token: string, amount: number, countryCode: string, applicationRef: string): Promise<RequestResponse> {
        return await portalApiClient.post(countryCode + '/checkout-pay', {
            token: token,
            amount: amount,
            applicationRef
        });
    },

    async checkoutTransactionId(payType: string, countryCode: string, paymentRef: string, data: checkoutTransactionIdData ): Promise<checkoutTransactionIdAPIResponseData>  {
        const resp = await portalApiClient.post(countryCode + '/checkout-transaction-id/' + paymentRef + '/' + payType, data);

        if (!(resp instanceof HttpResponse)) {
            return {
                id: '',
                type: payType
            };
        }

        return resp.content.data as checkoutTransactionIdAPIResponseData;
    },

    async capturePaypalTransaction(countryCode: string, data: capturePaymentAPIRequestData): Promise<RequestResponse>  {
        return await portalApiClient.post(countryCode + '/capture-paypal-transaction', data);
    }
};

export default payment;
