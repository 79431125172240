<template>
    <div class="stf-input">
        <fieldset class="stf-input__container" :class="cssContainerModifier" >
            <legend
                v-if="label.length > 0 && labelOnTop"
                class="stf-input__legend caption"
                :style="legendWidth"
            >
            </legend>
            <input
                :name="name"
                :class="cssInputModifier"
                :style="inputStyle"
                :placeholder="placeholder"
                class="stf-input__input"
                v-model="inputValue"
                @input="onInput"
                @change="onChange"
                @blur="onBlur"
                @focus="onFocus"
                @keyup.enter="$emit('action')"
                :disabled="disabled"
                :type="type"
                :required="required"
            />
            <span v-if="error" class="stf-input__error-icon" :class="{'stf-input__error-icon--action' : action}">
                <stf-icon type="CloseCircle" small/>
            </span>
            <stf-button
                v-if="action"
                icon
                iconName="Magnify"
                color="secondary"
                class="stf-input__action"
                :loading="actionLoading"
                @click="$emit('action')"/>
        </fieldset>
        <label
            ref="label"
            :for="name"
            v-if="label.length > 0"
            class="stf-input__label caption stf-text-grey"
            :class="cssLabelModifier"
        >
            {{ label }}
        </label>
        <div v-if="error" class="stf-input__help stf-input__help--error caption">
            {{ errorMessage }}
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, InputHTMLAttributes } from 'vue';
import StfIcon from '@/ui/icon/stf-icon.vue';
import { ComputedCssClass, CssProperties } from '@/ui/type';
import StfButton from '../button/stf-button.vue';

type LabelRef = HTMLLabelElement | undefined;

export default defineComponent({
    emits: ['update:modelValue', 'input', 'blur', 'change', 'action'],
    name: 'stf-input',
    components: {
        StfIcon,
        StfButton
    },
    props: {
        name: {
            type: String,
            required: true
        },
        modelValue: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        actionLoading: {
            type: Boolean,
            default: false
        },
        errorMessage: {
            type: String,
            default: undefined
        },
        required: {
            type: Boolean,
            default: false
        },
        action: {
            type: Boolean,
            default: false
        },
        height: {
            type: Number,
            default: undefined
        },
        type: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            inputValue: this.modelValue,
            focus: false,
            labelWidth: 0
        };
    },
    mounted() {
        const label = this.$refs['label'] as LabelRef;

        if (label) {
            this.labelWidth = label.clientWidth;
        }
    },
    computed: {
        legendWidth(): CssProperties {
            return {
                width: `${this.labelWidth}px`
            }
        },
        labelOnTop(): boolean {
            return !!this.inputValue || this.focus;
        },
        placeholder(): string {
            if (this.focus) {
                return '';
            }

            return this.label;
        },
        cssInputModifier(): ComputedCssClass {
            return {
                'stf-input__input--disabled': this.disabled,
                'stf-input__input--error': this.error,
            }
        },
        cssContainerModifier(): ComputedCssClass {
            return {
                'stf-input__container--disabled': this.disabled,
                'stf-input__container--focus': this.focus,
            }
        },
        cssLabelModifier(): ComputedCssClass {
            return {
                'stf-input__label--displayed': this.labelOnTop,
                'stf-input__label--focus': this.focus,
                'stf-input__label--required': this.required,
            }
        },
        inputStyle(): CssProperties {
            if (this.height) {
                return {
                    height: `${this.height}px`
                }
            }

            return {};
        },
        error(): boolean {
            return !!this.errorMessage;
        }
    },
    watch: {
        modelValue(newValue) {
            this.inputValue = newValue;
        },
        label() {
            this.$nextTick(() => {
                const label = this.$refs['label'] as LabelRef;

                if (label) {
                    this.labelWidth = label.clientWidth;
                }
            });
        }
    },
    methods: {
        onInput(event: InputEvent) {
            this.$emit('update:modelValue', (event.target as InputHTMLAttributes).value);
            this.$emit('input', event);
        },
        onChange(event: InputEvent) {
            this.$emit('change', event);
        },
        onBlur(event: InputEvent) {
            this.$emit('blur', event);
            this.focus = false;
        },
        onFocus(event: InputEvent) {
            this.$emit('blur', event);
            this.focus = true;
        }
    }
});
</script>

<style lang="sass">
@import './input'
</style>
