<template>
    <component :is="layout" :stepper="displayStepper">
        <router-view />
    </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { getCountryFromUrl } from '@/core/util/country-utils';
import { loadCountryTheme, setTheme } from '@/core/style/theme-injection';
import { loadPaypalScriptFile } from '@/core/paypal/paypal-script-injection';
import { Country } from '@/config/country/type';
import NewDesignLayout from '@/layouts/new-design.vue';
import DefaultLayout from '@/layouts/default.vue';
import UserConsoleLayout from '@/layouts/user-console.vue';
import { getSiteNameFromUrl } from './core/util/url-utils';
import useDomain from './composables/domainDependable';
import { Domain } from './config/domain/type';
import useDomainFavicon from "@/composables/domainFaviconable";

const DEFAULT_LAYOUT = 'default';

export default defineComponent({
    name: 'App',
    components: {
        DefaultLayout,
        NewDesignLayout,
        UserConsoleLayout
    },
    data() {
        return {
            country: null as Country | null,
            interval: 0
        }
    },
    setup() {
        const domain = useDomain();
        window.document.title = domain.title;

        setTheme(domain);

        useDomainFavicon();

        return {domain};
    },
    created() {
        this.updateMetaDescription(this.domain);

        let height = 0;
        // check body scrollHeight change
        this.interval = window.setInterval(() => {
            if (height !== document.body.scrollHeight) {
                height = document.body.scrollHeight;
                window.parent.postMessage({command: 'windowsHeight', message: height}, '*');
            }
        }, 500);

        this.country = getCountryFromUrl(window.location.pathname);
        const siteName = getSiteNameFromUrl(window.location.hostname);
        loadCountryTheme(this.country, siteName);
        loadPaypalScriptFile(this.country);
    },
    computed: {
        layout() {
            return (this.$route.meta.layout || DEFAULT_LAYOUT) + '-layout';
        },
        displayStepper() {
            return this.$route.meta.stepper || false;
        }
    },
    onUnmounted() {
        clearInterval(this.interval);
    },
    methods: {
        updateMetaDescription(domain: Domain) {
            const description = window.document.querySelector('meta[name="description"]');

            if (null == description) {
                return;
            }

            description.setAttribute('content', domain.description);
        }
    }
});
</script>
