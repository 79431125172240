import Rollbar from 'rollbar';
import HttpClientAdapter from './http-client-adapter';
import HttpError from './error/http-error';
import HttpStatusError from './error/http-status-error';
import HttpRequestError from './error/http-request-error';

export default class HttpLoggedClientDecorator {
    _httpClient: HttpClientAdapter;
    _logger: Rollbar;

    constructor(httpClient: HttpClientAdapter, logger: Rollbar) {
        this._httpClient = httpClient;
        this._logger = logger;
    }

    async get(url: string) {
        return await this._httpClient.get(url).catch((error: HttpError | Error) => {
            this.logError(error);

            return error;
        });
    }

    async post(url: string, data: any, config: any = {}) {
        return await this._httpClient.post(url, data, config).catch((error: HttpError | Error) => {
            this.logError(error);

            return error;
        });
    }

    private logError(error: HttpError | Error) {
        if (error instanceof HttpStatusError) {
            this.logHttpError(error);
            return;
        }

        if (error instanceof HttpRequestError) {
            this.logHttpRequestError(error);
            return;
        }

        this._logger.critical(error.message, error);
    }

    private logHttpError(error: HttpStatusError) {
        if (error.status < 500) {
            this._logger.warning(error.message, error, error.content);
            return;
        }

        this._logger.critical(error.message, error);
    }

    private logHttpRequestError(error: HttpRequestError) {
        this._logger.critical(error.message, error, error.request);
    }
}
