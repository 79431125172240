import { newZealandWorkflow } from '../workflow';
import { Country, CountryCode } from './type';

export const newZealand: Country = {
    name: 'New Zealand',
    code: CountryCode.NZ,
    displayCode: CountryCode.NZ,
    combinedDisplayCode: CountryCode.NZ,
    workflow: newZealandWorkflow,
    makeMethod: 'makeNewZealand',
    theme: false,
    currency: '$',
    currencyName: 'NZD',
    currencyWithCountry: 'NZ$',
    maxImageSize: 21000000, // 21MB
    maxGoodNumberPerClass: 1000,
    paymentMethodsImageName: 'image-5-card',
    stripeKey: import.meta.env.VITE_STRIP_PUBLIC_KEY_NZ,
    autoSelectedInForm: true,
};
