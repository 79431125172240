import { RouteLocationNormalized } from 'vue-router';
import { Country } from '@/config/country/type';
import { URLifyCountry } from '@/core/util/country-utils';

export const countryPath = function (path: string, countries: Country[]): string {
    const countriesRegex = countries.map(country => {
        return URLifyCountry(country.displayCode);
    }).join('||');

    return `/:country(${countriesRegex})` + path;
};

export const isCountryNavigationAllow = function (to: RouteLocationNormalized, countries: Country[]) : boolean {
    if (to.meta.isCountryRoute) {
        const countryFound = countries.find(country => {
            return URLifyCountry(country.displayCode) === to.params.country;
        });

        if (!countryFound) {
            return false;
        }
    }

    return true;
};
