<template>
    <div
        @click="menuOpen = !menuOpen"
        class="mobile_menu_bar"
    >
        <i class="fa fa-bars"></i>
        <div v-if="menuOpen" class="mobile-menu">
            <div v-for="(menu, index) in menuItems" :key="index">
                <external-link :href="menu.url" class="menu-link" :class="{'menu-link--parent' : menu.child.length > 0}">{{ menu.label }}</external-link>
                <div v-if="menu.child.length > 0" class="sub-menu">
                    <div v-for="(subMenu, index) in menu.child" :key="index">
                        <external-link :href="subMenu.url" class="menu-link">{{ subMenu.label }}</external-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import ExternalLink from "@/components/common/external-link.vue";

export default defineComponent({
    name: 'TmpUkMobileMenu',
  components: {ExternalLink},
    props: {
        menuItems: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            menuOpen: false
        }
    },
    computed: {
    }
});
</script>

<style scoped>
.mobile_menu_bar {
	position: relative;
}

.mobile-menu {
	position: absolute;
	z-index: 10;
	width: 280px;
	padding: 20px 15px;
	background: white;
	box-shadow: 0 0 4px -1px #a0a0a0;
	border-radius: 2px;
	right: 0;
}

.sub-menu {
	padding-left: 15px;
}

.menu-link {
	display: block;
	text-transform: uppercase;
	font-size: 15px;
	height: 40px;
	line-height: 40px;
	color: rgba(102, 102, 102, 0.9) !important;
	letter-spacing: -0.3px;
	font-weight: 500;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	border-bottom: 1px solid rgba(0, 0, 0, 0.03);
	padding: 0 5px;
}

.menu-link--parent {
	font-weight: 700;
	background-color: rgba(0, 0, 0, 0.03);
}
</style>
