<template>
    <div class="columns is-mobile is-variable is-1">
        <div class="column pl-5 py-1 is-9 text-ellipsis stf-text-grey body-2">
            {{ trans('message.payment.classDescription', {
                goods: goodsDescriptions,
                classNumber: classNumber
            })}}
        </div>
        <div class="column is-3 py-1 has-text-right body-2">
            {{ first && classPrice === 0 ? 'incl' : $filters.formatAmountWithCurrency(classPrice, country.currencyWithCountry) }}
        </div>
    </div>
</template>

<script lang="ts">
import Good from '@/core/model/good';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { stringifySpecification } from '@/core/util/specification-utils';
import useCountry from '@/composables/countryDependable';
import useInternalization from '@/composables/internalizable';
import ApplicationCheckout from '@/core/model/application-checkout';


export default defineComponent({
    name: 'ClassInfo',
    props: {
        classNumber: {
            type: Number,
            required: true
        },
        first: {
            type: Boolean,
            default: false
        },
        application: {
            type: ApplicationCheckout,
            required: true
        }
    },
    setup() {
        const country = useCountry();
        return {
            country,
            ...useInternalization(country)
        };
    },
    computed: {
        ...mapGetters('good', ['goodsInClass']),
        classPrice(): number {
            const applicationClass = this.application.classes.find(item => item.classNumber === this.classNumber);
            return applicationClass === undefined ? 0 : applicationClass.fee;
        },
        goodsDescriptions(): string {
            const good = this.goodsInClass(this.classNumber) as Good[];

            return stringifySpecification(good);
        }
    }
});
</script>
