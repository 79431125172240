import Good from '../model/good';

export interface ApplicationInterface {
    applicationRef: string;
    cartRef: string;
    mark: Mark;
}

export interface MarkWordInterface {
    word: string;
}

export interface MarkImageInterface {
    imageRef: string;
}

export interface MarkContactInterface {
    firstName: string;
    lastName: string | null;
    email: string;
    phone: string | null;
}

export const enum MarkOwnerType {
    PERSON = 1,
    BUSINESS = 2
}

export const enum MarkType {
    WORDS = 1,
    IMAGE = 2
}

export interface MarkOwnerInterface {
    markOwnerType: MarkOwnerType;
    firstName: string | null;
    lastName: string | null;
    businessName: string | null;
    citizenCountryCode: string | null;
    businessNumber: string | null;
    companyNumber: string | null;
}

export interface MarkOwnerDetailInterface {
    address: string;
    city: string;
    suburb: string | null;
    postCode: string;
    region: string | null;
    countryCode: string;
    email: string;
    phone: string;
    declaration: boolean;
}

export interface Mark {
    markSource: string | null;
    markWord: MarkWordInterface | null;
    markImage: MarkImageInterface | null;
    markContact: MarkContactInterface;
    markOwners: MarkOwnerInterface[];
    markOwnerDetail: MarkOwnerDetailInterface;
    markGoods: Good[];
}
