import RouteName from '@/router/route-name';

export interface WorkflowStep {
    name: string;
    route: string;
    previousStep: WorkflowStep[];
}

export const mark: WorkflowStep = {
    name: 'mark',
    route: RouteName.MARK,
    previousStep: []
};

export const goods: WorkflowStep = {
    name: 'goods',
    route: RouteName.GOODS,
    previousStep: [mark]
};

export const contact: WorkflowStep = {
    name: 'contact',
    route: RouteName.CONTACT,
    previousStep: [mark, goods]
};

export const payment: WorkflowStep = {
    name: 'payment',
    route: RouteName.PAYMENT,
    previousStep: [mark, goods, contact]
};

export default [
    mark,
    goods,
    contact,
    payment
];
