<!-- 
    Component using Bulma columns
    https://versions.bulma.io/0.9.0/documentation/columns/
-->

<template>
    <div :class="classes">
        <slot></slot>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'StfRow',
    props: {
        isMultiline: {
            type: Boolean,
            default: true
        },
        isCentered: {
            type: Boolean,
            default: false
        },
        isMobile: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        classes(): string[] {
            return [
                'columns',
                this.isMultiline ? 'is-multiline' : '',
                this.isCentered ? 'is-centered' : '',
                this.isMobile ? 'is-mobile' : '',
            ].filter(Boolean);
        }
    },
});
</script>
