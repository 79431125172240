import { App } from '@vue/runtime-core';
import RollBar from 'rollbar';

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $rollbar: RollBar;
    }

    interface App {
        rollbar: RollBar;
    }
}

export default {
    install: (app: App, options: any): void => {
        app.rollbar = new RollBar(options);
        app.config.globalProperties.$rollbar = app.rollbar;
        app.provide('rollbar', app.config.globalProperties.$rollbar);
    }
};
