<template>
    <div class="burger-wrapper" @click="menuOpen = !menuOpen">
        <span :class="{'bar1-open' : menuOpen}" class="bar1" :style="{'background-color': barColorMenu}"></span>
        <span :class="{'bar2-open' : menuOpen}" class="bar2" :style="{'background-color': barColorMenu}"></span>
    </div>
    <div class="dropdown" :class="{ 'dropdown-after' : menuOpen }" :style="{'background-color': backgroundColor}">
        <ul class="nav-list">
            <li v-for="(menu, index) in menuItems" :key="index" class="nav-list-item">
                <external-link :href=menu.url>{{ menu.label }}</external-link>
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import ExternalLink from "@/components/common/external-link.vue";

export default defineComponent({
    name: 'NavbarMobileBurgerMenu',
  components: {ExternalLink},
    data() {
        return {
            menuOpen: false,
        }
    },
    props: {
        menuItems: {
            type: Array,
            required: true
        },
        backgroundColor: {
            type: String,
            default: '#001B69'
        },
        barColor: {
            type: String,
            default: '#fff'
        }
    },
    computed: {
        barColorMenu() {
            if (!this.menuOpen) {
                return this.barColor;
            }
            return '#fff';
        }
    }
});
</script>

<style scoped>
.burger-wrapper {
  display: block;
  padding: 8px 12px;
  margin: 0 auto;
  width: 70px;
  height: 30px;
  float: right;
  z-index: 2;
  cursor: pointer;
}

.bar1, .bar2 {
  position: relative;
  display: block;
  z-index: 2;
  width: 40px;
  height: 2px;
  background-color: #fff;
  margin-bottom: 10px;
  line-height: 5em;
  transition: 0.4s;
}

.bar1-open {
  -webkit-transform: translateX(8px) rotate(130deg);
  -ms-transform: translateX(8px) rotate(130deg);
  transform: translateX(8px) rotate(130deg);
  margin-bottom: unset;
  width: 25px;
  margin-top: 5px;
}

.bar2-open {
  -webkit-transform: translate(8px, -2px) rotate(-130deg);
  -ms-transform: translate(8px, -2px) rotate(130deg);
  transform: translate(8px, -2px) rotate(-130deg);
  margin-bottom: unset;
  width: 25px;
}

.dropdown {
  position: absolute;
  z-index: 1;
  height: 0;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.dropdown-after {
  height: 100vh;
  width: 100vw;
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.4s;
  transition: height 0.2s ease;
}

.nav-list {
  list-style: none;
}

.nav-list-item {
  font-weight: 300;
  font-style: normal;
  text-transform: capitalize;;
  letter-spacing: .05em;
  line-height: 0.7em;
  font-size: 30px;
  text-align: center;
  padding: 20px 0;
}

.nav-list-item a {
  text-decoration: none;
  color: #FFFFFF;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

</style>
