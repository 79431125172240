<template>
    <div
        class="menu-item"
        :class="{'top-menu': hasChildren}"
        @mouseover="hover = true"
        @mouseleave="hover = false"
    >
        <external-link :href="menu.url">{{ menu.label }}</external-link>
        <div v-if="hasChildren && hover" class="sub-menu">
            <tmp-uk-sub-menu-item v-for="(menu, index) in menu.child" :key="index" :menu="menu"/>
        </div>
    </div>
</template>

<script lang="ts">
import TmpUkSubMenuItem from './tmp-uk-sub-menu-item.vue'
import {defineComponent} from 'vue';
import ExternalLink from "@/components/common/external-link.vue";

export default defineComponent({
    name: 'TmpUkMenuItem',
    props: {
        menu: {
            type: Object,
            required: true
        }
    },
    components: {ExternalLink, TmpUkSubMenuItem },
    data() {
        return {
            hover: false
        }
    },
    computed: {
        hasChildren(): boolean {
            return this.menu.child.length > 0;
        }
    }
});
</script>

<style scoped>

.menu-item > a {
	text-transform: uppercase;
	font-size: 15px;
	color: rgba(102, 102, 102, 0.9) !important;
	letter-spacing: -0.3px;
	font-weight: 500;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.menu-item > a:hover {
	opacity: 0.7;
}

.top-menu {
	position: relative;
	padding: 6px 16px 6px 0;
}

.sub-menu {
	position: absolute;
	z-index: 10;
	width: 240px;
	padding: 20px 0;
	background: white;
	box-shadow: 0 0 4px -1px #a0a0a0;
	border-radius: 2px;
    right: 0;
}
</style>
