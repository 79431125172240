import type { Mark } from '@/core/api/user-console/type';
interface Name {
    firstName: string|null;
    lastName: string|null;
    businessName: string|null;
}

interface Description {
    markImage: string;
    markDescription: string;
}

/* Stringify multiple values into one string with unique values */
export const stringifyGroupValues = (
    group: Array<{ [key: string]: any }>,
    groupBy: string
): string => {
    const mapGroupStrings = group.map(obj => obj[groupBy]);
    const uniqueStrings = Array.from(new Set(mapGroupStrings));
    return uniqueStrings.join(', ');
}

/* Stringify an array of first and last names */
export const stringifyNames = (names: Name[]): string => {
    return names.map(personName => {
        return joinName(personName);
    }).join(', ');
}

export const stringifyobjectStringFromArray = (array: any[], itemToJoin: string): string => {
    return array.map(obj => obj[itemToJoin]).join(', ');
};

/* Join last name and first name */
export const joinName = (personName: Name): string => {
    let name = '';
    if(personName.firstName && personName.lastName){
        name = `${personName.firstName} ${personName.lastName}`;
    } else if(personName.firstName){
        name = personName.firstName;
    } else if(personName.lastName){
        name = personName.lastName;
    } else if (personName.businessName) {
        name = personName.businessName;
    }
    return name;
}

/*  Check if the trademark is an image or a word */
export const isTrademarkImage = (trademark: Description | Mark): boolean => {
    return trademark.markImage ? true : false;
}

export const getImageUrl = (imgSrc:string): string => {
    return window.location.origin + imgSrc;
}