<template>
    <svg viewBox="0 0 24 24">
        <path fill="currentColor" d="M7,10L12,15L17,10H7Z" />
    </svg>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'stf-icon-menu-down',
});
</script>
