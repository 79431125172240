<template>
    <div :class="classes" @click="onClick">
        <slot></slot>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'stf-card',
    props: {
        color: {
            type: String,
            default: 'grey50'
        },
        elevation: {
            type: String,
            default: 'flat'
        }
    },
    computed: {
        classes(): string[] {
            return [
                'stf-card',
                `stf-card__${this.color}`,
                `stf-card__${this.elevation}`
            ];
        }
    },
    methods: {
        onClick() {
            this.$emit('click');
        }
    }
});
</script>
