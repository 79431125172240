/**
 * Config file for the country supported by the app.
 */
import { australia } from './australia';
import { newZealand } from './new-zealand';
import { unitedKingdom } from './united-kingdom';
import { singapore } from './singapore';
import { malaysia } from './malaysia';
import { benelux } from './benelux';
import { hongKong } from './hong-kong';

export default [
    australia,
    newZealand,
    unitedKingdom,
    singapore,
    malaysia,
    benelux,
    hongKong
];
