<template>
    <h2 class="notification-title text-info" style="font-size: 12px">
        {{ trans('message.classNumber', {classNumber}) }}
        <i class="fa fa-minus-circle text-dark float-right" aria-hidden="true" @click="removeClass"></i>
    </h2>
    <div>
        <good-display v-for="good in suggestions" :key="good.externalId" :good="good" @goodClick="onGoodClick" selected/>
    </div>
    <hr size="1"/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import GoodDisplay from '@/components/goods-view/good-display.vue';
import Good from '@/core/model/good';
import { mapActions, mapGetters } from 'vuex';
import useCountry from '@/composables/countryDependable';
import useInternalization from '@/composables/internalizable';

export default defineComponent({
    name: 'GoodClassDisplay',
    components: {
        GoodDisplay
    },
    props: {
        classNumber: {
            type: Number,
            required: true
        }
    },
    setup() {
        const country = useCountry();
        return {
            country, 
            ...useInternalization(country)
        };
    },
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters('good', ['goodsInClass']),
        suggestions(): Good[] {
            return this.goodsInClass(this.classNumber);
        }
    },
    methods: {
        ...mapActions('good', ['removeGood', 'removeGoodsInClass']),
        onGoodClick(good: Good) {
            this.removeGood({good: good, countryCode: this.country.displayCode.toLowerCase()});
        },
        removeClass() {
            this.removeGoodsInClass({classNumber: this.classNumber, countryCode: this.country.displayCode.toLowerCase()});
        }
    }
});
</script>
