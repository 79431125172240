<template>
    <div class="app-good">
        <span class="badge app-badge" :class="badgeColor" @click="onClick">
            <span class="pr-1">{{ good.name }}</span>
            <i class="fa" :class="icon" aria-hidden="true"></i>
        </span>
    </div>
</template>

<script lang="ts">
import Good from '@/core/model/good';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'GoodDisplay',
    props: {
        good: {
            type: Good,
            required: true
        },
        selected: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        icon(): string {
            if (this.selected) {
                return 'fa-minus-circle';
            }

            return 'fa-plus-circle';
        },
        badgeColor(): string {
            if (this.selected) {
                return 'badge-success';
            }

            return 'badge-secondary';
        }
    },
    methods: {
        onClick() {
            this.$emit('good-click', this.good);
        }
    }
});
</script>
