<template>
    <stf-header>
        <div>
            <img
                class="stf-header__navbar-image-left"
                loading="eager"
                :src="domain.headerImageUrl"
                alt=""
                :title="domain.headerImageTitle"
                @click="onImageClick(domain.menuItems[0].url)"
            >
        </div>
        <div>
            <div v-if="!$screen.isMobile" class="stf-header__nav-bar-buttons columns is-flex-wrap-wrap is-justify-content-right">
                <navbar-menu-item v-for="(menu, index) in domain.menuItems" :key="index" :menu="menu"/>
            </div>
            <div v-else>
                <navbar-mobile-burger-menu :menuItems="domain.menuItems" :backgroundColor="domain.mobileMenuColor"/>
            </div>
        </div>
    </stf-header>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import StfHeader from '@/header-footer-replicas/headers/stf-header.vue';
import NavbarMenuItem from '../../common/headers/new-navbar-menu-item.vue'
import NavbarMobileBurgerMenu from '../../common/headers/navbar-mobile-burger-menu.vue'
import useDomain from '@/composables/domainDependable';

export default defineComponent({
    name: 'TmoUkHeaderReplica',
    components: { StfHeader, NavbarMenuItem, NavbarMobileBurgerMenu },
    setup() {
        const domain = useDomain();
        return {domain};
    },
    methods: {
        onImageClick(url: string) {
            location.replace(url);
        }
    }
});
</script>
