import axios from 'axios';

export default class AxiosClient {
    static getClient(baseUrl: string) {
        return axios.create({
            baseURL: baseUrl,
            headers: {
                'Content-type': 'application/json'
            }
        });
    }
}

