<template>
    <div v-if="showAlert" class="alert alert-danger mt-4" role="alert">
        {{ trans('message.goods.alert') }}
    </div>

    <info-help :tipsArr="this.tipsArr"/>
    <div class="row select-goods">
        <div class="col-xm-12 col-sm-12 col-lg-6 col-xl-6">
            <good-search />
        </div>
        <div class="col-xm-12 col-sm-12 col-lg-6 col-xl-6  good-selection">
            <good-selection/>
        </div>
    </div>
    <div class="row justify-content-center mt-3">
        <div class="col-auto">
            <stf-button
                @click="clickNextStep"
                color="primary"
            >
                {{ trans('message.nextStep') }}
            </stf-button>
        </div>
    </div>
    <!-- footer -->
    <stf-footer>
        <div class="is-full-height is-flex is-justify-content-flex-end is-align-items-center is-flex-grow-1">
            <stf-button
                @click="onClickPreviousStep"
                class="mr-1"
                color="primary"
                outlined
            >
                {{ trans('message.previousStep') }}
            </stf-button>
            <stf-button
                id="cy-next"
                @click="clickNextStep"
                color="primary"
            >
                {{ trans('message.nextStep') }}
            </stf-button>
        </div>
    </stf-footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import useCountry from '@/composables/countryDependable';
import useAppReference from '@/composables/appReferenceable';
import GoodSearch from '@/components/goods-view/good-search.vue';
import InfoHelp from '@/components/common/info-help.vue';
import GoodSelection from '@/components/goods-view/good-selection.vue';
import RouteName from '@/router/route-name';
import { useStore } from 'vuex';
import useWorkflowRouteGuard from '@/composables/workflow-route-guard';
import useLoggable from '@/composables/loggable';
import useInternalization from '@/composables/internalizable';
import useCartReference from '@/composables/cartReferenceable';
import StfButton from '@/ui/button/stf-button.vue';
import StfFooter from '@/components/stf-footer.vue';

export default defineComponent({
    name: 'GoodsView',
    components: {
        GoodSearch,
        InfoHelp,
        GoodSelection,
        StfButton,
        StfFooter,
    },
    setup() {
        const country = useCountry();
        useAppReference(country.displayCode);
        useCartReference();
        const store = useStore()

        async function isValid() {
            if (store.getters['good/goods'].length === 0) {
                return false;
            }

            return true;
        }

        useWorkflowRouteGuard(isValid);

        return {country, isValid,  ...useLoggable(), ...useInternalization(country)};
    },
    watch: {
        showAlert(value: boolean) {
            if (value) {
                this.fadeAlert();
            }
        }
    },
    data() {
        return {
            showAlert: false
        }
    },
    computed: {
        tipsArr(): Array<string> {
            return [
                this.trans('message.goods.info.whatFor'),
                this.trans('message.goods.info.search')
            ];
        }
    },
    methods: {
        async clickNextStep() {
            if (!await this.isValid()) {
                this.showAlert = true;
                const appElement = document.getElementById('app');
                if (appElement !== null) {
                    appElement.scrollIntoView();
                }
                return;
            }

            this.$router.push({name: RouteName.CONTACT})
        },
        fadeAlert() {
            window.setInterval(() => {
                this.showAlert = false;
            }, 10000)
        },
        onClickPreviousStep() {
            this.$router.push({name: RouteName.MARK})
        }
    }
});
</script>
