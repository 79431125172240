import { Country } from '@/config/country/type';
import {
    loadStripe,
    Stripe,
    StripeElementStyle,
    StripeCardElementOptions,
    StripeCardElement,
    CreateTokenCardData, StripeError,
    TokenResult
} from '@stripe/stripe-js';
import { reactive } from 'vue';
import logger from './logger/logger';

type errorMessage = {
    message: string;
};

type CardDataKeys =
    'name' |
    'address_line1' |
    'address_line2' |
    'address_city' |
    'address_state' |
    'address_zip' |
    'address_country' |
    'currency'
    ;

export class StripePayment {
    private _stripe: Stripe;
    private _style: StripeElementStyle;
    private _card: StripeCardElement | null;
    private _error: errorMessage;
    private _cardData: CreateTokenCardData;

    constructor(stripe: Stripe) {
        this._style = {};
        this._stripe = stripe;
        this._card = null;
        this._error = reactive({ message: '' });
        this._cardData = {};
    }

    setStyle(style: StripeElementStyle): void {
        this._style = style;
    }

    getCard(): StripeCardElement {
        if (this._card === null) {
            this._card = this._stripe.elements().create('card', this.getCardOptions());
        }

        return this._card;
    }

    mountCardElement(HtmlTagId: string): void {

        const card = this.getCard();

        card.mount(HtmlTagId);
        card.on('change', (event) => {
            if (event.error) {
                this._error.message = event.error.message;
            } else {
                this._error.message = '';
            }
        });
    }

    getCardOptions(): StripeCardElementOptions {
        return {
            style: this._style,
            hidePostalCode: true
        };
    }

    setError(error: string): void {
        this._error.message = error;
    }

    get error(): errorMessage {
        return this._error;
    }

    setCardData(key: CardDataKeys, value: string): void {
        this._cardData[key] = value;
    }

    async getPaymentInfo(): Promise<TokenResult> {
        if (this._card === null) {
            throw 'The payment elements are not present on the page';
        }

        return await this._stripe.createToken(this._card, this._cardData);
    }

    async redirectToCheckout(sessionId: string): Promise<{error: StripeError}> {
        return await this._stripe.redirectToCheckout({sessionId: sessionId});
    }
}

class StripeService {
    private static _stripe = null as null | StripePayment;

    static async getPaymentService(country: Country): Promise<false| StripePayment> {
        if (this._stripe !== null) {
            return this._stripe;
        }

        if (!country.stripeKey || country.stripeKey === '') {
           throw new Error(`stripe public key is missing for ${country.name}`);
        }

        const stripe = await loadStripe(country.stripeKey);

        if (stripe === null) {
            const error = new Error('Impossible to set up strip');
            logger.critical(error.message, error);
            return false;
        }

        this._stripe = new StripePayment(stripe);

        return this._stripe;
    }
}

export default StripeService;
