import english from '@/locales/en';
import englishNewZealand from '@/locales/en-NZ';
import englishAustralia from '@/locales/en-AU';
import englishUnitedKingdom from '@/locales/en-GB';
import englishSingapore from '@/locales/en-SG';
import englishMalaysia from '@/locales/en-MY';
import englishBenelux from '@/locales/en-BE';
import englishHongKong from '@/locales/en-HK';

export default {
    'en': english,
    'en_NZ': englishNewZealand,
    'en_AU': englishAustralia,
    'en_GB': englishUnitedKingdom,
    'en_SG': englishSingapore,
    'en_MY': englishMalaysia,
    'en_BE': englishBenelux,
    'en_HK': englishHongKong
};
