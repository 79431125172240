import { Directive } from 'vue';

const clickOutside: Directive<any, () => any> =  {
    created: function (el, binding) {
      // Define Handler and cache it on the element
      function handler(event: any) {
        if (!binding.instance) {
            return;
        }
  
        if (el.contains(event.target)) {
            return
        } 
  
        el.__vueClickOutside__.callback(event);
      }
  
      // add Event Listeners
      el.__vueClickOutside__ = {
        handler: handler,
        callback: binding.value
      }

      const clickHandler = 'ontouchstart' in document.documentElement ? 'touchstart' : 'click';
      document.addEventListener(clickHandler, handler);
    },
  
    updated: function (el, binding) {
      el.__vueClickOutside__.callback = binding.value;
    },
  
    unmounted: function (el) {
      // Remove Event Listeners
      const clickHandler = 'ontouchstart' in document.documentElement ? 'touchstart' : 'click';
      el.__vueClickOutside__ && document.removeEventListener(clickHandler, el.__vueClickOutside__.handler);
      delete el.__vueClickOutside__;
    }
  }

  export default clickOutside;
