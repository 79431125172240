<template>
    <div>
        <external-link class="menu-item" :class="{'menu-link-selected' : menu.label === 'apply'}"  :href="menu.url">{{ menu.label }}</external-link>
    </div>

</template>

<script lang="ts">
import {defineComponent} from 'vue';
import ExternalLink from "@/components/common/external-link.vue";

export default defineComponent({
    name: 'NavbarMenuItem',
  components: {ExternalLink},
    props: {
        menu: {
            type: Object,
            required: true
        }
    }
});
</script>

<style scoped>

.menu-item {
  color: #424242;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
  font-size: 15px;
  text-align: center;
  margin-left: 16px;
  margin-right: 16px;
}

.menu-item:hover {
  color: #5fad2a;
}

.menu-link-selected {
  color: #5fad2a;
}

</style>
