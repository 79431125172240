import countries from '@/config/country';
import { Country } from '@/config/country/type';

export const URLifyCountry = function (url: string): string {
    return url.split(' ').join('-').toLowerCase();
};

export const getCountryFromUrl = function (pathname: string): Country | null {
    const firstPortion = pathname.split('/').find(string => string !== '');

    if (!firstPortion) {
        return null;
    }

    const countryFound = countries.find(country => {
        return URLifyCountry(country.displayCode) === firstPortion;
    });

    if (!countryFound) {
        return null;
    }

    return countryFound;
};

