import { MarkOwnerDetailInterface } from '@/core/interface/api-interface';
import { Module } from 'vuex';
import { RootState } from '.';
import {
    MUT_RESET,
    MUT_SET_CITY,
    MUT_SET_ADDRESS,
    MUT_SET_DECLARATION,
    MUT_SET_SUBURB,
    MUT_SET_POSTCODE,
    MUT_SET_REGION,
    MUT_SET_COUNTRY_CODE,
    MUT_SET_EMAIL,
    MUT_SET_PHONE,
    MUT_LOAD_STATE
} from '@/store/owner-details-mutation';

const ownerDetails: Module<MarkOwnerDetailInterface, RootState> = {
    namespaced: true,
    state: {
        city: '',
        address: '',
        suburb: '',
        postCode: '',
        region: '',
        countryCode: '',
        email: '',
        phone: '',
        declaration: false,
    },
    getters: {
        getMarkOwnerDetailsDataToSubmit(state): MarkOwnerDetailInterface {
            const stateTemp = { ...state };
            stateTemp.region = stateTemp.region === '' ? null : stateTemp.region;
            stateTemp.suburb = stateTemp.suburb === '' ? null : stateTemp.suburb;
            return stateTemp;
        },
        address: ({ address }) => address,
        city: ({ city }) => city,
        suburb: ({ suburb }) => suburb,
        postCode: ({ postCode }) => postCode,
        region: ({ region }) => region,
        countryCode: ({ countryCode }) => countryCode,
        email: ({ email }) => email,
        phone: ({ phone }) => phone,
        declaration: ({ declaration }) => declaration
    },
    actions: {
        loadState({ commit }, state) {
            commit(MUT_LOAD_STATE, state);
        },
        setCity({ commit }, value: string) {
            commit(MUT_SET_CITY, value);
        },
        setAddress({ commit }, value: string) {
            commit(MUT_SET_ADDRESS, value);
        },
        setSuburb({ commit }, value: string) {
            commit(MUT_SET_SUBURB, value);
        },
        setPostcode({ commit }, value: string) {
            commit(MUT_SET_POSTCODE, value);
        },
        setRegion({ commit }, value: string) {
            commit(MUT_SET_REGION, value);
        },
        setCountryCode({ commit }, value: string) {
            commit(MUT_SET_COUNTRY_CODE, value);
        },
        setEmail({ commit }, value: string) {
            commit(MUT_SET_EMAIL, value.trim());
        },
        setPhone({ commit }, value: string) {
            commit(MUT_SET_PHONE, value);
        },
        setDeclaration({ commit }, value: boolean|string) {
            if (typeof value === 'string') {
                value = false;
            }

            commit(MUT_SET_DECLARATION, value);
        },
        reset({ commit }) {
            commit(MUT_RESET);
        }
    },
    mutations: {
        [MUT_LOAD_STATE](state, stateToLoad: MarkOwnerDetailInterface) {
            Object.entries(stateToLoad).forEach(property => {
                const propertyName = property[0] as keyof MarkOwnerDetailInterface;
                let value = property[1];
                if (propertyName === 'city' && value === null) {
                    value = '';
                }

                if (propertyName === 'region' && value === null) {
                    value = '';
                }
                //todo check why it's type never when removing the unknown.
                (state[propertyName] as unknown) = value;
            });
        },
        [MUT_RESET](state) {
            state.address = '';
            state.suburb = '';
            state.postCode = '';
            state.countryCode = '';
            state.email = '';
            state.phone = '';
            state.region = '';
            state.city = '';
            state.declaration = false;
        },
        [MUT_SET_CITY](state, value: string) {
            state.city = value;
        },
        [MUT_SET_ADDRESS](state, value: string) {
            state.address = value;
        },
        [MUT_SET_SUBURB](state, value: string) {
            state.suburb = value;
        },
        [MUT_SET_POSTCODE](state, value: string) {
            state.postCode = value;
        },
        [MUT_SET_REGION](state, value: string) {
            state.region = value;
        },
        [MUT_SET_COUNTRY_CODE](state, value: string) {
            state.countryCode = value;
        },
        [MUT_SET_EMAIL](state, value: string) {
            state.email = value;
        },
        [MUT_SET_PHONE](state, value: string) {
            state.phone = value;
        },
        [MUT_SET_DECLARATION](state, value: boolean) {
            state.declaration = value;
        },
    }
};

export default ownerDetails;
