<template>
    <global-error :message="globalErrorMessage" :list="globalErrorList"/>
    <div :class="{'container': !$screen.isMobile}">
        <div class="columns m-0">
            <stf-sheet class="column is-6 p-0" :color="$screen.isMobile ? 'grey' : 'transparent'" fullWidth>
                <div
                    class="column"
                    :class="{'ligth-bottom-border': $screen.isMobile}"
                >
                    <stf-form-builder validation-on-fly :fields="fields" :validation="validation" :initial-values="initialValues" ref="contactForm"/>
                </div>
                <div
                    class="column"
                    :class="{'ligth-bottom-border': $screen.isMobile}"
                >
                    <div>
                        <div class="columns is-mobile is-variable is-1">
                            <div class="column is-narrow heading-3 stf-text-grey py-1">{{ trans('message.payment.paymentTitle') }}</div>
                            <div class="column has-text-right caption stf-text-grey py-1">
                                <stf-icon class="v-align-middle" type="Lock" small color="grey-700"/>
                                <span class="v-align-middle">{{ trans('message.payment.paymentCaption') }}</span>
                            </div>
                        </div>
                        <div>
                            <stf-button
                                :disabled="globalErrorMessage.length > 0"
                                :loading="loading || creatingApplication"
                                color="secondary"
                                full
                                @click="pay"
                            >
                                <stf-icon class="mr-2 v-align-middle" type="Lock" small color="grey-50"/>
                                {{ trans('message.payment.payCard', {currency: country.currency, amount: totalToPay }) }}
                            </stf-button>
                            <div class="mt-3 has-text-centered" style="width: 100%;">
                                <img style="width: 291px" :src="`/img/${country.paymentMethodsImageName}.png`">
                            </div>
                            <stf-divider 
                                v-show="showDivider()" 
                                :text="trans('message.or')"
                            />
                            <div id="paypal-button-container"></div>
                        </div>
                    </div>
                </div>
            </stf-sheet>
            <div class="column is-6 p-0">
                <stf-sheet class="mt-3 pt-1 pb-3" color="grey" fullWidth>
                    <div
                        class="column"
                    >
                        <template v-if="creatingApplication !== true && checkoutData != null">
                            <order-summary :checkout="checkoutData"/>
                            <total-price :checkout="checkoutData"/>
                        </template>
                        <template v-else-if="creatingApplication === true">
                            <div class="has-text-centered">
                                <stf-icon type="Loading" large class="stf-spinner"/>
                                <div >{{ trans('message.payment.loading') }}</div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="has-text-centered">
                                <div >{{ trans('message.error.default') }}</div>
                            </div>
                        </template>
                    </div>
                </stf-sheet>
            </div>  
        </div>
    </div>
    
    <stf-footer>
        <div class="is-full-height is-flex is-justify-content-flex-end is-align-items-center is-flex-grow-1">
            <stf-button
                @click="onClickPreviousStep"
                class="mr-1"
                color="primary"
                outlined
            >
                {{ trans('message.previousStep') }}
            </stf-button>
            <stf-button
                :disabled="globalErrorMessage.length > 0"
                id="cy-next"
                @click="pay"
                color="primary"
                :loading="loading || creatingApplication"
            >
                {{ trans('message.payment.pay') }}
            </stf-button>
        </div>
    </stf-footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import useCountry from '@/composables/countryDependable';
import useContactForm from '@/components/form/forms/contact-form';
import api from '@/core/api';
import useLoggable from '@/composables/loggable';
import {mapActions, mapGetters} from 'vuex';
import Checkout from '@/core/model/checkout';
import StripeService, { StripePayment } from '@/core/stripe';
import PaypalService from '@/core/paypal/paypal';
import {CONTACT, MARK, SUCCESS} from '@/router/route-name';
import workflowSteps from '@/config/workflow/steps';
import useInternalization from '@/composables/internalizable';
import HttpResponse from '@/core/http-module/http-response';
import HttpStatusError from '@/core/http-module/error/http-status-error';
import StfButton from '@/ui/button/stf-button.vue';
import StfDivider from '@/ui/divider/stf-divider.vue';
import StfFormBuilder from '@/components/form/stf-form-builder.vue';
import StfIcon from '@/ui/icon/stf-icon.vue';
import StfSheet from '@/ui/sheet/stf-sheet.vue';
import OrderSummary from '@/components/payment-view/order-summary.vue';
import {getCancelUrl, getSuccessUrl} from '@/core/util/url-utils';
import useCartReference from '@/composables/cartReferenceable';
import StfFooter from '@/components/stf-footer.vue';
import TotalPrice from '@/components/payment-view/total-price.vue';
import GlobalError from '@/components/global-error.vue';
import { CountryCode } from '@/config/country/type';

declare let paypal: any;

export default defineComponent({
    name: 'PaymentView',
    components: {
        StfButton,
        StfDivider,
        StfFormBuilder,
        StfIcon,
        StfSheet,
        OrderSummary,
        StfFooter,
        TotalPrice,
        GlobalError
    },
    setup() {
        const country = useCountry();
        useCartReference();
        return {
            ...useLoggable(),
            country,
            ...useInternalization(country),
            ...useContactForm()
        };
    },
    data() {
        return {
            checkoutData: null as Checkout | null,
            stripePayment: null as StripePayment | null,
            loading: false,
            creatingApplication: false,
            alerts: [] as string[],
            interval: 0,
            globalErrorMessage: '',
            globalErrorList: [] as string[]
        }
    },
    async mounted() {
        const step = workflowSteps.find(step => {
            return step.route === this.$route.name;
        });

        if (step && !this.$store.getters['workflow/isReachable'](step)) {
            this.$router.push({name: MARK});
            return;
        }

        // paypal might be 'undefined' each time this page mounted
        // this is because the paypal script will run and create paypal object after the script is loaded
        // but we could not know paypal object created first or mounted() first, so setInterval to make sure paypal loaded
        if (this.hasNoPaypal) {
            this.interval = window.setInterval(() => {
                if (typeof paypal !== 'undefined') {
                    this.loadPaypal();
                    clearInterval(this.interval);
                }
            }, 500);
        }
        

        this.creatingApplication = true;
        await this.SetupPayment();
        this.creatingApplication = false;
    },
    onUnmounted() {
        if(this.interval) {
            clearInterval(this.interval);
        }
    },
    computed: {
        ...mapGetters('application', ['appReference', 'paymentRef']),
        totalToPay(): string {
            if (this.checkoutData === null || this.checkoutData.total === null) {
                return '';
            }

            return this.checkoutData.total.toString();
        },
        hasNoPaypal(): boolean {
            return this.country.code !== CountryCode.MY;
        }
    },
    watch: {
        globalErrorMessage(newValue: string) {
            if (newValue.length > 0) {
                document.dispatchEvent(new Event('disablePayment'));
            }
        }
    },
    methods: {
        ...mapActions('application', ['syncApplication', 'setPaymentType', 'setPaymentSessionId', 'setPaymentRef']),
        ...mapActions('contact', ['setContactName', 'setEmail']),
        onClickPreviousStep() {
            this.$router.push({name: CONTACT})
        },
        async SetupPayment() {
            const stripeLoaded = this.loadStrip();
            if (!stripeLoaded) {
                return;
            }

            const isCreated = await this.createApplication();

            if (!isCreated) {
                return;
            }

            await this.loadPaymentAmount();
            this.info('payment loaded', {});
        },
        async loadPaypal() {
            if (paypal && paypal.Buttons) {
                const getPaymentRef = () => {
                    return this.paymentRef;
                }
                const onBeforeCreateOrder = async () => {
                    await this.submitContactForm();
                }
                const onCreateOrder = (sessionId: string) => {
                    this.$store.dispatch('application/setPaymentSessionId', sessionId);
                }
                const onApproveOrder = (payType: string) => {
                    this.$store.dispatch('application/setPaymentType', payType);
                }
                const onRouterChange = () => {
                    return this.$router.push({name: SUCCESS});
                }
                const onError = (err: string) => {
                    // todo should let user know the error but not only throw it
                    throw new Error(err);
                }
                const onInfo = (msg: string) => {
                    this.info(msg, {});
                }
                const onFinish = () => {
                    this.loading = false;
                }
                const style = {
                    layout: 'horizontal', // not show credit payment icon
                    tagline: false, // not show span under icon
                    color: 'blue',
                    height: 45
                }
                const config = {
                    getPaymentRef,
                    onBeforeCreateOrder,
                    onCreateOrder,
                    onApproveOrder,
                    onRouterChange,
                    onError,
                    onInfo,
                    onFinish,
                    style,
                }
                PaypalService.initPaypal(paypal, this.country, config);
            }
        },
        async loadStrip() {
            const stripePayment = await StripeService.getPaymentService(this.country);
            if (stripePayment === false) {
                this.globalErrorMessage = this.trans('message.error.default');
                return false;
            }

            this.stripePayment = stripePayment
            return true;
        },
        async createApplication() {
            const response = await api.application.sync(this.$store.getters['application/getApplicationDataToSubmit'], this.country.displayCode.toLowerCase());

            //application already paid
            if (response instanceof HttpStatusError && response.status === 555) {
                this.$store.dispatch('resetApplication');
                this.$router.push({name: MARK});
                return false;
            }

            //validation data issue
            if (response instanceof HttpStatusError && response.status === 400) {
                response.content.errors?.forEach((errorItem: any) => {
                    if ((errorItem as { detail: string; source: string }).detail) {
                        const apiErrorMessage = (errorItem as { detail: string; source: string }).detail;
                        this.globalErrorMessage = this.trans('message.error.short');
                        this.globalErrorList.push(apiErrorMessage);
                    }
                })

                return false;
            }

            if(!(response instanceof HttpResponse)) {
                this.globalErrorMessage = this.trans('message.error.default');
                return false;
            }
            this.setPaymentRef(response.content.data.paymentRef);

            return true;
        },
        async loadPaymentAmount() {
            this.checkoutData = await api.payment.checkoutAmount(this.country.displayCode.toLowerCase(), this.paymentRef);
        },
        async submitContactForm() {
            if (!this.contactForm) {
                throw new Error('Contact form not found');
            }
            
            const isValid = await this.contactForm.isValid();
            if (!isValid) {
                return false;
            }

            const data = this.contactForm.getData();

            this.setContactName(data.contactName);
            this.setEmail(data.contactEmail);

            return await this.createApplication();
        },
        async pay() {
            if (this.loading) {
                return;
            }
            this.loading = true;

            const formSubmitted = await this.submitContactForm();
            if (!formSubmitted) {
                this.loading = false;
                return;
            }

            this.info('User try to pay', {});

            if (this.stripePayment === null) {
                throw new Error('payment not initialize');
            }

            // When the customer clicks on the button, get a NEW stripe session id each time
            // 1. Checkout Sessions expire after payment succeed.
            // 2. Checkout Sessions expire 24 hours after creation.
            const stripeSession = await api.payment.checkoutTransactionId('card', this.country.displayCode.toLowerCase(), this.paymentRef, {
                successUrl: getSuccessUrl(window.location.host, window.location.protocol, this.country.displayCode),
                cancelUrl: getCancelUrl(window.location.host, window.location.protocol, this.country.displayCode)
            });
            if(!stripeSession.id) {
                this.loading = false;
                this.globalErrorMessage = this.trans('message.error.default');
                this.info('failed to get checkoutTransactionId', {});
                return;
            }

            this.setPaymentSessionId(stripeSession.id);
            this.setPaymentType('card');

            // redirect to stripe Checkout page.
            this.loading = false;
            this.info('Redirect to stripe Checkout page.', {});
            await this.stripePayment.redirectToCheckout(stripeSession.id);
        },
        showDivider() {
            return this.hasNoPaypal;
        }
    }
});
</script>
