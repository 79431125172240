import { computed, inject } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import RollBar from 'rollbar';

export default function useLoggable() {
    const store = useStore();
    const route = useRoute();

    const appRef = computed<string>(() => {
        return store.getters['application/appReference'];
    });

    const rollbar = inject('rollbar') as RollBar;
    const info = (message: string, info: Record<string, unknown>) => {
        rollbar.info(message, {
            ref: appRef.value,
            route: route.name,
            country: route.params.country,
            ...info
        });
    };

    return { appRef, info };
}
