import portalApiClient from './client/portal-api';
import HttpResponse from '../http-module/http-response';

const cart = {
    async load(countryCode: string, cartRef:string) {
        const response = await portalApiClient.get(`/${countryCode}/get-application/${cartRef}`);

        if (!(response instanceof HttpResponse)) {
            throw new Error('can\'t retrieve cart application')
        }

        return response.content.data;
    }
};

export default cart;


