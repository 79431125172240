/**
 * General file for the config, all the config can be imported here.
 */
import countries from '@/config/country';

const config = {
    portalApiUrl: window.location.origin + '/api',
    countries: countries,
};

export default config;
