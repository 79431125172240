import { Domain } from '@/config/domain/type';

export const trademarksOnlineMalaysia: Domain = {
    domain: import.meta.env.VITE_TMO_MY_DOMAIN,
    shortCode: 'TMO-MY',
    title: 'Apply for your Malaysia trademark',
    siteName: 'Trademarks Malaysia',
    description: 'Our online trademark registration platform is available 24/7 and takes just minutes. Apply for your Malaysia trademark by entering your trademark or logo.',
    headerColor: '#010066',
    footerColor: '#010066',
    mobileMenuColor: '#010066',
    contactFlagImgUrl: '',
    menuItems: [
        {
            label: 'Home',
            url: 'https://www.trademarksmalaysia.my/',
            child: []
        }, {
            label: 'Pricing',
            url: 'https://www.trademarksmalaysia.my/pricing',
            child: []
        }, {
            label: 'FAQ',
            url: 'https://www.trademarksmalaysia.my/faq',
            child: []
        }, {
            label: 'About us',
            url: 'https://www.trademarksmalaysia.my/about-us',
            child: []
        }
    ],
    headerImageUrl: 'https://images.squarespace-cdn.com/content/v1/62c0d51c8a6b3718402dff56/344e4b7d-c6fb-4153-98ae-2629d4ab074b/Trademarks+Malaysia+on+blue+with+flag+1+%283%29.png?format=1500w',
    headerImageTitle: 'Trademarks Online Malaysia',
    bannerImageUrl: 'https://images.squarespace-cdn.com/content/62c0d51c8a6b3718402dff56/34580c75-c7cf-44e7-a5fd-5a01ccc11e65/Malaysia+banner+corrected+blue+010066+1+%281%29.png?',
    termOfUseUrl: 'https://www.trademarksmalaysia.my/terms-of-use',
    declarationTitle: 'I confirm and declare that',
    declarationList: [
        'To the best of my knowledge, the information provided above is true and correct',
        'The owner is entitled to make this trademark application'
    ],
    gtmId: 'GTM-TWBXXN2M',
};
