<template>
    <tmo-group-footer-replica :footer-menu="footerMenu"></tmo-group-footer-replica>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import TmoGroupFooterReplica from '@/header-footer-replicas/common/footers/tmo-group-footer-replica.vue';

export default defineComponent({
    name: 'TmoSgFooterReplica',
    components: { TmoGroupFooterReplica },
    data() {
        return {
            footerMenu: {
                imageUrl: 'https://images.squarespace-cdn.com/content/v1/5883fd0b1e5b6c186550bc2e/83832f0a-f526-489c-9960-f902850ab66d/logo-horizontal+%281%29.png?format=500w',
                countryName: 'Australia',
                tmoCountries: [],
                termsUrl: 'https://www.trademarksonline.com.au/terms-of-use',
                privacyUrl: 'https://www.trademarksonline.com.au/privacy-policy',
                contactUrl: 'https://www.trademarksonline.com.au/contact',
                phone: '+61 (02) 8766 0666'
            }
        }
    }
});

</script>
