import { ref } from 'vue';
import { useStore } from 'vuex';
import * as yup from 'yup';
import StfFormBuilder from '@/components/form/stf-form-builder.vue';
import { FieldType, FormFields, InitFormValues } from '../type';

export default function useContactForm() {
    const store = useStore();

    const fields: FormFields = {
        contactName: {
            fieldType: FieldType.INPUT,
            name: 'contactName',
            options: {label: 'Contact name'},
        },
        contactEmail: {
            fieldType: FieldType.INPUT,
            name: 'contactEmail',
            options: {label: 'Contact email'},
        }
    };

    const validation = yup.object({
        contactName: yup.string().label('Contact name'),
        contactEmail: yup.string().trim().email().label('Contact email'),
    });

    const initialValues: InitFormValues = {
        contactName: store.getters['contact/getName'],
        contactEmail: store.getters['contact/getEmail']
    };

    const contactForm = ref<typeof StfFormBuilder>();

    return {
        fields,
        validation,
        initialValues,
        contactForm
    }
}
