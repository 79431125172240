import HttpError from './http-error';

export default class HttpRequestError extends HttpError {
    private _request: XMLHttpRequest;

    constructor(url: string, httpMethod: string, request: XMLHttpRequest) {
        super(url, httpMethod);
        this._request = request;
    }

    get request() {
        return this._request;
    }

    get message() {
        return `Bad request made to ${this.url} with ${this.httpMethod}`;
    }
}
