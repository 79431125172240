<template>
    <span>{{ this.value }}</span>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'stf-icon-badge-number',
    props: {
        value: {
            type: String,
            required: true
        }
    }
});
</script>
