<template>
    <svg viewBox="0 0 24 24">
        <path fill="currentColor" d="M7,15L12,10L17,15H7Z" />
    </svg>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'stf-icon-menu-up',
});
</script>
