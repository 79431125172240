import { Domain } from '@/config/domain/type';

export const trademarksOnlineHongKong: Domain = {
    domain: import.meta.env.VITE_TMO_HK_DOMAIN,
    shortCode: 'TMO-HK',
    title: 'Apply for your Hong Kong trademark',
    siteName: 'Trademarks Hong Kong',
    description: 'Our online trademark registration platform is available 24/7 and takes just minutes. Apply for your Hong Kong trademark by entering your trademark or logo.',
    headerColor: '#fff',
    footerColor: 'grey-dark',
    mobileMenuColor: '#505050',
    contactFlagImgUrl: '',
    menuItems: [
        {
            label: 'Home',
            url: 'https://www.trademarksonline.hk/',
            child: []
        }, {
            label: 'Pricing',
            url: 'https://www.trademarksonline.hk/pricing',
            child: []
        }, {
            label: 'FAQ',
            url: 'https://www.trademarksonline.hk/faq',
            child: []
        }, {
            label: 'About us',
            url: 'https://www.trademarksonline.hk/about-us',
            child: []
        }
    ],
    headerImageUrl: 'https://images.squarespace-cdn.com/content/v1/6479606464ff1518c0aa2c1b/17a3fa55-e12a-47f6-872b-a774fb18ee15/TM+Online+Squirkle+Red+1.png',
    headerImageTitle: 'Trademarks Online Hong Kong',
    bannerImageUrl: 'https://images.squarespace-cdn.com/content/v1/6479606464ff1518c0aa2c1b/54e545ab-519a-4638-975a-8bfc0779703e/TMO+HK+banner.png',
    termOfUseUrl: 'http://www.trademarksonline.hk/terms-of-use',
    declarationTitle: 'I confirm that',
    declarationList: [
        'The applicant intends to use, and is entitled to own, the trademark',
        'The TM Office will not let me change the trademark or goods and services after it has been submitted'
    ],
    gtmId: 'GTM-5MSFXTNV',
};
