<template>
    <div class="is-flex is-justify-content-space-between mb-5">
        <stf-heading-one>
            {{ title }}
        </stf-heading-one>
        <slot />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'MarkHeader',
    props: {
        title: {
            type: String,
            required: true
        }
    },
});
</script>