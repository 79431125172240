import { RequestResponse } from '../http-module/http-type';
import portalApiClient from './client/portal-api';

const markImage = {
    async postImage(country: string, formData: FormData): Promise<RequestResponse> {
        return await portalApiClient.postForm(country + '/mark-images', formData);
    }
};

export default markImage;
