<template>
    <div class="columns is-mobile is-variable is-0">
        <div class="column is-narrow heading-3 stf-text-grey py-1">{{ trans('message.payment.orderTitle') }}</div>
    </div>
    <div class="columns is-mobile is-variable is-0">
        <div class="column is-12">
            <stf-card>
                <div class="columns is-mobile is-variable is-1">
                    <div class="column py-1 is-9 body-1">
                        <template v-if="trademarkWord !== ''">
                            {{ trans('message.payment.wordMark', {
                                wordMark: trademarkWord,
                                countryCode: country.combinedDisplayCode
                            }) }}
                        </template>
                        <template v-else>
                            <img class="stf-image--tiny v-align-middle" :src="getImageUrl()"/>
                            {{ trans('message.payment.imageMark', {
                                countryCode: country.combinedDisplayCode
                            }) }}
                        </template>
                    </div>
                    <div class="column py-1 has-text-right body-1">
                        {{ $filters.formatAmountWithCurrency(flatFee, country.currencyWithCountry) }}
                    </div>
                </div>
                <div>
                    <class-info
                        v-for="(classNumber, index) in goodClasses"
                        :key="classNumber"
                        :application="checkout.application"
                        :class-number="classNumber"
                        :first="index === 0"
                    />
                </div>
                <stf-spacer/>
                <div class="columns is-mobile is-variable is-1">
                    <div class="column is-narrow body-1 py-1">
                        {{ trans('message.payment.subTotal') }}
                    </div>
                    <div class="column has-text-right body-1 py-1">
                        {{ $filters.formatAmountWithCurrency(subTotal, country.currencyWithCountry) }}
                    </div>
                </div>
                <div class="columns is-mobile is-variable is-1">
                    <div class="column is-narrow body-1 py-1">
                        {{ trans('message.payment.tax') }}
                    </div>
                    <div class="column has-text-right body-1 py-1">
                        {{ $filters.formatAmountWithCurrency(tax, country.currencyWithCountry) }}
                    </div>
                </div>
            </stf-card>
        </div>
    </div>
</template>

<script lang="ts">
import useCountry from '@/composables/countryDependable';
import useInternalization from '@/composables/internalizable';
import Checkout from '@/core/model/checkout';
import StfCard from '@/ui/card/stf-card.vue';
import StfSpacer from '@/ui/spacer/stf-spacer.vue';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import ClassInfo from './class-info.vue';

export default defineComponent({
    components: { 
        StfCard,
        ClassInfo,
        StfSpacer
    },
    props: {
        checkout: {
            type: Checkout,
            required: true
        }
    },
    setup() {
        const country = useCountry();
        return {
            country,
            ...useInternalization(country)
        };
    },
    computed: {
        ...mapGetters('good', ['goodClasses']),
        ...mapGetters('mark', ['trademarkWord', 'trademarkImage']),
        flatFee(): number | null {
            if (!this.checkout || !this.checkout.application) {
                return null;
            }

            return this.checkout.application.flatFee;
        },
        total(): number | null {
            if (!this.checkout || !this.checkout.application) {
                return null;
            }

            return this.checkout.application.total;
        },
        tax(): number | null {
            if (!this.checkout || !this.checkout.application) {
                return null;
            }

            return this.checkout.application.tax;
        },
        subTotal(): number | null {
            if (!this.checkout || !this.checkout.application) {
                return null;
            }

            return this.checkout.application.subTotal;
        },
        extraClassFee(): number | null {
            if (!this.checkout || !this.checkout.application) {
                return null;
            }

            return this.checkout.application.extraClassFees;
        },
    },
    methods: {
        getImageUrl() {
            return window.location.origin + this.trademarkImage
        }
    }
});
</script>
