<template>
    <stf-input
        id="cy-search"
        action
        name='search'
        :label="label"
        v-model="search"
        @action="onSearch"
        :action-loading="loading"
    />
</template>

<script lang="ts">
import useCountry from '@/composables/countryDependable';
import useInternalization from '@/composables/internalizable';
import stfInput from '@/ui/input/stf-input.vue';
import { defineComponent } from 'vue';

export default defineComponent({
    components: { stfInput },
    name: 'SearchBox',
    props: {
        loading: {
            type: Boolean,
            default: false,
        }
    },
    setup() {
        return { ...useInternalization(useCountry())};
    },
    data() {
        return {
            searchDone: false,
            search: '',
        }
    },
    computed: {
        label(): string {
            if (this.searchDone) {
                return this.trans('message.goods.search.searchAgain');
            }
            
            return this.trans('message.goods.search.search');
        }
    },
    watch: {
        loading(loadingValue: boolean) {
            if (!loadingValue) {
                this.search = '';
            }
        }
    },
    methods: {
        onSearch() {
            this.$emit('search', this.search);
            this.searchDone = true;
        }
    }
});
</script>
