import { malaysiaWorkflow } from '../workflow';
import { Country, CountryCode } from './type';

export const malaysia: Country = {
    name: 'Malaysia',
    code: CountryCode.MY,
    displayCode: CountryCode.MY,
    combinedDisplayCode: CountryCode.MY,
    workflow: malaysiaWorkflow,
    makeMethod: 'makeMalaysia',
    theme: false,
    currency: 'MYR ',
    currencyName: 'MYR ',
    currencyWithCountry: 'MYR ',
    maxImageSize: 20000000, // 20MB
    maxGoodNumberPerClass: 100,
    paymentMethodsImageName: 'image-4-card',
    stripeKey: import.meta.env.VITE_STRIP_PUBLIC_KEY_MY,
    autoSelectedInForm: true,
};
