<template>
    <div class="row mt-3 justify-content-center">
        <div class="col-sm-12 col-lg-10 col-xl-8">
            <div :class="getTipsRowStyle()">
                <ul :class="getUlStyle()">
                    <li
                        :class="getLiStyle()"
                        v-for="(tipsStr, key) in this.tipsArr"
                        :key="key"
                        v-html="tipsStr"
                    >
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'InfoHelp',
    props: {
        tipsArr: {
            type: Array,
            default: () => []
        },
        tipsStyle: {
            type: String,
            default: 'goods'
        }
    },
    methods: {
        getTipsRowStyle() {
            if (this.tipsStyle.toString() === 'goods') {
                return 'help';
            }
            return 'note note-success';
        },
        getLiStyle() {
            if (this.tipsStyle.toString() === 'goods') {
                return 'm-3';
            }
            return '';
        },
        getUlStyle() {
            if (this.tipsStyle.toString() === 'goods') {
                return 'pl-0';
            }
            return 'pl-4';
        }
    }
});
</script>
