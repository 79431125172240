<template>
    <footer class="footer-container mb-6 pb-1">
        <div class="is-centered tmp-group-footer-first-line">
            <p>Part of the Trademark Planet group</p>
        </div>
        <div class="columns tmp-group-footer-image">
            <img
                loading="lazy"
                src="https://images.squarespace-cdn.com/content/v1/60d93cf02ee9e55a1978b3cb/1625786137543-XL0FNAV978VCCTI44YPE/STM.jpg"
                alt="" title=""
                srcset="https://images.squarespace-cdn.com/content/v1/60d93cf02ee9e55a1978b3cb/1625786137543-XL0FNAV978VCCTI44YPE/STM.jpg?format=1640w 1640w,
                https://images.squarespace-cdn.com/content/v1/60d93cf02ee9e55a1978b3cb/1625786137543-XL0FNAV978VCCTI44YPE/STM.jpg?format=1280w 1280w,
                https://images.squarespace-cdn.com/content/v1/60d93cf02ee9e55a1978b3cb/1625786137543-XL0FNAV978VCCTI44YPE/STM.jpg?format=980w 980w,
                https://images.squarespace-cdn.com/content/v1/60d93cf02ee9e55a1978b3cb/1625786137543-XL0FNAV978VCCTI44YPE/STM.jpg?format=480w 480w"
                sizes="(min-width: 0px) and (max-width: 480px) 480px,
                (min-width: 481px) and (max-width: 980px) 980px,
                (min-width: 981px) and (max-width: 1280px) 1280px,
                (min-width: 1281px) 1640px, 100vw"
            >
        </div>
        <div class="tmp-group-footer-last-line">
            <span>{{copyright}}</span>
            <span v-if="!$screen.isMobile" class="tmp-group-footer-divider">|</span>
            <br v-if="$screen.isMobile">
            <span><external-link :href="footerMenu.termsLink">Terms of use</external-link></span>
            <span v-if="!$screen.isMobile" class="tmp-group-footer-divider">|</span>
            <br v-if="$screen.isMobile">
            <span><external-link :href="footerMenu.privacyLink">Privacy policy</external-link></span>
            <span v-if="!$screen.isMobile" class="tmp-group-footer-divider">|</span>
            <br v-if="$screen.isMobile">
            <span><external-link :href="footerMenu.contactLink">Contact us</external-link></span>
            <template v-if="!noPhone">
                <span v-if="!$screen.isMobile" class="tmp-group-footer-divider">|</span>
                <br v-if="$screen.isMobile">
                <span>Telephone <strong>{{footerMenu.contactNumber}}</strong></span>
            </template>
        </div>
    </footer>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import ExternalLink from "@/components/common/external-link.vue";
import useCopyright from "@/composables/copyrightable";

export default defineComponent({
    name: 'TmpGroupFooterReplica',
  setup() {
    const copyright = useCopyright();

    return {copyright};
  },
  components: {ExternalLink},
    props: {
        footerMenu: {
            type: Object,
            required: true
        },
        noPhone: {
            type: Boolean,
            default: false
        }
    }
});

</script>

<style scoped>
.footer-container {
  font-weight: 400;
  font-style: normal;
  line-height: 1.7em;
  letter-spacing: 0;
  display: block;
  color: #777;
  font-size: 14px;
}

.tmp-group-footer-first-line {
  margin-top: 100px;
  text-align:center;
  white-space:pre-wrap;
}

.tmp-group-footer-image {
  margin: auto;
  text-align:center;
  justify-content: center;
}

.tmp-group-footer-last-line {
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
  white-space: pre-wrap;
}

.tmp-group-footer-divider {
  word-wrap: break-word;
  white-space: pre-wrap;
}

span {
  margin-right: 28px;
  margin-left: 28px;
  white-space: nowrap;
}

span > a {
  color: #5fad2a;
}

</style>
