import { provide, ref, onMounted } from 'vue'

export default function useGlobalErrorTeleport(): void {
    const isMounted = ref(false)

    onMounted(() => {
        isMounted.value = true;
    });

    provide('teleportMounted', isMounted)
}
