export default {
    message: {
        mark: {
            info: {
                logo: 'Logos <b>must</b> be uploaded as TIFF, PNG, JPEG, or JPG files.'
            },
            form: {
                label: {
                    image: 'Select TIF, PNG, JPEG, or JPG file',
                    imageExtension: '.tif,.png,.jpeg,.jpg'
                }
            }
        },
        payment: {
            gst: 'GST (10%)',
            tax: 'GST:',
        },
        success: {
            submitInfo: 'Your Australian trademark application will be submitted to the Trademark Office in one business day.<br/> Please check your emails for our order confirmation and payment receipt/tax invoice.'
        }
    }
};
