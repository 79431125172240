import HttpLoggedClientDecorator from '@/core/http-module/http-logged-client-decortor';
import HttpClientAdapter from '@/core/http-module/http-client-adapter';
import logger from '@/core/logger/logger';
import { RequestResponse } from '@/core/http-module/http-type';

    export class UserApiClient {
    private static instance: UserApiClient;
    private client: HttpLoggedClientDecorator;

    private constructor() {
        const httpClientAdapter = new HttpClientAdapter(window.location.origin + '/user-api');

        this.client = new HttpLoggedClientDecorator(httpClientAdapter, logger);
    }

    async get(url: string): Promise<RequestResponse> {
        return await this.client.get(url);
    }

    async post(url: string, data: any): Promise<RequestResponse> {
        return await this.client.post(url, data);
    }

    static getInstance() {
        if (!UserApiClient.instance) {
            this.instance = new UserApiClient();
        }

        return this.instance;
    }
}

export default UserApiClient.getInstance();
