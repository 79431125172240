import { beneluxWorkflow } from '../workflow';
import { Country, CountryCode } from './type';

export const benelux: Country = {
    name: 'Benelux',
    code: CountryCode.BE,
    displayCode: CountryCode.BX,
    combinedDisplayCode: CountryCode.BENELUX,
    workflow: beneluxWorkflow,
    makeMethod: 'makeBenelux',
    theme: false,
    currency: '€ ',
    currencyName: 'EUR ',
    currencyWithCountry: '€ ',
    maxImageSize: 20000000, // 20MB
    maxGoodNumberPerClass: 100,
    paymentMethodsImageName: 'image-4-card',
    stripeKey: import.meta.env.VITE_STRIP_PUBLIC_KEY_BX,
    autoSelectedInForm: false,
};
