<template>
    <div class="records">
        <table class="records__table">
            <thead>
                <tr class="records__header-row">
                    <th 
                        v-for="(title, index) in trademarkTitles" 
                        :key="index"
                        :class="{ 'records__header-cell--goods': index === trademarkTitles.length - 2 }"
                        class="records__header-cell"
                    >
                        <stf-strong>{{ title }}</stf-strong>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(trademark, index) in marksRecords" :key="index" class="records__row">
                    <td class="records__cell">
                        {{ trademark.country?.name }}
                    </td>
                    <td class="records__cell">
                        {{ trademark.applicationNumber }}
                    </td>
                    <td class="records__cell">
                        <img
                            v-if="isTrademarkImage(trademark)"
                            class="records__image"
                            :src="getImageUrl(trademark.markImage?.url || '')"
                        >
                        <span v-else>
                            {{ trademark.markDescription }}
                        </span>
                    </td>
                    <td class="records__cell">
                        {{ trademark.normalizedStatus?.name }}
                    </td>
                    <td class="records__cell">
                        {{ stringifyNames(trademark.markOwners) }}
                    </td>
                    <td class="records__cell">
                        {{ stringifyGroupValues(trademark.markGoods, 'classNumber') }}
                    </td>
                    <td class="records__cell">
                        <stf-truncate
                            :text="stringifyGroupValues(trademark.markGoods, 'name')"
                            :max-words="16"
                        />
                    </td>
                    <td class="records__cell">
                        <stf-button
                            @click="showRecordDetails(trademark)"
                            color="primary-console"
                            small
                            noWrapper
                        >
                            See more
                        </stf-button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { mapActions } from 'vuex';
import {getImageUrl, isTrademarkImage, stringifyGroupValues, stringifyNames} from '@/core/util/trademarks-utils';
import StfTruncate from '@/ui/truncate/stf-truncate.vue';
import StfButton from '@/ui/button/stf-button.vue';
import type { Mark } from '@/core/api/user-console/type';
import RouteName from '@/router/route-name';

export default defineComponent({
    name: 'MarkRecordsTable',
    components: {
        StfTruncate,
        StfButton
    },
    props: {
        marksRecords: {
            type: Array as PropType<Mark[]>,
            required: true
        }
    },
    data () {
        return {
            trademarkTitles: [
                'Country',
                'Application Number',
                'Trademark',
                'Status',
                'Trademark Owner',
                'Classes', 
                'Products/Services',
                'Actions'
            ]
        };
    },
    methods: {
        ...mapActions('marksRecords', ['setMarkRecordDetails']),
        stringifyNames,
        stringifyGroupValues,
        getImageUrl,
        isTrademarkImage,
        showRecordDetails(markRecord: Mark) {
            this.setMarkRecordDetails(markRecord);
            this.$router.push({name: RouteName.TRADEMARKS_DETAILS});
        },
    }
});
</script>
