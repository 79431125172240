export default {
    message: {
        mark: {
            info: {
                logo: 'Logos <b>must</b> be uploaded as TIFF, PNG, JPEG, JPG, or GIF files with a maximum size of 4MB.'
            },
            form: {
                label: {
                    image: 'Select TIF, PNG, JPEG, JPG, or GIF file',
                    imageExtension: '.tif,.png,.jpeg,.jpg,.gif'
                }
            }
        },
        contact: {
            form: {
                companyNumber: {
                    label: 'Company number',
                    invalid: 'Company number is Invalid'
                },
            }
        },
        goods: {
            search: {
                firstItemSelected: {
                    great: 'You are protecting your trademark in class [ {number} ].',
                    selectAgain: ' Add more terms in this class for no extra cost.',
                },
                secondClassSelected: {
                    great: 'You have added an extra class.',
                    // hard coding UK single class fee 100
                    selectAgain: ' Each extra class costs {currency}100.',
                },
            }
        },
        price: {
            baseFee: 'Trademark',
            classFee: 'Extra classes',
            baseFeeRichWording: 'Trademark in 1 class',
            classFeeRichWording: 'Extra classes (£100 each)',
            total: 'Total',
        }
    }
};
