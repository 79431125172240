
export default {
    install: (): void => {
        // add comment line
        const comments = document.createComment(' UET tag Tracking Code for https://app.trademarkplanet.com ');
        document.head.appendChild(comments);

        // add Google Ads Conversion parameters script
        const bingUETQScript = document.createElement('script');
        bingUETQScript.setAttribute('type', 'text/javascript');
        bingUETQScript.text += '(function (w, d, t, r, u) {\n' +
            '    var f, n, i;\n' +
            '    w[u] = w[u] || [], f = function () {\n' +
            '        var o = {ti: "17540283"};\n' +
            '        o.q = w[u], w[u] = new UET(o), w[u].push("pageLoad")\n' +
            '    }, n = d.createElement(t), n.src = r, n.async = 1, n.onload = n.onreadystatechange = function () {\n' +
            '        var s = this.readyState;\n' +
            '        s && s !== "loaded" && s !== "complete" || (f(), n.onload = n.onreadystatechange = null)\n' +
            '    }, i = d.getElementsByTagName(t)[0], i.parentNode.insertBefore(n, i)\n' +
            '})(window, document, "script", "//bat.bing.com/bat.js", "uetq");';
        document.head.appendChild(bingUETQScript);
    }
};
