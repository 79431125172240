import { useStore } from 'vuex';
import { onMounted } from 'vue';

export default function useAppReference(countryCode: string): void {
    const store = useStore();

    onMounted(() => {
        store.dispatch('application/generateRef', countryCode);
    });
}
