import { Domain } from '@/config/domain/type';

export const trademarksOnlineNewZealand: Domain = {
    domain: import.meta.env.VITE_TMO_NZ_DOMAIN,
    shortCode: 'TMO-NZ',
    title: 'Apply for your New Zealand trademark',
    siteName: 'Trademarks New Zealand',
    description: 'Our online trademark registration platform is available 24/7 and takes just minutes. Apply for your New Zealand trademark by entering your trademark or logo.',
    headerColor: '#fff',
    footerColor: 'grey-dark',
    mobileMenuColor: '#505050',
    contactFlagImgUrl: '',
    menuItems: [
        {
            label: 'Home',
            url: 'https://www.trademarksonline.nz/',
            child: []
        }, {
            label: 'Need to know',
            url: 'https://www.trademarksonline.nz/need-to-know',
            child: []
        }, {
            label: 'Pricing',
            url: 'https://www.trademarksonline.nz/pricing',
            child: []
        }, {
            label: 'Faq',
            url: 'https://www.trademarksonline.nz/faq',
            child: []
        }, {
            label: 'Testimonials',
            url: 'https://www.trademarksonline.nz/testimonials',
            child: []
        }, {
            label: 'About us',
            url: 'https://www.trademarksonline.nz/aboutus',
            child: []
        }, {
            label: 'Blog',
            url: 'https://www.trademarksonline.nz/blog',
            child: []
        }
    ],
    headerImageUrl: 'https://images.squarespace-cdn.com/content/664132c3541a8a4acd3f96aa/094c735a-02eb-4bc8-a857-e0ddab4fa475/app_logo_dark.png',
    headerImageTitle: 'Trademarks Online New Zealand',
    bannerImageUrl: 'https://images.squarespace-cdn.com/content/v1/62afc363f951920a266e3af0/58c83014-f8b8-4191-9b78-5e59cbbcaebb/NZ+Banner.png',
    termOfUseUrl: 'http://www.trademarksonline.nz/terms-of-use',
    declarationTitle: 'I confirm that',
    declarationList: [
        'The applicant intends to use, and is entitled to own, the trademark',
        'The TM Office will not let me change the trademark or goods and services after it has been submitted'
    ],
    gtmId: 'GTM-K2HBMBWF',
};
