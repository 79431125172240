import { trackRouter } from 'vue-gtag-next';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import store from '@/store/index';
import RouteName from './route-name';
import Mark from '@/views/mark.vue';
import Goods from '@/views/goods.vue';
import Contact from '@/views/contact.vue';
import Payment from '@/views/payment.vue';
import Success from '@/views/success.vue';
import Trademarks from '@/views/user-console/marks-records.vue';
import TrademarkDetails from '@/views/user-console/mark-details.vue';
import Login from '@/views/login.vue';
import ResetPassword from '@/views/reset-password.vue';
import ResetPasswordReset from '@/views/reset-password-reset.vue';
import CompleteCart from '@/views/complete-cart.vue';
import NotFound from '@/views/not-found.vue';
import { countryPath, isCountryNavigationAllow } from './helpers';
import countries from '@/config/country';
import authentication from '@/core/authentication/authentication';

const routes: Array<RouteRecordRaw> = [
    {
        path: countryPath('/trade-mark', countries),
        name: RouteName.MARK,
        component: Mark,
        meta: { isCountryRoute: true, stepper: true }
    },
    {
        path: countryPath('/goods', countries),
        name: RouteName.GOODS,
        component: Goods,
        meta: { isCountryRoute: true, stepper: true }
    },
    {
        path: countryPath('/contact', countries),
        name: RouteName.CONTACT,
        component: Contact,
        meta: { isCountryRoute: true, layout: 'new-design', stepper: true}
    },
    {
        path: countryPath('/payment', countries),
        name: RouteName.PAYMENT,
        component: Payment,
        meta: { isCountryRoute: true , layout: 'new-design', stepper: true}
    },
    {
        path: countryPath('/success', countries),
        name: RouteName.SUCCESS,
        component: Success,
        meta: { isCountryRoute: false }
    },
    {
        path: '/login',
        name: RouteName.LOGIN,
        component: Login,
        meta: { isCountryRoute: false, layout: 'user-console'}
    },
    {
        path: '/trademarks',
        name: RouteName.TRADEMARKS,
        component: Trademarks,
        meta: { requiresAuth: true, layout: 'user-console', isCountryRoute: false}
    },
    {
        path: '/trademark-details',
        name: RouteName.TRADEMARKS_DETAILS,
        component: TrademarkDetails,
        meta: { requiresAuth: true, layout: 'user-console', isCountryRoute: false}
    },
    {
        path: '/reset-password',
        name: RouteName.RESET_PASSWORD,
        component: ResetPassword,
        meta: { isCountryRoute: false, layout: 'user-console'}
    },
    {
        path: '/reset-password/reset',
        name: RouteName.RESET_PASSWORD_RESET,
        component: ResetPasswordReset,
        meta: { isCountryRoute: false, layout: 'user-console'}
    },
    {
        path: countryPath('/complete-cart/:cartRef', countries),
        name: RouteName.COMPLETE_CART,
        component: CompleteCart,
        meta: { isCountryRoute: true}
    },
    { path: '/:pathMatch(.*)*', name: RouteName.NOT_FOUND, component: NotFound },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        // scroll to top
        const appElement = document.getElementById('app');
        if (appElement !== null) {
            appElement.scrollIntoView();
        }
        window.parent.postMessage({ command: 'toTop', message: 'toTop' }, '*');
    }
});

router.beforeEach(async (to, from, next) => {
    const isAllowed = isCountryNavigationAllow(to, countries);

    if (!isAllowed) {
        return next({ name: RouteName.NOT_FOUND });
    }

    if(to.matched.some(record => record.meta.requiresAuth)) {
        const isAuth = await authentication.isAuth();
        if(isAuth) {
            return next();
        } else {
            store.dispatch('authentication/setLoading', false)
            return next({ name: RouteName.LOGIN });
        }
    }
    return next();
});


trackRouter(router);

export default router;
