const rollBarOption = {
    accessToken: import.meta.env.VITE_ROLLBAR_POST_CLIENT_ITEM_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    addErrorContext: true,
    stackTraceLimit: 20,
    retryInterval: 5,
    ignoreDuplicateErrors: false,
    enabled: import.meta.env.MODE !== 'development',
    environment: import.meta.env.MODE,
    payload: {
        client: {
            javascript: {
                source_map_enabled: true,
                code_version: process.env.COMMIT,
                guess_uncaught_frames: true
            }
        }
    }
};

export default rollBarOption;
