import { CountryCode } from '@/config/country/type';
import { MarkOwnerType } from '../interface/api-interface';


export const isBusinessName = function (name: string): boolean {
    //if one word it is a business
    name = name.trim();
    if (name.split(' ').length === 1) {
        return true;
    }

    const limitedAtTheEnd = new RegExp('limited\\b$', 'ig');
    const ltdAtTheEnd = new RegExp('ltd\\b$', 'ig');
    const ltdWithFullStopAtTheEnd = new RegExp('ltd\\.$', 'ig');

    if (limitedAtTheEnd.test(name) || ltdAtTheEnd.test(name) || ltdWithFullStopAtTheEnd.test(name)) {
        return true;
    }

    return false;
};

export const getOwnerTypeByName = function (name: string): MarkOwnerType {
    if (isBusinessName(name)) {
        return MarkOwnerType.BUSINESS;
    }

    return MarkOwnerType.PERSON;
};

export const getOwnerType = function(ownerName: string, secondOwnerName: string, companyNumber: string): MarkOwnerType {
    if (companyNumber.length > 0) {
        return MarkOwnerType.BUSINESS;
    } else if ((isBusinessName(ownerName) || (secondOwnerName !== '' && isBusinessName(secondOwnerName)))) {
        return MarkOwnerType.BUSINESS;
    } else {
        return MarkOwnerType.PERSON;
    }
};

export const getFirstAndLastNameFromString = function (name: string): {firstName: string; lastName: string}  {
    const firstLastName = {
        firstName: '',
        lastName: ''
    }

    name = name.trim();
    const indexOfFirstSpace = name.indexOf(' ');
    if (indexOfFirstSpace === -1) {
        firstLastName.firstName = name;
    } else {
        firstLastName.firstName = name.substr(0, indexOfFirstSpace).trim();
        firstLastName.lastName = name.substr(indexOfFirstSpace).trim();
    }

    return firstLastName;
};

export const isValidFirstAndLastName = function(name: string): boolean {
    const firstLastName = getFirstAndLastNameFromString(name);

    return firstLastName.firstName !== '' && firstLastName.lastName !== '';
}

const ABN_LENGTH = 11; // Australian business number length

export const isBusinessNumber = function (companyNumber: string, citizenCountryCode: string): boolean  {
    const isAustralianBusinessNumber = citizenCountryCode === CountryCode.AU && companyNumber.replace(/\s+/g, '').length === ABN_LENGTH;
    return isAustralianBusinessNumber;
};
