<script lang="ts">
import { StepperBuilder, StepperDirector } from './stepper-builder';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'StepperBuilder',
    props: {
        formMethod: {
            type: String,
            required: true
        }
    },
    render() {
        const director = new StepperDirector();
        const builder = new StepperBuilder();

        director[this.formMethod](builder);

        return builder.getStepper();
    }
});
</script>
