
/**
 * Build up the redirect domain needs to change depending if we are using iframe or not
 */
const buildRedirectDomain = function (domain: string, protocol: string, countryCode: string, param: string): string {
    const schema = protocol == 'http:' ? 'http://' : 'https://';
    return schema + domain + '/' + countryCode.toLowerCase() + '/' + param;
};

/**
 * Build success URL that should be used once payment is completed
 */
export const getSuccessUrl = function (domain: string, protocol: string, countryCode: string): string {
    return buildRedirectDomain(domain, protocol, countryCode, 'success');
};

/**
 * Build a Cancel URL that should end-user will go if they do not want to complete payment
 */
export const getCancelUrl = function (domain: string, protocol: string, countryCode: string): string {
    return buildRedirectDomain(domain, protocol, countryCode, 'payment');
};

export const getSiteNameFromUrl = function (domain: string): string {
    //remove www, .com, .co, .nz, etc ...
    const domainFiltered = domain.replace(/(www.)|(.com)|(.co)|(.au)|(.uk)|(.nz)/ig, '');

    //split domain, subdomain.
    const splittedDomain = domainFiltered.split('.');

    //domain is the last one.
    return splittedDomain[splittedDomain.length - 1];
}
