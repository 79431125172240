/**
 * Config file for the domains supported by the app.
 */
import { diyNewZealand } from './diy-nz';
import { trademarksOnlineAustralia } from './tmo-au';
import { trademarksOnlineUnitedKingdom } from './tmo-uk';
import { trademarkPlanetUnitedKingdom } from './tmp-uk';
import { trademarksOnlineSingapore } from './tmo-sg';
import { trademarksOnlineMalaysia } from './tmo-my';
import { trademarksOnlineBenelux } from './tmo-bx';
import { trademarksOnlineNewZealand } from './tmo-nz';
import { trademarksOnlineHongKong } from './tmo-hk';

export default [
    diyNewZealand,
    trademarksOnlineAustralia,
    trademarksOnlineUnitedKingdom,
    trademarkPlanetUnitedKingdom,
    trademarksOnlineSingapore,
    trademarksOnlineMalaysia,
    trademarksOnlineBenelux,
    trademarksOnlineNewZealand,
    trademarksOnlineHongKong
];
