<template>
    <div class="container my-6 pt-6">
        <div>
            <img
                class="is-flex mx-auto footer-image"
                loading="lazy"
                :src="footerMenu.imageUrl"
                alt=""
            >
        </div>
        <div class="container has-text-centered mt-5">
            <p>Part of the Trademark Planet group</p>
        </div>
        <div class="is-flex is-flex-wrap-wrap is-justify-content-center is-align-items-center mx-auto mt-3">
            <span v-for="(menuItem, index) in footerMenu.tmoCountries" :key="index">
                <span v-if="index !== 0">|</span>
                <external-link :href="menuItem.url" class="mx-2">{{menuItem.name}}</external-link>
            </span>
        </div>
        <div
            v-if="footerMenu.phone"
            class="is-flex is-justify-content-center is-align-items-center mx-auto mt-6"
        >
            Telephone {{ footerMenu.phone }}
        </div>
        <div class="is-flex is-flex-wrap-wrap is-justify-content-center is-align-items-center mx-auto mt-6 body-2">
            <span><external-link :href="footerMenu.privacyUrl" class="mx-2">Privacy policy</external-link></span>
            <span>|</span>
            <span><external-link :href="footerMenu.termsUrl" class="mx-2">Terms of use</external-link></span>
            <span>|</span>
            <span><external-link :href="footerMenu.contactUrl" class="mx-2">Contact us</external-link></span>
        </div>
        <div class="container has-text-centered body-2">
            <p>{{copyright}}</p>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import useDomain from '@/composables/domainDependable';
import ExternalLink from "@/components/common/external-link.vue";
import useCopyright from "@/composables/copyrightable";

export default defineComponent({
    name: 'TmoGroupFooterReplica',
    setup() {
        const domain = useDomain();
        const copyright = useCopyright();

        return {domain, copyright};
    },
    components: {
      ExternalLink
    },
    props: {
        footerMenu: {
            type: Object,
            required: true
        }
    }
});
</script>

<style scoped>
  .footer-image {
    max-width: 480px;
  }

  span > a {
    text-underline-offset: 2px;
  }

  @media screen and (max-width: 768px){
    .footer-image {
      max-width: 100%;
      padding: 0 10px;
    }
  }
</style>
