<template>
    <stf-card color="grey100" class="px-3 py-1 pb-3 mt-5">
        <stf-card color="grey100" class="py-1 ml-4">
            <input
                :name="name"
                type="checkbox"
                id="formValidation"
                v-model="isChecked"
            />
            <label for="formValidation" class="pl-1">
                {{ declarationTitle }}:
            </label>
        </stf-card>
        <stf-card class="my-0 py-1">
            <ul>
                <li v-for="(message, index) in declarationList" :key="index">
                    {{ message }}
                </li>
                <li>I have read and agree to the <external-link :href="termsUrl">Terms of Use</external-link> and consent to receive emails as set out therein.</li>
            </ul>
        </stf-card>
    </stf-card>
    <div v-if="errorMessage.length" class="py-1 ml-6 error">
        {{ errorMessage }}
    </div>
</template>

<script lang="ts">
import StfCard from '@/ui/card/stf-card.vue';
import { defineComponent } from 'vue';
import useDomain from '@/composables/domainDependable';
import ExternalLink from "@/components/common/external-link.vue";

export default defineComponent({
    inheritAttrs:false,
    name: ' StfDeclaration',
    emits: ['update:modelValue'],
    data() {
        return {
            isChecked: this.modelValue ,
        }
    },
    setup() {
        const domain = useDomain();
        return {
            termsUrl: domain.termOfUseUrl,
            declarationTitle: domain.declarationTitle,
            declarationList: domain.declarationList,
        };
    },
    components: {
      ExternalLink,
        StfCard
    },
    props: {
        name: {
            type: String,
            required: true
        },
        modelValue: {
            type: Boolean,
            default: false
        },
        errorMessage: {
            type: String,
            default: ''
        }
    },
    watch: {
        modelValue(newValue) {
            this.isChecked = newValue;
        },
        isChecked(newValue) {
            this.$emit('update:modelValue', newValue);
        },
    }
});

</script>
