import {Country} from '@/config/country/type';
import {SUCCESS} from '@/router/route-name';
import api from '@/core/api';
import {getCancelUrl, getSuccessUrl} from '@/core/util/url-utils';

type Config = {
    getPaymentRef: () => string;
    onBeforeCreateOrder: () => void;
    onCreateOrder: (sessionId: string) => void;
    onApproveOrder: (payType: string) => void;
    onRouterChange: (routerName: { name: string }) => void;
    onError: (err: string) => void;
    onInfo: (msg: string) => void;
    onFinish: () => void;
    style: any;
}

class PaypalService {
    private paypal: any
    private country: Country;
    private config: Config;

    private constructor(
        paypal: any,
        country: Country,
        config: Config
    ) {
        this.country = country;
        this.paypal = paypal;
        this.config = config;
        this.renderPayPalButton();
    }

    public static initPaypal(
        paypal: any,
        country: Country,
        config: Config
    ) {
        // do not use singleton, render paypal each time page mounted
        if (!paypal || !paypal.Buttons) {
            throw new Error('pay or paypal.Buttons is undefined')
        }

        return new PaypalService(paypal, country, config)
    }

    private renderPayPalButton() {
        if (this.paypal.Buttons.close) {
            // ref: https://github.com/paypal/paypal-checkout-components/issues/1506
            this.paypal.Buttons.close();
        }
        // render the buttons
        this.paypal.Buttons({
            // Call server to create an order for the transaction
            createOrder: () => {
                return this.creatPayPalOrder();
            },
            // Call server to finalize the transaction
            onApprove: (data: any, actions: any) => {
                return this.approvePayPalOrder(data, actions);
            },
            onError: (err: any) => {
                this.config.onError('error from rendering paypal button: ' + err);
            },
            onInit: function(data: any, actions: any) {
                document.addEventListener('disablePayment', () => {
                    actions.disable();
                });
            },
            style: this.config.style
        }).render('#paypal-button-container');
    }

    private async creatPayPalOrder() {
        // call API to set up the details of the transaction.
        await this.config.onBeforeCreateOrder()

        const paypalSession = await api.payment.checkoutTransactionId('paypal', this.country.displayCode.toLowerCase(), this.config.getPaymentRef(), {
                successUrl: getSuccessUrl(window.location.host, window.location.protocol, this.country.displayCode),
                cancelUrl: getCancelUrl(window.location.host, window.location.protocol, this.country.displayCode)
            });

        if (!paypalSession.id) {
            this.config.onError('An error occur, failed to get paypal checkoutTransactionId');
            this.config.onFinish();
            return;
        }
        this.config.onCreateOrder(paypalSession.id)
        return paypalSession.id;
    }

    private async approvePayPalOrder(data: any, actions: any) {
        this.config.onApproveOrder('paypal');
        const reqData = {'orderId': data.orderID};
        await api.payment.capturePaypalTransaction(this.country.displayCode.toLowerCase(), reqData)
            .then((response) => {
                return JSON.stringify(response)
            }).then((resData) => {
                // ref: https://developer.paypal.com/demo/checkout/#/pattern/server
                const resDataObj = JSON.parse(resData)
                if (resDataObj._status && resDataObj._status === 200) {
                    const resDataInfo = resDataObj._content.data;
                    // 1.Successful transaction -> Show confirmation
                    if (resDataInfo.success) {
                        this.config.onInfo('paypal transaction completed!');
                        this.config.onFinish();
                        this.config.onRouterChange({name: SUCCESS});
                        return;
                    }
                    // 2.Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                    const resDataInfoErrorDetail = Array.isArray(resDataInfo.details) && resDataInfo.details[0];
                    if (!resDataInfo.success && resDataInfoErrorDetail && resDataInfoErrorDetail.issue === 'INSTRUMENT_DECLINED') {
                        // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
                        this.config.onInfo('paypal INSTRUMENT_DECLINED, ' + resDataInfo.details.description);
                        this.config.onFinish();
                        return actions.restart();
                    }
                    // 3. Failed transaction -> Return
                    this.config.onError('paypal transaction failed!');
                    this.config.onFinish();
                    return;
                }

                // 4.Other non-recoverable errors -> Show a failure message
                const resDataDetail = JSON.parse(resDataObj._content.detail);
                const errorDetail = Array.isArray(resDataDetail.details) && resDataDetail.details[0];
                if (errorDetail) {
                    let msg = 'Sorry, your transaction could not be processed.' + errorDetail.issue;
                    if (errorDetail.description) msg += '\n\n' + errorDetail.description;
                    if (resDataDetail.debug_id) msg += ' (' + resDataDetail.debug_id + ')';
                    this.config.onError(msg);
                    this.config.onFinish();
                }
            });
    }
}

export default PaypalService;
