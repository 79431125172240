import { Domain } from '@/config/domain/type';

export const trademarksOnlineBenelux: Domain = {
    domain: import.meta.env.VITE_TMO_BX_DOMAIN,
    shortCode: 'TMO-BX',
    title: 'Apply for your Benelux trademark',
    siteName: 'Trademarks Benelux',
    description: 'Our online trademark registration platform is available 24/7 and takes just minutes. Apply for your Benelux trademark by entering your trademark or logo.',
    headerColor: '#fff',
    footerColor: 'grey-dark',
    mobileMenuColor: '#505050',
    contactFlagImgUrl: '',
    menuItems: [
        {
            label: 'Home',
            url: 'https://www.trademarksonline.be/',
            child: []
        }, {
            label: 'Pricing',
            url: 'https://www.trademarksonline.be/pricing',
            child: []
        }, {
            label: 'FAQ',
            url: 'https://www.trademarksonline.be/faq',
            child: []
        }, {
            label: 'About us',
            url: 'https://www.trademarksonline.be/about-us',
            child: []
        }
    ],
    headerImageUrl: 'https://images.squarespace-cdn.com/content/66412d0c5faacc56469e658a/1715547424319-EZLJVHQT5IJHQLN90VJG/Asset+103.png',
    headerImageTitle: 'Trademarks Online Benelux',
    bannerImageUrl: 'https://images.squarespace-cdn.com/content/66412d0c5faacc56469e658a/fded38eb-4150-4fdf-a162-b4b0fa4bde5d/banner_app_process.png',
    termOfUseUrl: 'http://www.trademarksonline.be/terms-of-use',
    declarationTitle: 'I confirm that',
    declarationList: [
        'The applicant intends to use, and is entitled to own, the trademark',
        'The TM Office will not let me change the trademark or goods and services after it has been submitted'
    ],
    gtmId: 'GTM-N6LJGWQ5',
};
