<template>
    <button type="button" :disabled="disabled || loading" :class="classes">
        <div class="stf-button__icon-wrapper" v-if="icon">
            <stf-icon v-if="loading" type="Loading" :small="small" class="stf-spinner"/>
            <stf-icon v-else :type="iconName" :small="small"/>
        </div>
        <div v-else class="stf-button__text">
            <div v-if="loading" class="stf-button__loading"><stf-icon type="Loading" :small="small" class="stf-spinner"/></div>
            <slot>{{ label }}</slot>
        </div>
    </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import StfIcon from '@/ui/icon/stf-icon.vue';

export default defineComponent({
    name: 'stf-button',
    components: {
        StfIcon
    },
    props: {
        label: {
            type: String,
            default: '',
        },
        color: {
            type: String,
            default: 'default',
            description: 'color of the button'
        },
        small: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        icon: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        },
        rounded: {
            type: Boolean,
            default: false
        },
        iconName: {
            type: String,
        },
        loading: {
            type: Boolean,
            default: false
        },
        full: {
            type: Boolean,
            default: false
        },
        noWrapper: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        shapeClass(): string {
            if (this.outlined) {
                return 'stf-button--outlined';
            }

            return 'stf-button--contained';
        },
        classes(): string[] {
            const myClasses = [
                'stf-button',
                this.shapeClass,
                `stf-button--${this.color}`,
            ];

            if(this.small) {
                myClasses.push('button-2');
                myClasses.push('stf-button--small');
            } else {
                myClasses.push('button-1');
            }

            if(this.full) {
                myClasses.push('stf-button--full');
            }

            if(this.disabled) {
                myClasses.push('stf-button--disabled');
            }

            if(this.rounded) {
                myClasses.push('stf-button--rounded');
            }

            if(this.loading) {
                myClasses.push('stf-button--loading');
            }

            if (this.icon) {
                myClasses.push('stf-button--icon');
            }

            if(this.noWrapper) {
                myClasses.push('stf-button--no-wrapper');
            }

            return myClasses;
        }
    }
});
</script>

<style lang="sass">
@import './button'
</style>
