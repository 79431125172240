<template>
    <div />
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { useLoadCart } from '@/composables/useLoadCart';
import { useRouter } from 'vue-router';
import RouteName from '@/router/route-name';

const router = useRouter();

onMounted(async () => {
    const isLoadedCart = await useLoadCart();

    if(isLoadedCart) {
        // Url query parameters are passed through because of google UTM query parameters
        router.push({name: RouteName.PAYMENT, query: router.currentRoute.value.query});
    } else {
        router.push({name: RouteName.MARK});
    }
});
</script>
