<template>
    <div>
        <h2>Password reset/create email sent</h2>
        <div>
            <p>
                If an account matching your email exists, then an email was just sent that contains a link that you can use to create / reset your password.
            </p>
            <p>
                If you don't receive an email please:
            </p>
            <ul>
                <li>check that the email addressed used matches the one you provided to us with your trademark order,</li>
                <li>check your spam folder, or</li>
                <li> <a href="#" @click="retryResetPassword">try again</a>.</li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'sentEmail',
    emits: ['retrySendEmail'],
    methods: {
        retryResetPassword() {
            this.$emit('retrySendEmail');
        }
    }
});
</script>
