import { RootState } from '.';
import {ActionContext, Module} from 'vuex';
import { MarkContactInterface } from '@/core/interface/api-interface';
import Owner from '@/core/model/owner';
import {
    MUT_SET_POSTAL_ADDRESS_SELECTED,
    MUT_SET_SECOND_PERSON_SELECTED,
    MUT_SET_EMAIL,
    MUT_SET_FIRST_NAME,
    MUT_SET_LAST_NAME,
    MUT_RESET,
    MUT_LOAD_STATE
} from '@/store/contact-mutation';

export interface ContactState {
    hasSecondPersonSelected: boolean;
    hasPostalAddressSelected: boolean;
    email: string;
    firstName: string;
    lastName: string;
}

const contact: Module<ContactState, RootState> = {
    namespaced: true,
    state: {
        hasSecondPersonSelected: false,
        hasPostalAddressSelected: true,
        email: '',
        firstName: '',
        lastName: ''
    },
    getters: {
        hasSecondPersonSelected: ({ hasSecondPersonSelected }) => hasSecondPersonSelected,
        hasPostalAddressSelected: ({ hasPostalAddressSelected }) => hasPostalAddressSelected,
        getFirstName: (state, getters, rootState, rootGetters): string | null => {
            if (state.firstName !== '') {
                return state.firstName;
            }

            const owner = rootGetters['owners/getFirstOwner'] as Owner | null;

            if (owner === null || owner.firstName === '') {
                return null;
            }

            return owner.firstName;
        },
        getLastName: (state, getters, rootState, rootGetters): string | null => {
            if (state.lastName !== '') {
                return state.lastName;
            }

            const owner = rootGetters['owners/getFirstOwner'] as Owner | null;

            if (owner === null || owner.lastName === '') {
                return null;
            }

            return owner.lastName;
        },
        getEmail: (state, getters, rootState): string => {
            return !state.email ? rootState.ownerDetails.email : state.email;
        },
        getPhone: (state, getters, rootState): string | null => {
            if (rootState.ownerDetails.phone !== '') {
                return rootState.ownerDetails.phone;
            }

            return null;
        },
        getName: (state, getters, rootState, rootGetters): string => {
            if(state.firstName !== '' || state.lastName !== '') {
                return state.firstName + ' ' + state.lastName;
            }

            const owner = rootGetters['owners/getFirstOwner'] as Owner | null;
            if (owner !== null) {
                return owner.name;
            }

            return '';
        },
        getMarkContactDataToSubmit(state, getters): MarkContactInterface {
            return {
                firstName: getters.getFirstName,
                lastName: getters.getLastName,
                email: getters.getEmail,
                phone: getters.getPhone,
            };
        }
    },
    actions: {
        setHasSecondPersonSelected(context: ActionContext<ContactState, RootState>, val: boolean) {
            context.commit(MUT_SET_SECOND_PERSON_SELECTED, val);
        },
        setHasPostalAddressSelected(context: ActionContext<ContactState, RootState>, val: boolean) {
            context.commit(MUT_SET_POSTAL_ADDRESS_SELECTED, val);
        },
        setEmail(context: ActionContext<ContactState, RootState>, email: string) {
            context.commit(MUT_SET_EMAIL, email);
        },
        setContactName(context: ActionContext<ContactState, RootState>, contactName: string) {
            let firstName = null;
            let lastName = null;
            // Trim whitespace before comparing and splitting
            const contactNameTrimmed = contactName.trim()

            const owners = context.rootGetters['owners/owners'] as Owner[];
            owners.forEach(owner => {
                if (owner.name === contactNameTrimmed) {
                    firstName = owner.firstName;
                    lastName = owner.lastName;
                }
            })

            if (firstName === null) {
                const indexOfFirstSpace = contactNameTrimmed.indexOf(' ');
                if (indexOfFirstSpace === -1) {
                    firstName = contactNameTrimmed;
                    lastName = '';
                } else {
                    firstName = contactNameTrimmed.substr(0, indexOfFirstSpace).trim();
                    lastName = contactNameTrimmed.substr(indexOfFirstSpace).trim();
                }
            }

            context.commit(MUT_SET_FIRST_NAME, firstName);
            context.commit(MUT_SET_LAST_NAME, lastName);
        },
        reset({ commit }) {
            commit(MUT_RESET);
        },
        loadState({ commit }, state) {
            commit(MUT_LOAD_STATE, state);
        },
    },
    mutations: {
        [MUT_SET_SECOND_PERSON_SELECTED](state, value: boolean) {
            state.hasSecondPersonSelected = value;
        },
        [MUT_SET_POSTAL_ADDRESS_SELECTED](state, value: boolean) {
            state.hasPostalAddressSelected = value;
        },
        [MUT_SET_EMAIL](state, email: string) {
            state.email = email.trim();
        },
        [MUT_SET_FIRST_NAME](state, firstName: string) {
            state.firstName = firstName;
        },
        [MUT_SET_LAST_NAME](state, lastName: string) {
            state.lastName = lastName;
        },
        [MUT_RESET](state) {
            state.hasSecondPersonSelected = false;
            state.hasPostalAddressSelected = true;
            state.email = '';
            state.firstName = '';
            state.lastName = '';
        },
        [MUT_LOAD_STATE](state: ContactState, stateToLoad: ContactState) {
            Object.entries(stateToLoad).forEach(property => {
                const propertyName = property[0] as keyof ContactState;

                (state[propertyName] as any) = property[1];
            });
        }
    }
};

export default contact;
