import { Country } from '@/config/country/type';
import { useI18n } from 'vue-i18n';

export default function useInternalization(country: Country) {
    const { t, locale } = useI18n();

    locale.value = 'en_' + country.code;

    return { trans: t };
}
