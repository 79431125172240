import { Country } from '@/config/country/type';

export const loadPaypalScriptFile = (country: Country | null): void => {
    if (!country) {
        return;
    }
    const clientId = country.displayCode === 'AU' ? import.meta.env.VITE_PAYPAL_CLIENT_ID_AU : import.meta.env.VITE_PAYPAL_CLIENT_ID;
    const paypalScript = document.createElement('script')
    paypalScript.setAttribute('src', `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=${country.currencyName}`)
    document.head.appendChild(paypalScript)
};
