<template>
    <tmo-group-footer-replica :footer-menu="footerMenu"></tmo-group-footer-replica>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import TmoGroupFooterReplica from '@/header-footer-replicas/common/footers/tmo-group-footer-replica.vue';

export default defineComponent({
    name: 'TmoNzFooterReplica',
    components: { TmoGroupFooterReplica },
    data() {
        return {
            footerMenu: {
                imageUrl: 'https://images.squarespace-cdn.com/content/v1/62afc363f951920a266e3af0/3e2a24d9-f5bb-4e74-8684-acdf91bd1c92/logo-horizontal%2B%281%29.png',
                countryName: 'New Zealand',
                tmoCountries: [],
                contactUrl: 'https://www.trademarksonline.nz/contact',
                termsUrl: 'https://www.trademarksonline.nz/terms-of-use',
                privacyUrl: 'https://www.trademarksonline.nz/privacy-policy',
                phone: '+64(9) 888 0540'
            }
        }
    }
});

</script>
