<template>
    <stf-declaration 
        v-model="value"
        :name="name"
        :error-message="errorMessage"
        v-bind="$attrs"
    />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useField } from 'vee-validate';
import StfDeclaration from '@/ui/declaration/stf-declaration.vue';

export default defineComponent({
    name: 'StfFormDeclaration',
    components: {
        StfDeclaration
    },
    props: {
        name: {
            type: String,
            required: true
        },
        rules: {
            default: undefined
        },
        validationOnFly: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const { errorMessage, value } = useField(props.name, props.rules, { validateOnValueUpdate: props.validationOnFly });

        return {
            errorMessage,
            value
        };
    }
});

</script>