<template>
    <template v-for="(field, name) in fields" :key="name">
        <stf-form-field :field="field" :validation-on-fly="validationOnFly"/>
    </template>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { FormFields, InitFormValues} from './type';
import StfFormField from './stf-form-field.vue';
import useStfForm from './formable';

export default defineComponent({
    name: 'StfFormBuilder',
    components: {
        StfFormField
    },
    props: {
        fields: {
            type: Object as PropType<FormFields>,
            required: true
        },
        validation: {
            type: Object,
            required: true
        },
        initialValues: {
            type: Object as PropType<InitFormValues>,
            required: true
        },
        validationOnFly: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const {isValid, getData} = useStfForm(props.validation, props.initialValues, props.fields);

        return {
            isValid,
            getData
        };
    }
});
</script>
