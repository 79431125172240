<template>
    <div class="flexbox-wrapper">
        <div v-if="!navbarInViewport" class="nav-bar sticky-nav-bar">
            <div v-if="!$screen.isMobile" class="columns text-nowrap flex-wrap is-centered">
                <navbar-menu-item v-for="(menu, index) in menuItems" :key="index" :menu="menu"/>
            </div>
            <div v-else class="nav-bar-mobile" >
                <navbar-mobile-menu :menuItems="menuItems"/>
            </div>
        </div>
        <header class="header-container">
            <div class="diy-nz-header" @click="onImageClick">
                <img
                    class="diy-nz-header-image"
                    loading="lazy"
                    src="https://images.squarespace-cdn.com/content/v1/57cfbc9cebbd1adbaf20a7f1/4296e8a5-6068-44c7-908b-a5331e5e5899/diy_ban_surfman.jpg"
                    alt="" title="DIY Trademarks New Zealand"
                >
            </div>
            <div class="nav-bar" id="nav">
                <div v-if="!$screen.isMobile" class="columns text-nowrap flex-wrap is-centered">
                    <navbar-menu-item v-for="(menu, index) in menuItems" :key="index" :menu="menu"/>
                </div>
                <div v-else class="nav-bar-mobile" >
                    <navbar-mobile-menu :menuItems="menuItems"/>
                </div>
            </div>
        </header>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import NavbarMenuItem from '../../common/headers/navbar-menu-item.vue'
import NavbarMobileMenu from '../../common/headers/navbar-mobile-menu.vue'

export default defineComponent({
    name: 'DiyNzHeaderReplica',
    components: { NavbarMenuItem, NavbarMobileMenu },
    data() {
        return {
            menuItems: [
                {
                    label:'home',
                    url: 'https://www.diytm.co.nz/',
                    child: []
                },{
                    label:'need to know',
                    url: 'https://www.diytm.co.nz/how-to-get-a-trademark',
                    child: []
                },{
                    label:'apply',
                    url: '/nz/trade-mark',
                    child: []
                },{
                    label:'pricing',
                    url: 'https://www.diytm.co.nz/trademark-cost',
                    child: []
                },{
                    label:'faq',
                    url: 'https://www.diytm.co.nz/trademark-questions',
                    child: []
                },{
                    label:'testimonials',
                    url: 'https://www.diytm.co.nz/testimonials',
                    child: []
                },{
                    label:'about us',
                    url: 'https://www.diytm.co.nz/about-us',
                    child: []
                },{
                    label:'blog',
                    url: 'https://www.diytm.co.nz/blog',
                    child: []
                }
            ],
            navbarInViewport: true
        };
    },
    methods: {
        onImageClick() {
            location.replace('https://www.diytm.co.nz/');
        },
        isNavbarInViewport() {
            const mainNavbar = document.getElementById('nav');
            if (mainNavbar) {
                const navbar = mainNavbar.getBoundingClientRect();
                this.navbarInViewport = (navbar.top >= 0);
            }
        }
    },
    created () {
        window.addEventListener('scroll', this.isNavbarInViewport);
    },
    unmounted () {
        window.removeEventListener('scroll', this.isNavbarInViewport);
    }
});
</script>

<style scoped>

@media only screen and (max-width: 760px) {
  .header-container {
    height: auto;
    margin: auto;
  }
}

@media only screen and (max-width: 960px) {
  .header-container {
    padding: 5% 50px 0;
  }
}

@media only screen and (min-width: 760px) {
  .nav-bar {
    padding: 40px 0;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
  }
}

.flexbox-wrapper {
  display: flex;
}

.header-container {
  display: block;
  max-width: 1100px;
  margin: 0 auto 30px;
  padding-top: 12px;
  position: relative;
  text-align: center;
}

.diy-nz-header {
  display: grid;
  width: 100%;
  cursor: pointer;
}

.diy-nz-header-image {
  width: 100%;
  margin: auto;
}

.nav-bar {
  margin-top: 25px;
  height: auto;
  width: 100%;
}

.nav-bar-mobile {
  border: 1px solid black;
  padding: 5px 5px !important;
}

.sticky-nav-bar {
  position: -webkit-sticky;
  position: fixed;
  top: 0;
  align-self: flex-start;
  z-index: 10 !important;
  margin-top: unset;
  background: rgb(255, 255, 255);
}

</style>
