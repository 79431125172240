import { reactive } from 'vue';
import {Config, Screen} from './type';

const DEFAULT_TABLET_WIDTH = 760;

export default function useScreen(config: Config = {}): Screen {
    const tabletWidth = config.tabletWidth || DEFAULT_TABLET_WIDTH;

    const screen = reactive({
        isMobile: false
    });
    
    let timeout: number;
    const updateScreen = () => {
        clearTimeout(timeout);
        timeout = window.setTimeout(() => {
            screen.isMobile = window.innerWidth < tabletWidth;
        }, 300);
    }

    window.addEventListener('resize', updateScreen);

    updateScreen();
    
    return screen;
}
