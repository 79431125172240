import { Country } from '@/config/country/type';
import { Domain } from '@/config/domain/type';

const isCountryWithTheme = function (country: Country | null): boolean {
    if (country === null || !country.theme) {
        return false;
    }

    return true;
};

const injectThemeToHtmlDocument = function (country: Country): void {
    const date = Date.now();
    const cssFile = `/css/${country.displayCode.toLowerCase()}.css?id=${date}`;

    const styleTage = document.createElement('link') as HTMLLinkElement;
    styleTage.rel = 'stylesheet';
    styleTage.href = cssFile;

    const head = document.head || document.getElementsByTagName('head')[0];

    head.appendChild(styleTage);
};

const isTrademarkplanet = function (siteName: string) {
    return siteName === 'trademarkplanet';
};

export const loadCountryTheme = function (country: Country | null, siteName: string): void {
    if (isCountryWithTheme(country) && isTrademarkplanet(siteName)) {
        injectThemeToHtmlDocument(country as Country);
    }
};

export const setTheme = function (domain: Domain): void {
    const app = document.getElementById('tmp-app') as HTMLElement;

    app.className = domain.shortCode;
};
