<template>
    <header class="header-container">
        <div class="row second-row justify-content-between">
            <div class="col-9 col-sm-6 col-md-6 col-lg-3 d-flex align-items-center" @click="onLogoClick">
                <img style="width: 291px" src="/img/tmp-uk-logo.png" alt="trademark planet UK">
            </div>
            <div class="d-none d-sm-none d-lg-flex col-2 col-sm-4 col-md-4 col-lg-7 justify-content-between align-items-center">
                <tmp-uk-menu-item v-for="(menu, index) in menuItems" :key="index" :menu="menu"/>
            </div>
            <div class="d-flex d-sm-flex d-lg-none col-2 col-sm-4 col-md-4 justify-content-end align-items-center">
                <tmp-uk-mobile-menu :menuItems="menuItems"/>
            </div>
        </div>
    </header>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import TmpUkMenuItem from './tmp-uk-menu-item.vue'
import TmpUkMobileMenu from './tmp-uk-mobile-menu.vue'

export default defineComponent({
    name: 'TmpUkHeaderReplica',
    components: { TmpUkMenuItem, TmpUkMobileMenu },
    data() {
        return {
            menuItems: [
                {
                    label:'What to trademark',
                    url: 'https://www.trademarkplanet.com/uk/trademark-your-brand-name/',
                    child: [
                        {
                            label: 'Your brand name',
                            url: 'https://www.trademarkplanet.com/uk/trademark-your-brand-name/'
                        },{
                            label: 'Your logo',
                            url: 'https://www.trademarkplanet.com/uk/trademark-your-logo/'
                        },{
                            label: 'Your business name',
                            url: 'https://www.trademarkplanet.com/uk/trademark-your-business-name/'
                        }
                    ]
                },{
                    label:'How it works',
                    url: 'https://www.trademarkplanet.com/uk/how-it-works-step1-your-trademark/',
                    child: [
                        {
                            label: 'Step 1 – Your brand',
                            url: 'https://www.trademarkplanet.com/uk/how-it-works-step1-your-trademark'
                        },{
                            label: 'Step 2 – Your products',
                            url: 'https://www.trademarkplanet.com/uk/how-it-works-step2-your-products/'
                        },{
                            label: 'Step 3 – The Owner',
                            url: 'https://www.trademarkplanet.com/uk/how-it-works-step3-the-owner/'
                        },{
                            label: 'Step 4 – Check out',
                            url: 'https://www.trademarkplanet.com/uk/how-it-works-step4-checkout/'
                        },{
                            label: 'After you apply',
                            url: 'https://www.trademarkplanet.com/uk/after-you-apply/'
                        }
                    ]
                },{
                    label:'pricing',
                    url: 'https://www.trademarkplanet.com/uk/pricing/',
                    child: []
                },{
                    label:'about us',
                    url: 'https://www.trademarkplanet.com/uk/about/',
                    child: [
                        {
                            label: 'about us',
                            url: 'https://www.trademarkplanet.com/uk/about/'
                        },{
                            label: 'Contact us',
                            url: 'https://www.trademarkplanet.com/uk/contact/'
                        }
                    ]
                }
            ]
        };
    },
    methods: {
        onLogoClick() {
            location.replace('https://www.trademarkplanet.com/uk/');
        }
    }
});
</script>

<style scoped>
.header-container {
	padding: 0px 5%;
}

.flag {
	padding-top: 4px;
}
.logo {
	max-width: 100%;
	height: auto;
	cursor: pointer;
}

@media (max-width: 980px) {
	.first-row {
		height: 60px;
	}
}

@media (min-width: 981px) {
	.first-row {
		height: 37px;
	}
}

@media only screen and (min-width: 1350px) {
	.first-row {
		height: 54px;
	}
}

.second-row {
	min-height: 71px;
}
</style>
