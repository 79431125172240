import portalApiClient from './client/portal-api';
import { ApplicationInterface } from '../interface/api-interface';
import { RequestResponse } from '../http-module/http-type';

type applicationAmountData = {
    applicationRef: string;
    mark: {
        markGoods: any;
    };
};

const application = {
    async sync(data: ApplicationInterface, countryCode: string): Promise<RequestResponse> {
        return await portalApiClient.post(countryCode + '/application', data);
    },

    async applicationAmount(data: applicationAmountData, countryCode: string): Promise<RequestResponse> {
        return await portalApiClient.post(countryCode + '/application-amount', data);
    }
};

export default application;
