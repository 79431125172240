<template>
    <tmp-group-footer-replica :footer-menu="footerMenu"></tmp-group-footer-replica>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import TmpGroupFooterReplica from '@/header-footer-replicas/common/footers/tmp-group-footer-replica.vue';

export default defineComponent({
    name: 'DiyNzFooterReplica',
    components: { TmpGroupFooterReplica },
    data() {
        return {
            footerMenu: {
                termsLink: 'https://www.diytm.co.nz/terms-conditions',
                privacyLink: 'https://www.diytm.co.nz/privacy-policy',
                contactLink: 'https://www.diytm.co.nz/contact',
                contactNumber: '+64 (09) 888 0540'
            }
        }
    }
});

</script>
