<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-sm-12 col-lg-10 col-xl-8 ml-5">
                <div v-if="loading" class="has-text-centered pt-6">
                    <stf-heading-two>Signing you in, please wait..</stf-heading-two>
                    <progress class="progress is-primary" max="100"/>
                </div>
                <div v-else>
                    <stf-heading-one>Please sign in</stf-heading-one>
                    <form
                        @submit.prevent="login"
                        class="mt-5"
                    >
                        <stf-input
                            class="mb-4"
                            v-model="email"
                            name="email"
                            label="email"
                            type="email"
                            required
                        />

                        <stf-input
                            class="mb-4"
                            v-model="password"
                            name="password"
                            label="password"
                            type="password"
                            required
                        />

                        <div class="mb-5">
                            <a
                                class="mr-6"
                                href="#"
                                @click.prevent.stop="resetPassword"
                            >
                                Forgot password?
                            </a>

                            <a
                                href="#"
                                @click.prevent.stop="resetPassword"
                            >
                                Create  password (new users)
                            </a>
                        </div>

                        <div v-if="errorMessage" class="error mb-4">
                            {{ errorMessage }}
                        </div>

                        <div v-if="successMessage" class="success mb-4">
                            {{ successMessage }}
                        </div>

                        <stf-button
                            type="submit"
                            color="primary-console"
                        >
                            Login
                        </stf-button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import HttpResponse from '@/core/http-module/http-response';
import HttpError from '@/core/http-module/error/http-error';
import authentication from '@/core/authentication/authentication';
import RouteName from '@/router/route-name';
import StfButton from '@/ui/button/stf-button.vue';
import stfInput from '@/ui/input/stf-input.vue';

export default defineComponent({
    name: 'Login',
    components: {
        StfButton,
        stfInput
    },
    data() {
        return {
            email: '',
            password: '',
            errorMessage: '',
            successMessage: ''
        }
    },
    computed: {
        ...mapGetters('authentication', ['loading']),
    },
    mounted() {
        this.checkChangePasswordSuccess();
        this.setLoading(false);
    },
    methods: {
        ...mapActions('authentication', ['setLoading']),
        async login() {
            this.reset();
            const response = await authentication.login({
                email:this.email, 
                password:this.password
            });

            if(
                (response instanceof HttpResponse && response.status !== 200) || 
                response instanceof HttpError
            ) {   
                this.errorMessage = 'Unable to log in. Please verify your email and password are correct or try again later.';
                this.setLoading(false);
                return;
            }
            this.$router.push({name: RouteName.TRADEMARKS});       
        },
        resetPassword() {
            this.$router.push({name: RouteName.RESET_PASSWORD});
        },
        checkChangePasswordSuccess() {
            if(this.$route.params.changePasswordSuccess) {
                this.successMessage = 'Your password was successfully changed, please sign in with your new password.';
            }
        },
        reset() {
            this.errorMessage = '';
            this.successMessage = '';
            this.setLoading(true);
        }
    }
});
</script>