import HttpLoggedClientDecorator from '@/core/http-module/http-logged-client-decortor';
import HttpClientAdapter from '@/core/http-module/http-client-adapter';
import logger from '@/core/logger/logger';
import { RequestResponse } from '@/core/http-module/http-type';

export class PortalApiClient {
    private static instance: PortalApiClient;
    private client: HttpLoggedClientDecorator;

    private constructor() {
        const httpClientAdapter = new HttpClientAdapter(window.location.origin + '/api');

        this.client = new HttpLoggedClientDecorator(httpClientAdapter, logger);
    }

    async get(url: string): Promise<RequestResponse> {
        return await this.client.get(url);
    }

    async post(url: string, data: any): Promise<RequestResponse> {
        return await this.client.post(url, data);
    }

    async postForm(url: string, data: FormData): Promise<RequestResponse> {
        return await this.client.post(url, data, { timeout: 90000 });
    }

    static getInstance() {
        if (!PortalApiClient.instance) {
            this.instance = new PortalApiClient();
        }

        return this.instance;
    }
}

export default PortalApiClient.getInstance();
