<template>
    <svg viewBox="0 0 24 24">
        <path fill="currentColor" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
    </svg>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'stf-loading',
});
</script>
