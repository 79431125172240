import { WorkflowStep } from '../workflow/steps';

export interface Country {
    name: string;
    code: CountryCode;
    displayCode: string;
    combinedDisplayCode: string;
    workflow: WorkflowStep[];
    makeMethod: string;
    theme: boolean;
    currency: string;
    currencyName: string;
    currencyWithCountry: string;
    maxImageSize: number;
    maxGoodNumberPerClass: number;
    paymentMethodsImageName: string;
    stripeKey: string | undefined;
    autoSelectedInForm: boolean;
}

export const enum CountryCode {
    AU = 'AU',
    UK = 'UK',
    GB = 'GB',
    NZ = 'NZ',
    SG = 'SG',
    MY = 'MY',
    BX = 'BX',
    BENELUX = 'BENELUX',
    BE = 'BE',
    NL = 'NL',
    LU = 'LU',
    HK = 'HK',
}
