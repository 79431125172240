<template>
    <select class="navbar" id="tmo-uk-mobile-dropdown" name="tmo-uk-mobile-dropdown" @change="onChange">
        <option v-for="(menu, index) in menuItems" :key="index" :value="menu.url" :selected="menu.label === 'apply'">{{menu.label}}</option>
    </select>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'NavbarMobileMenu',
    props: {
        menuItems: {
            type: Array,
            required: true
        }
    },
  methods: {
      onChange(event: Event) {
        window.open(event.target.value, '_blank');
      }
  }
});
</script>

<style scoped>

.navbar {
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 2px;
  display: inline-block;
  vertical-align: middle;
  line-height: 1em;
  font-size: 16px;
  color: #5fad2a;
  margin: 0;
  border: solid 0px transparent;
  background-color: transparent;
  border-radius: 0;
  text-indent: .1px;
  outline: none;
  position: relative;
  z-index: 1;
  width: 100%;
}


</style>
