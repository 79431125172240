import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import useCountry from '@/composables/countryDependable';
import completeCart from '@/core/api/cart';
import { ApplicationState } from '@/store/application';
import { GoodState } from '@/store/good';
import { OwnersState } from '@/store/owners';
import { ContactState } from '@/store/contact';
import { MarkState } from '@/store/mark';
import { MarkOwnerDetailInterface } from '@/core/interface/api-interface';
import {MarkOwnerType} from '@/core/interface/api-interface';

export const useLoadCart = async (): Promise<boolean> => {
    const route = useRoute();
    const store = useStore();
    const country = useCountry();
    const countryCode = country.displayCode.toLowerCase();
    const cartRef = route.params.cartRef?.toString();

    try {
        const response = await completeCart.load(countryCode, cartRef);
        const { mark, applicationRef } = response;

        store.dispatch('application/loadState', computeAppRefs(cartRef, applicationRef));
        store.dispatch('mark/loadState', computedMark(mark));
        store.dispatch('good/loadState', computeGoods(mark));
        store.dispatch('ownerDetails/loadState', computeOwnerDetails(mark));
        store.dispatch('owners/loadState', computeOwnerInfos(mark));
        store.dispatch('contact/loadState', computePaymentInfos(mark));
        store.dispatch('workflow/loadState', country.workflow);
        store.dispatch('good/updatePrice', countryCode);
        return true;
    } catch (e) {
        store.dispatch('resetApplication');
        return false;
    }
}

const computeAppRefs = (cartRef: string, applicationRef: string): ApplicationState => {
    return {
        ref: applicationRef,
        cartRef: cartRef,
        paymentType: '',
        paymentRef: '',
        paymentSessionId: '',
    };
};

const computedMark = (mark: any): MarkState => {
    const markWord = mark.markWord?.word;
    const markImage = mark.markImage ? `/content/image/${mark.markImage?.originalFilePath}` : '';
    const markImageRef = mark.markImage?.imageRef;
    return {
        trademarkWord: markWord,
        trademarkImage: markImage,
        trademarkImageRef: markImageRef,
    }
};

const computeGoods = (mark: any): GoodState => {
    return {
        goods: [...mark.markGoods],
        goodsPrice:  {
            application: null,
            extraClassFees: null,
            ref: null,
            total: null
        }
    };
};

const computeOwnerDetails = (mark: any): MarkOwnerDetailInterface => {
    return {
        address: mark.markOwnerDetail?.address,
        city: mark.markOwnerDetail?.city,
        suburb: mark.markOwnerDetail?.suburb,
        postCode: mark.markOwnerDetail?.postCode,
        region: mark.markOwnerDetail?.region,
        countryCode: mark.markOwnerDetail?.countryCode,
        email: mark.markContact?.email,
        phone: mark.markContact?.phone,
        declaration: true,
    };
};

const computeOwnerInfos = (mark: any): OwnersState => {
    const firstOwner = mark.markOwners[0];
    const lastName = firstOwner?.lastName ? firstOwner.lastName : '';
    const ownerName = firstOwner?.firstName ? `${firstOwner?.firstName} ${lastName}` : firstOwner?.businessName;
    return  {
        ownerType: firstOwner?.markOwnerType?.name === 'Person' ? MarkOwnerType.PERSON : MarkOwnerType.BUSINESS,
        ownerName: firstOwner?.firstName ? ownerName.trim() : firstOwner?.businessName,
        secondOwnerName: '',
        companyNumber: firstOwner?.companyNumber,
        ownerCountryCode: firstOwner?.citizenCountryCode
    };
};

const computePaymentInfos = (mark: any): ContactState => {
    const firstOwner = mark.markOwners[0];
    return {
        hasSecondPersonSelected: false,
        hasPostalAddressSelected: true,
        email: mark.markContact?.email,
        firstName: mark.markContact?.firstName ? mark.markContact?.firstName : firstOwner?.businessName,
        lastName: mark.markContact?.lastName ? mark.markContact?.lastName : '',
    };
};

