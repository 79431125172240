import { mark, goods, contact, payment } from './steps';

const defaultWorkflow = [
    mark,
    goods,
    contact,
    payment,
];

export const australiaWorkflow = defaultWorkflow;
export const unitedKingdomWorkflow = defaultWorkflow;
export const newZealandWorkflow = defaultWorkflow;
export const singaporeWorkflow = defaultWorkflow;
export const malaysiaWorkflow = defaultWorkflow;
export const beneluxWorkflow = defaultWorkflow;
export const hongKongWorkflow = defaultWorkflow;
