<template>
    <div class="stf-tooltip-box">
        <slot/>
        <div
            class="stf-tooltip"
        >
            <span
                class="stf-tooltip-html"
                v-html="html"
            />
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    props: {
        html: {
            type: String,
            required: true
        }
    }
});
</script>
