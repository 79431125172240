<template>
    <stf-col size="is-full">
        <stf-row isMobile>
            <stf-col :size="size" class="py-1">
                <stf-strong>
                    {{ label }}
                </stf-strong>
            </stf-col>
            <stf-col v-if="value" class="py-1">
                {{ value}}
            </stf-col>
            <stf-col v-else class="py-1">
                <slot />
            </stf-col>
        </stf-row>
    </stf-col>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'MarkDetailsSection',
    props: {
        label: {
            type: String,
            required: true
        },
        value: {
            type: String,
            required: false
        },
        alignVerticaly: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        size(): string {
            return this.alignVerticaly ? 'is-12' : 'is-4';
        }
    },
});
</script>