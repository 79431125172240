import GoodClass from './good-class';

export default class DrafterSearch {
    private _classes: Array<GoodClass>;

    constructor() {
        this._classes = [];
    }

    addClass(goodClass: GoodClass) {
        this._classes.push(goodClass);
    }

    get classes() {
        return this._classes;
    }
}
