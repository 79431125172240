import { 
    australiaWorkflow, 
    unitedKingdomWorkflow, 
    newZealandWorkflow, 
    singaporeWorkflow, 
    malaysiaWorkflow, 
    beneluxWorkflow, 
    hongKongWorkflow 
} from '@/config/workflow';
import StepComponent from './step.vue';
import StepperComponent from './stepper.vue';
import { h, VNode } from 'vue';
import { WorkflowStep } from '@/config/workflow/steps';

export class StepperDirector {
    [name: string]: (builder: StepperBuilder) => void;

    makeAustralia(builder: StepperBuilder) {
        builder.setSteps(australiaWorkflow);
    }

    makeSingapore(builder: StepperBuilder) {
        builder.setSteps(singaporeWorkflow);
    }

    makeNewZealand(builder: StepperBuilder) {
        builder.setSteps(newZealandWorkflow);
    }

    makeUnitedKingdom(builder: StepperBuilder) {
        builder.setSteps(unitedKingdomWorkflow);
    }
    makeMalaysia(builder: StepperBuilder) {
        builder.setSteps(malaysiaWorkflow);
    }
    makeBenelux(builder: StepperBuilder) {
        builder.setSteps(beneluxWorkflow);
    }
    makeHongKong(builder: StepperBuilder) {
        builder.setSteps(hongKongWorkflow);
    }
}

export class StepperBuilder {
    private _steps: VNode[];

    constructor() {
        this._steps = [];
    }

    setSteps(steps: WorkflowStep[]): void {
        steps.forEach((step) => {
            this._steps.push(h(StepComponent, {
                routeName: step.route,
                stepName: step.name
            }));
        });
    }

    getStepper() {
        return h(StepperComponent, this._steps);
    }
}
