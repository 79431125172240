export const MUT_RESET = 'MUT_RESET';
export const MUT_SET_ADDRESS = 'MUT_SET_ADDRESS';
export const MUT_SET_CITY = 'MUT_SET_CITY';
export const MUT_SET_SUBURB = 'MUT_SET_SUBURB';
export const MUT_SET_POSTCODE = 'MUT_SET_POSTCODE';
export const MUT_SET_REGION = 'MUT_SET_REGION';
export const MUT_SET_COUNTRY_CODE = 'MUT_SET_COUNTRY_CODE';
export const MUT_SET_EMAIL = 'MUT_SET_EMAIL';
export const MUT_SET_PHONE = 'MUT_SET_PHONE';
export const MUT_LOAD_STATE = 'MUT_LOAD_STATE';
export const MUT_SET_DECLARATION = 'MUT_SET_DECLARATION';
