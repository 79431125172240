import { Domain } from '@/config/domain/type';

export const diyNewZealand: Domain = {
    domain: import.meta.env.VITE_DIY_NZ_DOMAIN,
    shortCode: 'DIY-NZ',
    title: 'Apply for your NZ trademark',
    siteName: 'DIY Trademarks',
    description: 'Our online trademark registration platform is available 24/7 and takes just minutes. Apply for your NZ trademark by entering your trademark or logo.',
    headerColor: 'grey-dark',
    footerColor: 'grey-dark',
    mobileMenuColor: 'grey-dark',
    contactFlagImgUrl: '',
    menuItems: [{ label: '', url: '', child: [] }],
    headerImageUrl: '',
    headerImageTitle: '',
    bannerImageUrl: '',
    termOfUseUrl: '',
    declarationTitle: 'I confirm that',
    declarationList: [
        'The applicant intends to use, and is entitled to own, the trademark',
        'The TM Office will not let me change the trademark or goods and services after it has been submitted'
    ],
    gtmId: 'GTM-57R6JNG9',
};
