import domains from '@/config/domain';
import { Domain } from '@/config/domain/type';


export const getDomainFromHost = (host: string): Domain => {
    let domainFound = domains.find(domain => {
        return domain.domain === host;
    });

    if (!domainFound) {
        domainFound = {
            domain: 'defaultDomain',
            shortCode: 'default',
            title: 'Apply',
            siteName: 'Apply',
            description: 'apply',
            headerColor: '',
            footerColor: '',
            mobileMenuColor: '',
            contactFlagImgUrl: '',
            menuItems: [{ label: '', url: '', child: [] }],
            headerImageUrl: '',
            headerImageTitle: '',
            bannerImageUrl: '',
            termOfUseUrl: '',
            declarationTitle: '',
            declarationList: [],
            gtmId: 'GTM-57R6JNG9',
        };
    }

    return domainFound;
};
