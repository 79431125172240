<template>
    <div class="stf-header">
        <div class="stf-header-v3__nav-bar" :style="{'background-color': domain.headerColor}">
            <div class="stf-header-v3__nav-bar-inner">
                <slot />
            </div>
        </div>
        <div class="is-flex is-justify-content-center is-align-items-center m-0">
            <img
                class="stf-header-v3__banner-image"
                loading="eager"
                :src="domain.bannerImageUrl"
                alt=""
            >
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import useDomain from '@/composables/domainDependable';

export default defineComponent({
    name: 'StfHeader',
    setup() {
        const domain = useDomain();
        return {domain};
    },
});
</script>
