import Good, { GoodLike } from '@/core/model/good';
import { CheckoutLike } from '@/core/model/checkout';
import { ActionContext, Module } from 'vuex';
import { RootState } from '.';
import { MUT_ADD_GOOD, MUT_REMOVE_GOOD, MUT_RESET, MUT_UPDATE_GOOD_PRICE, MUT_LOAD_STATE } from './good-mutation';
import Checkout from '@/core/model/checkout';
import ApplicationCheckout from '@/core/model/application-checkout';
import { APIapplicationCheckout } from '@/core/api/payment';
import api from '@/core/api';
import HttpResponse from '@/core/http-module/http-response';
import { CountryCode } from '@/config/country/type';

export interface GoodState {
    goods: GoodLike[];
    goodsPrice: CheckoutLike;
}

const good: Module<GoodState, RootState> = {
    namespaced: true,
    state: {
        goods: [],
        goodsPrice: {
            application: null,
            extraClassFees: null,
            ref: null,
            total: null
        }
    },
    getters: {
        goods: (state): Good[] => {
            return state.goods.map(good => {
                return Good.hydrateGood(good);
            });
        },
        goodsInClass: state => (classNumber: string | number): Good[] => {
            if (typeof classNumber === 'string') {
                classNumber = parseInt(classNumber, 10);
            }

            return state.goods.filter(good => good.classNumber === classNumber).map(good => {
                return Good.hydrateGood(good);
            });
        },
        goodsPrice: (state): Checkout => {
            return Checkout.hydrateCheckout(state.goodsPrice);
        },
        goodClasses: (state): number[] => {
            return [...new Set(state.goods.map(good => good.classNumber))];
        },
        getGoodDataToSubmit(state) {
            return state.goods.map(good => {
                return Good.hydrateGood(good).apiData;
            });
        },
        getRef(state, getters, rootState, rootGetters) {
            return rootGetters['application/appReference'];
        }
    },
    mutations: {
        [MUT_LOAD_STATE](state, stateToLoad: GoodState) {
            Object.entries(stateToLoad).forEach(property => {
                const propertyName = property[0] as keyof GoodState;
                state[propertyName] = property[1];
            });
        },
        [MUT_ADD_GOOD](state, good: Good) {
            state.goods.push(good.getGoodLike());
        },
        [MUT_REMOVE_GOOD](state, goodIndex: number) {
            state.goods.splice(goodIndex, 1);
        },
        [MUT_UPDATE_GOOD_PRICE](state, goodPrice: CheckoutLike) {
            state.goodsPrice = goodPrice;
        },
        [MUT_RESET](state) {
            state.goods = [];
            state.goodsPrice = {
                application: null,
                extraClassFees: null,
                ref: null,
                total: null
            };
        }
    },
    actions: {
        loadState({ commit }, state) {
            commit(MUT_LOAD_STATE, state);
        },
        async updatePrice(context: ActionContext<GoodState, RootState>, countryCode: string) {
            if (!context.getters.getGoodDataToSubmit.length) {
                return;
            }

            const requestData = {
                applicationRef: context.getters.getRef,
                mark: {
                    markGoods: context.getters.getGoodDataToSubmit,
                }
            };

            const response = await api.application.applicationAmount(requestData, countryCode);

            if (!(response instanceof HttpResponse)) {
                return;
            }

            const amountData = response.content.data as APIapplicationCheckout;

            if (countryCode.toUpperCase() !== CountryCode.UK && amountData.classes.length <= 0) {
                throw new Error(`An error occur, get no application-amount from ${countryCode}/application-amount`);
            }

            const application = new ApplicationCheckout(
                amountData.classes,
                amountData.flatFee,
                amountData.extraClassFees,
                context.getters.getRef,
                amountData.subtotal,
                amountData.tax,
                amountData.total,
            );

            const checkout = new Checkout();

            checkout.init(application, context.getters.getRef, amountData.extraClassFees as unknown as string, amountData.total as unknown as string);

            context.commit(MUT_UPDATE_GOOD_PRICE, checkout.getCheckoutLike());
        },
        async addGood(context: ActionContext<GoodState, RootState>, data: {
            good: Good;
            countryCode: string;
        }) {
            const goodIndex = context.state.goods.findIndex(item => item.externalId === data.good.externalId);

            if (goodIndex > -1) {
                return;
            }

            const goodsInClass = context.getters.goodsInClass(data.good.classNumber).length;

            context.commit(MUT_ADD_GOOD, data.good);

            // update price only when the good is the first good to add in the class
            if (!goodsInClass) {
                await context.dispatch('updatePrice', data.countryCode);
            }
        },
        async removeGood(context: ActionContext<GoodState, RootState>, data: {
            good: Good;
            countryCode: string;
        }) {
            const goodIndex = context.state.goods.findIndex(item => item.externalId === data.good.externalId);

            if (goodIndex === -1) {
                return;
            }

            context.commit(MUT_REMOVE_GOOD, goodIndex);

            const goodsInClass = context.getters.goodsInClass(data.good.classNumber).length;
            // update price only when the good is the last one good to remove in the class
            if (!goodsInClass) {
                await context.dispatch('updatePrice', data.countryCode);
            }
        },
        removeGoodsInClass(context: ActionContext<GoodState, RootState>, data: {
            classNumber: string | number;
            countryCode: string;
        }) {
            const goods = context.getters.goodsInClass(data.classNumber) as Good[];

            goods.forEach(good => context.dispatch('removeGood', { good: good, countryCode: data.countryCode }));
        },
        reset({ commit }) {
            commit(MUT_RESET);
        }
    }
};

export default good;
