<template>
    <tmo-group-footer-replica :footer-menu="footerMenu"></tmo-group-footer-replica>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import TmoGroupFooterReplica from '@/header-footer-replicas/common/footers/tmo-group-footer-replica.vue';

export default defineComponent({
    name: 'TmoMyFooterReplica',
    components: { TmoGroupFooterReplica },
    data() {
        return {
            footerMenu: {
                imageUrl: 'https://images.squarespace-cdn.com/content/v1/62c0d51c8a6b3718402dff56/b16d2a97-dd83-48c0-bb9e-51f24cd148cf/Trademarks%2BMalaysia%2Bon%2Bwhite%2Bwith%2Bflag%2B1%2B%284%29.jpg',
                countryName: 'Malaysia',
                tmoCountries: [
                    {
                        name: 'Malaysia',
                        url: 'https://www.trademarksmalaysia.my/'
                    },                    
                    {
                        name: 'Singapore',
                        url: 'https://www.trademarksonline.com.sg/'
                    },                     
                    {
                        name: 'Australia',
                        url: 'https://www.trademarksonline.com.au/'
                    },
                    {
                        name: 'New Zealand',
                        url: 'https://www.diytm.co.nz/'
                    },
                    {
                        name: 'United Kingdom',
                        url: 'https://www.trademarksonline.co.uk/'
                    },
                ],
                contactUrl: 'https://www.trademarksmalaysia.my/contact',
                termsUrl: 'https://www.trademarksmalaysia.my/terms-of-use',
                privacyUrl: 'https://www.trademarksmalaysia.my/privacy-policy',
            }
        }
    }
});

</script>