
export default {
    // ref: https://sortifytm.atlassian.net/browse/DEV-1892
    install: (): void => {
        // add comment line
        const commentsStart = document.createComment('  Facebook Pixel Code for https://app.trademarkplanet.com ');
        document.head.appendChild(commentsStart);

        // add facebook pixel script
        const facebookPixelScript = document.createElement('script');
        facebookPixelScript.text += '!function(f,b,e,v,n,t,s)\n' +
            '{if(f.fbq)return;n=f.fbq=function(){n.callMethod?\n' +
            'n.callMethod.apply(n,arguments):n.queue.push(arguments)};\n' +
            'if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version=\'2.0\';\n' +
            'n.queue=[];t=b.createElement(e);t.async=!0;\n' +
            't.src=v;s=b.getElementsByTagName(e)[0];\n' +
            's.parentNode.insertBefore(t,s)}(window,document,\'script\',\n' +
            '\'https://connect.facebook.net/en_US/fbevents.js\');\n' +
            ' fbq(\'init\', \'267240458086932\');\n' +
            'fbq(\'track\', \'PageView\');';
        document.head.appendChild(facebookPixelScript);

        // add facebook pixel noscript
        const facebookPixelNoScript = document.createElement('noscript');
        const objImg = document.createElement('img');
        objImg.setAttribute('height', '1');
        objImg.setAttribute('width', '1');
        objImg.setAttribute('src', 'https://www.facebook.com/tr?id=267240458086932&ev=PageView&noscript=1');
        facebookPixelNoScript.appendChild(objImg);
        document.head.appendChild(facebookPixelNoScript);

        // add comment line
        const commentsEnd = document.createComment('  End Facebook Pixel Code for https://app.trademarkplanet.com ');
        document.head.appendChild(commentsEnd);
    }
};
