import { Domain } from '@/config/domain/type';

export const trademarksOnlineUnitedKingdom: Domain = {
    domain: import.meta.env.VITE_TMO_UK_DOMAIN,
    shortCode: 'TMO-UK',
    title: 'Apply for your UK trademark',
    siteName: 'Trademarks Online',
    description: 'Our online trademark registration platform is available 24/7 and takes just minutes. Apply for your UK trademark by entering your trademark or logo.',
    headerColor: '#012169',
    footerColor: '#012169',
    mobileMenuColor: '#012169',
    contactFlagImgUrl: '',
    menuItems: [
        {
            label: 'Home',
            url: 'https://www.trademarksonline.co.uk/',
            child: []
        }, {
            label: 'Pricing',
            url: 'https://www.trademarksonline.co.uk/pricing',
            child: []
        }, {
            label: 'FAQ',
            url: 'https://www.trademarksonline.co.uk/faq',
            child: []
        }, {
            label: 'Contact',
            url: 'https://www.trademarksonline.co.uk/contact',
            child: []
        }
    ],
    headerImageUrl: 'https://images.squarespace-cdn.com/content/v1/6226ec08a8758f252e060714/d2af155e-e901-4868-a6cf-8baf3d88b740/UKTMO%2Bwords%2Bwhite%2Bwith%2Bflag%2Bon%2Bblue%2B25052022.jpg',
    headerImageTitle: 'Trademarks Online United Kingdom',
    bannerImageUrl: 'https://images.squarespace-cdn.com/content/v1/6226ec08a8758f252e060714/e79f3983-4c5c-4ddc-958a-f95c13b5f1e3/UK%2BBanner%2Bupdated%2B20052022v3.jpg',
    termOfUseUrl: 'https://www.trademarksonline.co.uk/terms-of-use',
    declarationTitle: 'I confirm that',
    declarationList: [
        'The applicant intends to use, and is entitled to own, the trademark',
        'The TM Office will not let me change the trademark or goods and services after it has been submitted'
    ],
    gtmId: 'GTM-WJRDB52T',
};
