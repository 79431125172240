<template>
    <div v-if="show" class="modal fade show" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <slot name="header"/>
                    <img @click="close" class="close" src="/img/image-close.svg"  />
                </div>
                <div v-if="showBody" class="modal-body">
                    <slot name="body"/>
                </div>
                <div class="modal-footer border-0">
                    <slot name="footer"/>
                </div>
            </div>
        </div>
    </div>
    <div v-if="show" class="modal-backdrop fade show"></div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Modal',
    props: {
        modelValue: {
            type: Boolean,
            default: false
        },
        showBody: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            show: this.modelValue
        };
    },
    watch: {
        show(newValue) {
            this.$emit('update:modelValue', newValue);
        },
        modelValue(newValue) {
            this.show = newValue;
        }
    },
    methods: {
        close() {
            this.show = false;
        }
    }
});
</script>
