import Owner from '@/core/model/owner';
import {RootState} from '.';
import {Module} from 'vuex';
import {MarkOwnerInterface, MarkOwnerType} from '@/core/interface/api-interface';
import {
    MUT_ADD_COMPANY_NUMBER,
    MUT_ADD_OWNER_COUNTRY_CODE,
    MUT_ADD_OWNER_NAME,
    MUT_ADD_SECOND_OWNER_NAME,
    MUT_LOAD_STATE,
    MUT_ADD_OWNER_TYPE
} from '@/store/owners-mutation';
import {MUT_RESET} from '@/store/mark-mutation';
import {getOwnerType} from '@/core/util/owner-utils';

export interface OwnersState {
    ownerType: null | MarkOwnerType;
    ownerName: string;
    secondOwnerName: string;
    companyNumber: string;
    ownerCountryCode: string;
}

const owners: Module<OwnersState, RootState> = {
    namespaced: true,
    state: {
        ownerType: null,
        ownerName: '',
        secondOwnerName: '',
        companyNumber: '',
        ownerCountryCode: ''
    },
    getters: {
        ownerName(state): string {
            return state.ownerName;
        },
        secondOwnerName(state): string {
            return state.secondOwnerName;
        },
        companyNumber(state): string {
            return state.companyNumber;
        },
        ownerCountryCode(state): string {
            return state.ownerCountryCode;
        },
        getMarkOwnersDataToSubmit(state, getters): MarkOwnerInterface[] {
            const owners: Owner[] = [];

            const firstOwner = getters.getFirstOwner;
            if (firstOwner) {
                owners.push(firstOwner.apiData);
            }

            const secondOwner = getters.getSecondOwner;
            if (secondOwner) {
                owners.push(secondOwner.apiData);
            }

            return owners;
        },
        ownerType(state): MarkOwnerType | null {
            return state.ownerType;
        },
        ownerTypeGuessed(state): MarkOwnerType {
            if (state.ownerType === null) {
                return getOwnerType(state.ownerName, state.secondOwnerName, state.companyNumber)
            }

            return state.ownerType;
        },
        getFirstOwner(state, getters): Owner | null {
            if (state.ownerName.length === 0) {
                return null;
            }

            const owner = new Owner(getters.ownerTypeGuessed);

            owner.setOwnerName(state.ownerName);
            owner.citizenCountryCode = state.ownerCountryCode;
            if (owner.isBusiness) {
                owner.companyNumber = state.companyNumber;
            }

            return owner;
        },
        getSecondOwner(state, getters): Owner | null {
            if (state.secondOwnerName.length === 0) {
                return null;
            }
            const owner = new Owner(getters.ownerTypeGuessed);

            owner.setOwnerName(state.secondOwnerName);
            owner.citizenCountryCode = state.ownerCountryCode;
            if (owner.isBusiness) {
                owner.companyNumber = state.companyNumber;
            }

            return owner;
        },
        owners(state, getters): Owner[] {
            const owners = [getters.getFirstOwner, getters.getSecondOwner];

            return owners.filter(owner => owner !== null);
        }
    },
    actions: {
        updateOwnerName({ commit }, ownerName: string) {
            commit(MUT_ADD_OWNER_NAME, ownerName);
        },
        updateOwnerType({ commit }, ownerType: MarkOwnerType) {
            commit(MUT_ADD_OWNER_TYPE, ownerType);
        },
        updateSecondOwnerName({ commit }, secondOwnerName: string) {
            commit(MUT_ADD_SECOND_OWNER_NAME, secondOwnerName);
        },
        updateCompanyNumber({ commit }, companyNumber: string | undefined) {
            if (!companyNumber) {
                companyNumber = '';
            }

            commit(MUT_ADD_COMPANY_NUMBER, companyNumber);
        },
        updateOwnerCountryCode({ commit }, ownerCountryCode: string) {
            commit(MUT_ADD_OWNER_COUNTRY_CODE, ownerCountryCode);
        },
        loadState({ commit }, state) {
            commit(MUT_LOAD_STATE, state);
        },
        reset({ commit }) {
            commit(MUT_RESET);
        }
    },
    mutations: {
        [MUT_LOAD_STATE](state, stateToLoad: OwnersState) {
            Object.entries(stateToLoad).forEach(property => {
                const propertyName = property[0];
                (state[propertyName as keyof OwnersState] as any) = property[1];
            });
        },
        [MUT_RESET](state) {
            state.ownerType = null;
            state.ownerName = '';
            state.secondOwnerName = '';
            state.companyNumber = '';
            state.ownerCountryCode = '';
        },
        [MUT_ADD_OWNER_TYPE](state, ownerType: MarkOwnerType) {
            state.ownerType = ownerType;
        },
        [MUT_ADD_OWNER_NAME](state, ownerName: string) {
            state.ownerName = ownerName;
        },
        [MUT_ADD_SECOND_OWNER_NAME](state, secondOwnerName: string) {
            state.secondOwnerName = secondOwnerName;
        },
        [MUT_ADD_COMPANY_NUMBER](state, companyNumber: string) {
            state.companyNumber = companyNumber;
        },
        [MUT_ADD_OWNER_COUNTRY_CODE](state, ownerCountryCode: string) {
            state.ownerCountryCode = ownerCountryCode;
        }
    }
};

export default owners;
