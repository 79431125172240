import { RootState } from '.';
import { Module } from 'vuex';
import { MUT_RESET, MUT_ADD_VALID_STEP, MUT_REMOVE_VALID_STEP, MUT_LOAD_STATE } from '@/store/workflow-mutation';
import { WorkflowStep } from '@/config/workflow/steps';

export interface WorkflowState {
    validSteps: WorkflowStep[];
}

const workflow: Module<WorkflowState, RootState> = {
    namespaced: true,
    state: {
        validSteps: [],
    },
    getters: {
        getValidSteps: ({ validSteps }): WorkflowStep[] => validSteps,
        isValid: ({ validSteps }) => (step: WorkflowStep | string) => {
            let stepName = '';
            if (typeof step !== 'string') {
                stepName = step.name;
            } else {
                stepName = step;
            }

            const stepFound = validSteps.find((stepValidated: WorkflowStep) => {
                return stepValidated.name == stepName;
            });
            
            if (stepFound) {
                return true;
            }

            return false;
        },
        isReachable: (state, getter) => (step: WorkflowStep) => {
            return step.previousStep.every(previousStep => {
                return getter.isValid(previousStep);
            });
        }
    },
    actions: {
        validateStep({ commit, getters }, stepValidated: WorkflowStep) {
            if (getters.isValid(stepValidated)) {
                return;
            }

            commit(MUT_ADD_VALID_STEP, stepValidated);
        },
        unValidateStep({ commit, getters }, stepValidated: WorkflowStep) {
            if (!getters.isValid(stepValidated)) {
                return;
            }

            commit(MUT_REMOVE_VALID_STEP, stepValidated);
        },
        reset({ commit }) {
            commit(MUT_RESET);
        },
        loadState({ commit }, workflow) {
            commit(MUT_LOAD_STATE, workflow);
        }
    },
    mutations: {
        [MUT_RESET](state) {
            state.validSteps = [];
        },
        [MUT_ADD_VALID_STEP](state, step: WorkflowStep) {
            state.validSteps.push(step);
        },
        [MUT_REMOVE_VALID_STEP](state, step: WorkflowStep) {
            const index = state.validSteps.findIndex(validStep => {
                return validStep.name === step.name;
            });

            if (index !== -1) {
                state.validSteps.splice(index, 1);
            }
        },
        [MUT_LOAD_STATE](state, stepsWorflow) {
            state.validSteps = stepsWorflow;
        }
    }
};

export default workflow;
