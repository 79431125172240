import { useStore } from 'vuex';
import { onBeforeRouteLeave, RouteLocationNormalized, useRoute, useRouter } from 'vue-router';
import workflowSteps from '@/config/workflow/steps';
import { MARK } from '@/router/route-name';

const getCurrentWorkflowStepFromRoute = function (from: RouteLocationNormalized) {
    return workflowSteps.find(step => {
        return step.route === from.name;
    });
};

const getNextWorkflowStepFromRoute = function (to: RouteLocationNormalized) {
    return workflowSteps.find(step => {
        return step.route === to.name;
    });
};

const isNavigationToWorkflowStep = function (to: RouteLocationNormalized) {
    const currentStep = getCurrentWorkflowStepFromRoute(to);

    if (currentStep) {
        return true;
    }

    return false;
};

const isNavigationToPreviousStepPossible = function (to: RouteLocationNormalized, from: RouteLocationNormalized) {
    const currentStep = getCurrentWorkflowStepFromRoute(from);

    if (!currentStep) {
        return false;
    }

    const nextStep = getNextWorkflowStepFromRoute(to);
    if (nextStep) {
        const stepFound = currentStep.previousStep.find(step => {
            return step.name === nextStep.name;
        });

        if (stepFound) {
            return true;
        }
    }

    return false;
};

export default function useWorkflowRouteGuard(isStepValid: () => Promise<boolean>) {
    const store = useStore();

    onBeforeRouteLeave(async (to, from) => {
        if (!isNavigationToWorkflowStep(to)) {
            return true;
        }

        if (isNavigationToPreviousStepPossible(to, from)) {
            return true;
        }

        const currentStep = getCurrentWorkflowStepFromRoute(from);
        if (!await isStepValid()) {
            if (currentStep) {
                store.dispatch('workflow/unValidateStep', currentStep);
            }

            return false;
        }

        if (currentStep) {
            store.dispatch('workflow/validateStep', currentStep);
        }

        const isNextStepNavigationPossible = store.getters['workflow/isReachable'](getNextWorkflowStepFromRoute(to));
        if (!isNextStepNavigationPossible) {
            return false;
        }

        return true;
    });

    const route = useRoute();
    const router = useRouter();

    const step = workflowSteps.find(step => {
        return step.route === route.name;
    });

    if (step && !store.getters['workflow/isReachable'](step)) {
        router.push({ name: MARK });
    }
}
