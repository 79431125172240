<template>
    <stf-form-field :field="fields.ownerType" class="mb-4"/>
    <div v-show="OwnerTypeSelected">
        <stf-form-field class="" :field="fields.ownerName"/>
        <stf-button
            :icon=true
            :outlined=true
            class="second-owner-name-button"
            icon-name="account-multiple-plus"
            v-if="false"
            @click="addSecondOwnerName"
        />
        <stf-form-field v-if="hasSecondOwnerName" :field="fields.secondOwnerName"/>

        <stf-form-field :field="fields.companyNumber"/>
        <stf-form-field :field="fields.ownerCountry"/>
        <stf-card color="grey100" class="p-3 py-5 my-5">
            <stf-form-field :field="fields.address"/>
            <stf-form-field :field="fields.suburb"/>
            <stf-form-field :field="fields.region"/>
            <stf-form-field :field="fields.city"/>
            <stf-form-field :field="fields.postcode"/>
        </stf-card>
        <stf-form-field :field="fields.ownerEmail"/>
        <stf-form-field :field="fields.ownerPhone"/>
        <stf-form-field :field="fields.declaration"/>
    </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import useOwnerForm from '@/components/form/forms/owner-form';
import { Country } from '@/config/country/type';
import StfFormField from '@/components/form/stf-form-field.vue';
import useStfForm from '../form/formable';
import StfCard from '@/ui/card/stf-card.vue';
import StfButton from '@/ui/button/stf-button.vue'

export default defineComponent({
    name: 'OwnerForm',
    components: {
        StfCard,
        StfFormField,
        StfButton
    },
    props: {
        country: {
            type: Object as PropType<Country>,
            default: () => {
                return {
                    code: '',
                };
            }
        }
    },
    data() {
        return {
            secondUser: false
        }
    },
    setup(props) {
        const {fields, validation, initialValue} = useOwnerForm(props.country);

        const {isValid, getData, valuesForm} = useStfForm(validation, initialValue, fields);

        return {
            valuesForm,
            fields,
            isValid,
            getData,
        };
    },
    computed: {
        hasSecondOwnerName(): boolean {
            return this.secondUser || this.valuesForm.secondOwnerName != '';
        },
        ownerCountry(): string {
            return this.valuesForm.ownerCountry as string;
        },
        OwnerTypeSelected(): boolean {
            return this.valuesForm.ownerType !== null;
        }
    },
    methods: {
        addSecondOwnerName() {
            this.secondUser = true;
        }
    }
});
</script>
