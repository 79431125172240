<template>
    <stf-header>
        <div>
            <div v-if="!$screen.isMobile" class="test stf-header__nav-bar-buttons columns is-flex-wrap-wrap is-justify-content-right">
                <navbar-menu-item  class="menu" v-for="(menu, index) in domain.menuItems" :key="index" :menu="menu"/>
            </div>
            <div v-else>
                <navbar-mobile-burger-menu 
                    :menuItems="domain.menuItems" 
                    :backgroundColor="domain.mobileMenuColor"
                    :barColor="burgerMenuColor"
                />
            </div>
        </div>
    </stf-header>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import StfHeader from '@/header-footer-replicas/headers/stf-header-v3.vue';
import NavbarMenuItem from '../../common/headers/new-navbar-menu-item-v3.vue'
import NavbarMobileBurgerMenu from '../../common/headers/navbar-mobile-burger-menu.vue'
import useDomain from '@/composables/domainDependable';

export default defineComponent({
    name: 'TmoBxHeaderReplica',
    components: { StfHeader, NavbarMenuItem, NavbarMobileBurgerMenu },
    setup() {
        const domain = useDomain();
        const burgerMenuColor = '#3d3d3d';
        return {domain, burgerMenuColor};
    },
    methods: {
        onImageClick(url: string) {
            location.replace(url);
        }
    }
});
</script>