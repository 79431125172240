import { createApp } from 'vue';
import { createGtm } from '@gtm-support/vue-gtm';
import App from './App.vue';
// import './registerServiceWorker'; // disable PWA temporarily
import router from './router';
import store, { RootState } from './store';
import config from './config';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { Store } from 'vuex';
import moneyFilter from '@/filter/money-filter';
import Rollbar from '@/plugins/rollbar';
import BingUETQ from '@/plugins/uetq';
import FacebookPixel from '@/plugins/facebookPixel';
import { LogArgument } from 'rollbar';
import i18n from '@/lang';

import '../node_modules/@fortawesome/fontawesome-free/css/all.css';
import '@/assets/sass/themes/default.scss';
import rollBarOption from './core/logger/rollbar-option';
import '@/assets/sass/themes/new-design.scss';
import screenSize from './plugins/screen-plugin/plugin';
import useDomain from '@/composables/domainDependable';

import StfRow from '@/ui/layout/stf-row.vue';
import StfCol from '@/ui/layout/stf-col.vue';
import StfHeadingOne from '@/ui/typography/stf-heading-one.vue';
import StfHeadingTwo from '@/ui/typography/stf-heading-two.vue';
import StfStrong from '@/ui/typography/stf-strong.vue';
import StfSectionDivider from '@/ui/divider/stf-section-divider.vue';

const { gtmId } = useDomain();

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $config: Record<string, unknown>;
        $store: Store<RootState>;
    }
}

// declare bing ads UET tags
declare global {
    interface Window {
        uetq: any;
    }
}

const app = createApp(App);
app
    .use(
        createGtm({
            id: gtmId,
            enabled: true,
            loadScript: true,
            // @ts-ignore Probably due to Router version mismatch
            vueRouter: router
        }),
    )
    .use(store)
    .use(router)
    .use(BingUETQ, {})
    .use(FacebookPixel, {})
    .use(i18n)
    .use(screenSize, {
        tabletWidth: 800
    })
    .use(Rollbar, rollBarOption)
    .use(VueAxios, axios);

// Global components
app.component('StfRow', StfRow);
app.component('StfCol', StfCol);
app.component('StfHeadingOne', StfHeadingOne);
app.component('StfHeadingTwo', StfHeadingTwo);
app.component('StfStrong', StfStrong);
app.component('StfSectionDivider', StfSectionDivider);

app.config.globalProperties.$config = config;
app.config.globalProperties.$filters = {
    ...moneyFilter
};

// Assign a handler for uncaught errors during component render function and watchers.
// The handler gets called with the error and the application instance.
app.config.errorHandler = (err, vm) => {
    if (vm !== null) {
        vm.$rollbar.error(err as LogArgument);
    }

    // eslint-disable-next-line no-console
    console.error(err);
};

app.mount('#app');
