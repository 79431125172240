import { singaporeWorkflow } from '../workflow';
import { Country, CountryCode } from './type';

export const singapore: Country = {
    name: 'Singapore',
    code: CountryCode.SG,
    displayCode: CountryCode.SG,
    combinedDisplayCode: CountryCode.SG,
    workflow: singaporeWorkflow,
    makeMethod: 'makeSingapore',
    theme: false,
    currency: '$',
    currencyName: 'SGD',
    currencyWithCountry: 'SG$',
    maxImageSize: 20000000, // 20MB
    maxGoodNumberPerClass: 100, // IP Australia only allows 100 goods per class for applications.
    paymentMethodsImageName: 'image-4-card',
    stripeKey: import.meta.env.VITE_STRIP_PUBLIC_KEY_SG,
    autoSelectedInForm: true,
};
