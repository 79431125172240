<template>
    <component :is="type" :class="classes" :value="value"/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ChevronUp from '../icons/ChevronUp.vue';
import ChevronDown from '../icons/ChevronDown.vue';
import AccountMultiplePlus from '../icons/AccountMultiplePlus.vue';
import CloseCircle from '../icons/close-circle.vue';
import Close from '../icons/Close.vue';
import MinusCircle from '../icons/MinusCircle.vue';
import PlusCircle from '../icons/PlusCircle.vue';
import BadgeNumber from '../icons/BadgeNumber.vue';
import Shopping from '../icons/Shopping.vue';
import Menu from '../icons/Menu.vue';
import Lock from '../icons/Lock.vue';
import Pencil from '../icons/Pencil.vue';
import Loading from '../icons/Loading.vue';
import MenuDown from '../icons/MenuDown.vue';
import MenuUp from '../icons/MenuUp.vue';
import Magnify from '../icons/Magnify.vue';
import { ComputedCssClass } from '../type';

export default defineComponent({
    name: 'stf-icon',
    components: {
        CloseCircle,
        ChevronUp,
        ChevronDown,
        AccountMultiplePlus,
        Close,
        MinusCircle,
        PlusCircle,
        Shopping,
        BadgeNumber,
        Menu,
        Lock,
        Loading,
        Pencil,
        MenuDown,
        MenuUp,
        Magnify
    },
    props: {
        type: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            default: 'default',
            description: 'color of the background'
        },
        small: {
            type: Boolean,
            default: false
        },
        large: {
            type: Boolean,
            default: false
        },
        value: {
            type: String,
            default: '0',
        }
    },
    computed: {
        classes(): ComputedCssClass {
            const cssClass: ComputedCssClass = {
                'stf-icon': true,
                'stf-icon--small': this.small,
                'stf-icon--large': this.large,
            };

            cssClass[this.colorClass] = true;

            return cssClass;
        },
        colorClass(): string {
            return `stf-icon--${this.color}`;
        }
    }
});
</script>

<style lang="sass">
@import './icon'
</style>
