<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-sm-12 col-lg-10 col-xl-8 ml-5">
                <h2>Reset or Create your password</h2>
                <form 
                    @submit.prevent="resetPassword"
                    class="mt-4"
                >
                    <stf-input 
                        class="mb-4"
                        v-model="newPassword"
                        name="New password" 
                        label="New password" 
                        type="password" 
                        required
                    />

                    <stf-input 
                        class="mb-5"
                        v-model="confirmPassword"
                        name="Confirm password" 
                        label="Confirm password" 
                        type="password" 
                        required
                    />

                    <div v-if="errorMsg" class="error mb-4">
                        {{ errorMsg }}
                    </div>
            
                    <stf-button
                        type="submit"
                        color="primary-console"
                    >
                        Reset/Create password
                    </stf-button>
                </form>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import StfButton from '@/ui/button/stf-button.vue';
import HttpResponse from '@/core/http-module/http-response';
import stfInput from '@/ui/input/stf-input.vue';
import authentication from '@/core/authentication/authentication';
import RouteName from '@/router/route-name';

export default defineComponent({
    name: 'ResetPassword',
    components: {
        StfButton,
        stfInput
    },
    data() {
        return {
            newPassword: '',
            confirmPassword: '',
            errorMsg: '',
        }
    },
    methods: {
        async resetPassword() {
            if(this.newPassword !== this.confirmPassword) {
                this.errorMsg = 'Passwords do not match';
                return;
            }
            const response = await authentication.resetPasswordReset(this.newPassword);
            if(response instanceof HttpResponse && response.status !== 200) {
                this.errorMsg = 'An error occurred, please try again later.';
                return;
            }
            this.resetForm();
            this.$router.push({
                name: RouteName.LOGIN,
                params: { changePasswordSuccess: 'true' }
            });
        },
        resetForm() {
            this.newPassword = '';
            this.confirmPassword = '';
            this.errorMsg = '';
        }
    }
});
</script>