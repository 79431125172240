<template>
    <div class="row justify-content-center">
        <div class="col-12 col-sm-12 col-lg-10 col-xl-8">
            <div class="wizard">
                <slot/>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Stepper',
});
</script>
