<template>
    <tmo-group-footer-replica :footer-menu="footerMenu"></tmo-group-footer-replica>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import TmoGroupFooterReplica from '@/header-footer-replicas/common/footers/tmo-group-footer-replica.vue';

export default defineComponent({
    name: 'TmoBxFooterReplica',
    components: { TmoGroupFooterReplica },
    data() {
        return {
            footerMenu: {
                imageUrl: 'https://images.squarespace-cdn.com/content/66412d0c5faacc56469e658a/b68aac99-aaae-42e7-b1c6-52f3534c7784/logo_no_background.png?format=280w',
                countryName: 'Benelux',
                tmoCountries: [],
                contactUrl: 'https://www.trademarksonline.be/contact',
                termsUrl: 'https://www.trademarksonline.be/terms-of-use',
                privacyUrl: 'https://www.trademarksonline.be/privacy-policy',
            }
        }
    }
});

</script>