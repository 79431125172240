export default class HttpResponse {
    private _status: number;
    private _content: any;

    constructor(status: number, content: any) {
        this._status = status;
        this._content = content;
    }

    get status() {
        return this._status;
    }

    get content() {
        return this._content;
    }
}
