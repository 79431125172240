<template>
    <div>
        {{ displayedText }}
        <div v-if="countWord > maxWords  && showExtendButton" class="mt-2 is-block">
            <stf-button
                @click="isTruncated = !isTruncated"
                color="primary-console"
                outlined
                small
            >
                {{ action }}
            </stf-button>
        </div>
    </div>
</template>

<script>
import StfButton from '@/ui/button/stf-button.vue';
export default {
    components: {
        StfButton
    },
    props: {
        text: {
            type: String,
            required: true
        },
        maxWords: {
            type: Number,
            default: 45
        },
        showExtendButton: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isTruncated: true
        };
    },
    computed: {
        action() {
            return this.isTruncated ? 'show more' : 'show less';
        },
        countWord() {
            return this.text.split(' ').length;
        },
        displayedText() {
            if (this.countWord > this.maxWords && this.isTruncated) {
                return this.text.split(' ').slice(0, this.maxWords).join(' ') + '...';
            }
            return this.text;
        },
    }
};
</script>

